@charset "UTF-8";
@import "../assets/fonts/stylesheet.css";
/*** 

====================================================================
	Reset
====================================================================

***/
html {
  scroll-behavior: smooth;
}

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

button {
  outline: none;
}

button:focus {
  outline: none;
}

button:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

/*** 
  ====================================================================
      Global Settings
  ====================================================================
   ***/
body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #000;
  line-height: 1.7em;
  font-weight: 400;
  background: #100f2d;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
}

.auto__container {
  position: relative;
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

h1 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 23px;
  line-height: 20px;
  color: #fff;
}
h1.big {
  font-weight: 900;
  font-size: 70px;
  line-height: 1;
  background: -webkit-gradient(linear, left top, right top, from(#ffed71), to(#ffbe00)), #ffffff;
  background: linear-gradient(90deg, #ffed71 0%, #ffbe00 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

h2 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 23px;
  line-height: 20px;
  color: #fff;
}
h2.big {
  font-weight: 900;
  font-size: 35px;
  line-height: 42px;
}

h3 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}

p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
}

main {
  padding-top: 66px;
}

.bgOverlay {
  position: fixed;
  top: 66px;
  height: calc(100% - 66px);
  left: 0;
  width: 100%;
  background: rgba(12, 7, 34, 0.8);
  z-index: 95;
}

body.active {
  overflow: hidden;
}

.button {
  position: relative;
}
.button.auth {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  padding: 5px 0;
  background: none;
  max-width: 136px;
  cursor: pointer;
}
.button.auth p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.05em;
  color: #fff;
  z-index: 1;
}
.button.auth span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.button.auth span.default::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  border-radius: 5px;
}
.button.auth span.default::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
}
.button.auth span.hov {
  opacity: 0;
}
.button.auth span.hov::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 5px;
}
.button.auth span.hov::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
}
.button.auth span.active {
  opacity: 0;
}
.button.auth span.active::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  border-radius: 5px;
}
.button.auth span.active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
}
.button.auth:hover span.default {
  opacity: 0;
}
.button.auth:hover span.hov {
  opacity: 1;
}
.button.auth:focus span.default {
  opacity: 0;
}
.button.auth:focus span.hov {
  opacity: 0;
}
.button.auth:focus span.active {
  opacity: 1;
}
.button.login span.default::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#27264f), to(#212047));
  background: linear-gradient(180deg, #27264f 0%, #212047 100%);
}
.button.login span.default::after {
  background: -webkit-gradient(linear, left top, right top, from(#2e2d55), to(#313063));
  background: linear-gradient(90deg, #2e2d55 0%, #313063 100%);
}
.button.login span.hov::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#7d7ccd), to(#61609d));
  background: linear-gradient(180deg, #7d7ccd 0%, #61609d 100%);
}
.button.login span.hov::after {
  background: #42407e;
}
.button.login span.active::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#7d7ccd), to(#61609d));
  background: linear-gradient(180deg, #7d7ccd 0%, #61609d 100%);
}
.button.login span.active::after {
  background: -webkit-gradient(linear, left top, right top, from(#2e2d55), to(#313063));
  background: linear-gradient(90deg, #2e2d55 0%, #313063 100%);
}
.button.register span.default::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#1f60ab), to(#1e5ca4));
  background: linear-gradient(180deg, #1f60ab 0%, #1e5ca4 100%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}
.button.register span.default::after {
  background: #307bd3;
}
.button.register span.hov::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#b0f0ff));
  background: linear-gradient(180deg, #ffffff 0%, #b0f0ff 100%);
}
.button.register span.hov::after {
  background: #3eaeff;
}
.button.register span.active::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#b0f0ff));
  background: linear-gradient(180deg, #ffffff 0%, #b0f0ff 100%);
}
.button.register span.active::after {
  background: #307bd3;
}
.button.continue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  padding: 15px 0;
  background: none;
  width: 100%;
  cursor: pointer;
  -webkit-box-shadow: 0px 4px 4px rgba(24, 24, 53, 0.5);
          box-shadow: 0px 4px 4px rgba(24, 24, 53, 0.5);
  position: relative;
}
.button.continue p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  z-index: 1;
}
.button.continue span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.button.continue span.default {
  opacity: 1;
}
.button.continue span.default::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 5px;
  z-index: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#1f60ab), to(#1e5ca4));
  background: linear-gradient(180deg, #1f60ab 0%, #1e5ca4 100%);
}
.button.continue span.default::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#3e91f2), to(#307bd3)), #307bd3;
  background: linear-gradient(180deg, #3e91f2 0%, #307bd3 100%), #307bd3;
}
.button.continue span.hov {
  opacity: 0;
}
.button.continue span.hov::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 5px;
  z-index: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#b0f0ff));
  background: linear-gradient(180deg, #ffffff 0%, #b0f0ff 100%);
}
.button.continue span.hov::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#3eb3f2), to(#3e91f2));
  background: linear-gradient(180deg, #3eb3f2 0%, #3e91f2 100%);
}
.button.continue span.active {
  opacity: 0;
}
.button.continue span.active::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  border-radius: 5px;
  z-index: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#b0f0ff));
  background: linear-gradient(180deg, #ffffff 0%, #b0f0ff 100%);
}
.button.continue span.active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#3eb3f2), to(#3e91f2));
  background: linear-gradient(180deg, #3eb3f2 0%, #3e91f2 100%);
}
.button.continue:hover span.default {
  opacity: 0;
}
.button.continue:hover span.hov {
  opacity: 1;
}
.button.continue:focus span.default {
  opacity: 0;
}
.button.continue:focus span.hov {
  opacity: 0;
}
.button.continue:focus span.active {
  opacity: 1;
}
.button.green {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.button.green p {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #fff;
  text-shadow: 0px 1px 2px rgba(23, 35, 140, 0.3);
}
.button.green p b {
  font-weight: 900;
}
.button.green span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.button.green span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  border-radius: 5px;
}
.button.green span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  border-radius: 5px;
}
.button.green span.default::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#289c64), to(#167346));
  background: linear-gradient(180deg, #289c64 0%, #167346 100%);
}
.button.green span.default::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#34c05e), to(#008748));
  background: linear-gradient(180deg, #34c05e 0%, #008748 100%);
}
.button.green span.hov {
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.button.green span.hov::before {
  background: #fff;
  -webkit-filter: blur(1px);
          filter: blur(1px);
}
.button.green span.hov::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#33dd66), to(#09b564));
  background: linear-gradient(180deg, #33dd66 0%, #09b564 100%);
}
.button.green span.active {
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.button.green span.active::before {
  background: #fff;
}
.button.green span.active::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#33dd66), to(#09b564));
  background: linear-gradient(180deg, #33dd66 0%, #09b564 100%);
}
.button.green:hover span.hov {
  opacity: 1;
}
.button.green:hover p {
  font-size: 18px;
  line-height: 22px;
}
.button.green:focus span.hov {
  opacity: 0;
}
.button.green:focus span.active {
  opacity: 1;
}
.button.green:focus p {
  font-size: 17px;
  line-height: 21px;
}
.button.play {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  -webkit-box-shadow: 0px 1.77px 1.77px 0px #0d1c4a;
          box-shadow: 0px 1.77px 1.77px 0px #0d1c4a;
}
.button.play p {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 1;
  color: #fff;
  text-shadow: 0px 0.884161px 1.76832px #187443;
}
.button.play svg {
  position: absolute;
  right: 46px;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 16px;
  height: 19px;
  color: #187443;
}
.button.play span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.button.play span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  border-radius: 5px;
}
.button.play span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  border-radius: 5px;
}
.button.play span.default::before {
  background: -webkit-gradient(linear, left bottom, left top, from(#1a8a4e), to(#5dc74a));
  background: linear-gradient(360deg, #1a8a4e 0%, #5dc74a 100%);
}
.button.play span.default::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#34c05e), to(#008748));
  background: linear-gradient(180deg, #34c05e 0%, #008748 100%);
}
.button.play span.hov {
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.button.play span.hov::before {
  background: #fff;
}
.button.play span.hov::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#33dd66), to(#09b564));
  background: linear-gradient(180deg, #33dd66 0%, #09b564 100%);
}
.button.play span.active {
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.button.play span.active::before {
  background: #fff;
}
.button.play span.active::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#33dd66), to(#09b564));
  background: linear-gradient(180deg, #33dd66 0%, #09b564 100%);
}
.button.play:hover span.hov {
  opacity: 1;
}
.button.play:focus span.hov {
  opacity: 0;
}
.button.play:focus span.active {
  opacity: 1;
}
.button.link {
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #1f1d47;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}
.button.link p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #bdbaef;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.button.link svg {
  width: 23px;
  height: 16px;
  color: #fff;
}
.button.link.linked {
  background: #37b06c;
}
.button.link.linked p {
  color: #0c0722;
  -webkit-box-shadow: unset;
          box-shadow: unset;
}
.button.link:hover {
  opacity: 0.8;
}
.button.buy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 44px;
  border-radius: 5px;
  cursor: pointer;
}
.button.buy p {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 23.38px;
  line-height: 1;
  color: #fff;
  text-shadow: 0px 2px 4px #00489c;
}
.button.buy img {
  width: 23px;
  height: 37px;
  margin-left: 10px;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.button.buy span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.button.buy span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  border-radius: 5px;
}
.button.buy span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  border-radius: 5px;
}
.button.buy span.default::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#0058be), to(#00489c));
  background: linear-gradient(180deg, #0058be 0%, #00489c 100%);
}
.button.buy span.default::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#3e91f2), to(#307bd3));
  background: linear-gradient(180deg, #3e91f2 0%, #307bd3 100%);
}
.button.buy span.hov {
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.button.buy span.hov::before {
  background: #fff;
}
.button.buy span.hov::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#3eb3f2), to(#3e91f2));
  background: linear-gradient(180deg, #3eb3f2 0%, #3e91f2 100%);
}
.button.buy span.active {
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.button.buy span.active::before {
  background: #fff;
}
.button.buy span.active::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#71cdff), to(#5fa9ff));
  background: linear-gradient(180deg, #71cdff 0%, #5fa9ff 100%);
}
.button.buy:hover span.hov {
  opacity: 1;
}
.button.buy:focus span.hov {
  opacity: 0;
}
.button.buy:focus span.active {
  opacity: 1;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.footer {
  background: #0c0722;
}
.footer.hide {
  display: none;
}
.footer__top {
  position: relative;
}
.footer__top::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(#0c0722), color-stop(47.5%, #22214a), to(#0c0722));
  background: linear-gradient(90deg, #0c0722 0%, #22214a 47.5%, #0c0722 100%);
  content: "";
}
.footer__top-inner {
  padding: 50px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.footer__top-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.footer__top-socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.footer__top-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 40px;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.footer__top-social:hover {
  opacity: 0.8;
}
.footer__top-social:last-child {
  margin-right: 0;
}
.footer__top-social svg {
  width: 100%;
  height: 100%;
  color: #fff;
}
.footer__top-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 177px;
  margin-right: 73px;
}
.footer__top-logo img {
  width: 100%;
}
.footer__top-btn {
  width: 175px;
  background: #307bd3;
  border-radius: 5px;
  padding: 10px 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.footer__top-btn:hover {
  opacity: 0.8;
}
.footer__top-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 1;
  text-align: center;
  color: #fff;
}
.footer__top-btn svg {
  width: 18px;
  height: 18px;
  color: #fff;
  margin-right: 16px;
}
.footer__links {
  position: relative;
}
.footer__links::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(#0c0722), color-stop(47.5%, #22214a), to(#0c0722));
  background: linear-gradient(90deg, #0c0722 0%, #22214a 47.5%, #0c0722 100%);
  content: "";
}
.footer__links-inner {
  padding: 24px 0 36px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  max-width: 850px;
}
.footer__links-col {
  margin-right: 90px;
}
.footer__links-col:last-child {
  margin-right: 0;
}
.footer__links-col h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #efefff;
  margin-bottom: 10px;
}
.footer__links-col-link {
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #616096;
  margin-bottom: 8px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}
.footer__links-col-link:hover {
  color: #fff;
}
.footer__links-col-link:last-child {
  margin-bottom: 0;
}
.footer__note {
  position: relative;
}
.footer__note::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(#0c0722), color-stop(47.5%, #22214a), to(#0c0722));
  background: linear-gradient(90deg, #0c0722 0%, #22214a 47.5%, #0c0722 100%);
  content: "";
}
.footer__note-inner {
  padding: 40px 0 30px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.footer__note-inner p {
  max-width: 522px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #616096;
}
.footer__note-inner p span {
  font-weight: 600;
  color: #efefff;
}
.footer__note-inner p a {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #616096;
  text-decoration: underline;
  text-underline-position: under;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.footer__note-inner p a:hover {
  color: #efefff;
}
.footer__copy-inner {
  padding: 23px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.footer__copy-inner p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #616096;
}
.footer .languageSelect {
  width: 44px;
}
.footer .languageSelect__selected {
  border-radius: 5px;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #1f1d47;
}
.header.diffHeader {
  background: rgba(10, 10, 23, 0.5);
}
.header.diffHeader .header__inner::before {
  background: rgba(10, 10, 23, 0.5);
}
.header.noBgHeader {
  background: none;
}
.header.noBgHeader .header__inner::before {
  background: none;
}
.header__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  z-index: 99;
}
.header__inner::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #1f1d47;
  z-index: 0;
}
.header__inner-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header__inner-logo {
  position: relative;
  cursor: pointer;
  width: 38.98px;
  height: 41px;
  margin-right: 37px;
}
.header__inner-logo-ellipse {
  position: absolute;
  top: 1.33px;
  left: 1.84px;
  width: 36.08px;
  height: 36.47px;
  background: #1f78ff;
  border-radius: 50%;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header__inner-logo-ellipse2 {
  position: absolute;
  top: 2.4px;
  left: 1.05px;
  border-radius: 50%;
  width: 36.35px;
  height: 37.41px;
  z-index: 0;
  background: #1c35bf;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header__inner-logo svg {
  width: 28.97px;
  height: 29.29px;
  color: #fff;
  position: absolute;
  z-index: 2;
  top: 5.06px;
  right: 4.74px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header__inner-logo:hover svg {
  top: 4.53px;
  right: 2.63px;
  width: 27.65px;
  height: 30.08px;
}
.header__inner-logo:hover .header__inner-logo-ellipse {
  color: #1fa2ff;
  width: 33.71px;
  height: 37.54px;
  left: 4.74px;
  top: 0.8px;
}
.header__inner-logo:hover .header__inner-logo-ellipse2 {
  top: 0.8px;
  left: 2.37px;
  width: 36.08px;
  height: 38.07px;
  color: #2843db;
}
.header__btns {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 285px;
  margin-right: 52px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.header__btns .button {
  width: calc(50% - 6px);
}
.header .languageSelect {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 0;
}
.header .languageSelect__selected {
  border-radius: 5px;
}
.header__more {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 99;
}
.header__more-level {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #1f1d47;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.header__more-level-progress {
  width: 93px;
  height: 7px;
  border: 1px solid #444376;
  border-right: unset;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #1f1d47;
  position: relative;
  border-radius: 50px 0 0 50px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  overflow: hidden;
}
.header__more-level-progress span {
  position: absolute;
  top: 50%;
  left: 0;
  width: 40%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  background: #4ac61c;
  height: 5px;
}
.header__more-level-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  width: 36px;
  height: 26px;
  background: #444376;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header__more-level-info img {
  width: 24px;
  height: 15px;
}
.header__more-level:hover {
  background: #3d3c6c;
}
.header__more-level:hover .header__more-level-progress {
  background: #3d3c6c;
  border: 1px solid #adacde;
  border-right: unset;
}
.header__more-level:hover .header__more-level-info {
  background: #adacde;
}
.header__more-level:focus {
  background: #adacde;
}
.header__more-level:focus .header__more-level-progress {
  background: #3d3c6c;
  border: 1px solid #3d3c6c;
  border-right: unset;
}
.header__more-level:focus .header__more-level-info {
  background: #3d3c6c;
}
.header__more-account {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 144px;
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #1f1d47;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin-right: 14px;
  cursor: pointer;
}
.header__more-account-avatar {
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background: #2f2e5f;
}
.header__more-account-avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.header__more-account-avatar svg {
  width: 25px;
  height: 25px;
  color: #adacde;
}
.header__more-account p {
  width: calc(100% - 45px);
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header__more-account:hover {
  background: #3d3c6c;
}
.header__more-account:focus {
  background: #adacde;
}
.header__more-account:focus p {
  color: #3d3c6c;
}
.header__more-notify {
  width: 34px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #1f1d47;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 20px;
}
.header__more-notify svg {
  width: 16px;
  height: 19px;
  color: #adacde;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header__more-notify:hover {
  background: #3d3c6c;
}
.header__more-notify:hover svg {
  width: 18px;
  height: 21px;
}
.header__more-notify:focus {
  background: #adacde;
}
.header__more-notify:focus svg {
  width: 16px;
  height: 19px;
  color: #3d3c6c;
}
.header__more-balance {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 134px;
  height: 34px;
  background: #100f2d;
  border-radius: 20px;
  margin-right: 10px;
}
.header__more-balance p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  color: #fff;
  max-width: calc(100% - 25px);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header__more-balance svg {
  width: 20px;
  height: 18px;
  color: #3eaeff;
}
.header__more-balance svg path.otherpath {
  color: #005cc9;
}
.header__more-balance img {
  width: 15px;
  height: 26px;
  -webkit-transform: rotate(16.73deg);
          transform: rotate(16.73deg);
  margin-left: 6px;
}
.header__more-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header__more-language {
  cursor: pointer;
  width: 44px;
  height: 34px;
  padding: 7px 13px 7px 11px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #1f1d47;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 5px 0 0 5px;
}
.header__more-language-image {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #2d2c61;
  -webkit-box-shadow: 0px 2px 2px 0px #100f2d80;
          box-shadow: 0px 2px 2px 0px #100f2d80;
  border-radius: 17.5px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header__more-language-image img {
  width: 100%;
  height: 100%;
}
.header__more-language:hover {
  background: #3d3c6c;
  padding: 5px 11px 6px 10px;
}
.header__more-language:hover .header__more-language-image {
  width: 23px;
  height: 23px;
  -webkit-box-shadow: 0px 2px 2px 0px #2d2c61;
          box-shadow: 0px 2px 2px 0px #2d2c61;
}
.header__more-language:focus {
  background: #adacde;
  padding: 7px 13px 7px 11px;
}
.header__more-language:focus .header__more-language-image {
  border: 1px solid #adacde;
  width: 20px;
  height: 20px;
  -webkit-box-shadow: 0px 2px 2px 0px #3d3c6c80;
          box-shadow: 0px 2px 2px 0px #3d3c6c80;
}
.header__more .languageSelect {
  position: relative;
  top: unset;
  right: unset;
  transform: translate(0, 0);
  -moz-ransform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
}
.header__more .languageSelect__selected {
  border-radius: 5px 0 0 5px;
}
.header__more-chat {
  cursor: pointer;
  width: 42px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #1f1d47;
  border-radius: 0 5px 5px 0;
}
.header__more-chat svg {
  width: 17px;
  height: 15px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #adacde;
}
.header__more-chat:hover {
  background: #3d3c6c;
}
.header__more-chat:hover svg {
  width: 19px;
  height: 17px;
}
.header__more-chat:focus {
  background: #adacde;
}
.header__more-chat:focus svg {
  width: 17px;
  height: 15px;
  color: #3d3c6c;
}

.nav__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.nav__inner-link {
  margin-right: 43px;
  background: none;
  padding: 19px 0;
  cursor: pointer;
}
.nav__inner-link:last-child {
  margin-right: 0;
}
.nav__inner-link-content {
  position: relative;
  z-index: 1;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 28px;
  width: 90px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.nav__inner-link-content::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 1px;
  height: 1px;
  content: "";
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.nav__inner-link p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #adacde;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.nav__inner-link.hov .nav__inner-link-content p {
  font-weight: 600;
  color: #ffffff;
}
.nav__inner-link.hov .nav__inner-link-content::before {
  background: -webkit-gradient(linear, left top, right top, from(#1f1d47), color-stop(20%, #1f78ff), color-stop(49.5%, #3eaeff), color-stop(80%, #1f78ff), to(#1f1d47));
  background: linear-gradient(90deg, #1f1d47 0%, #1f78ff 20%, #3eaeff 49.5%, #1f78ff 80%, #1f1d47 100%);
  width: 100%;
}
.nav__inner-link:hover .nav__inner-link-content p {
  font-weight: 600;
  color: #ffffff;
}
.nav__inner-link:hover .nav__inner-link-content::before {
  background: -webkit-gradient(linear, left top, right top, from(#1f1d47), color-stop(20%, #1f78ff), color-stop(49.5%, #3eaeff), color-stop(80%, #1f78ff), to(#1f1d47));
  background: linear-gradient(90deg, #1f1d47 0%, #1f78ff 20%, #3eaeff 49.5%, #1f78ff 80%, #1f1d47 100%);
  width: 100%;
}
.nav__inner-link.active .nav__inner-link-content p {
  font-weight: 500;
  color: #ffffff;
}
.nav__inner-link.active .nav__inner-link-content::before {
  background: -webkit-gradient(linear, left top, right top, from(#1f1d47), color-stop(20%, #1f78ff), color-stop(49.5%, #3eaeff), color-stop(80%, #1f78ff), to(#1f1d47));
  background: linear-gradient(90deg, #1f1d47 0%, #1f78ff 20%, #3eaeff 49.5%, #1f78ff 80%, #1f1d47 100%);
  width: 100%;
  height: 5px;
}
.nav__inner-link.active.hov .nav__inner-link-content p {
  font-weight: 600;
  color: #ffffff;
}
.nav__inner-link.active.hov .nav__inner-link-content::before {
  background: -webkit-gradient(linear, left top, right top, from(#1f1d47), color-stop(20%, #1f78ff), color-stop(49.5%, #3eaeff), color-stop(80%, #1f78ff), to(#1f1d47));
  background: linear-gradient(90deg, #1f1d47 0%, #1f78ff 20%, #3eaeff 49.5%, #1f78ff 80%, #1f1d47 100%);
  width: 100%;
}
.nav__inner-link.active:hover .nav__inner-link-content p {
  font-weight: 600;
  color: #ffffff;
}
.nav__inner-link.active:hover .nav__inner-link-content::before {
  background: -webkit-gradient(linear, left top, right top, from(#1f1d47), color-stop(20%, #1f78ff), color-stop(49.5%, #3eaeff), color-stop(80%, #1f78ff), to(#1f1d47));
  background: linear-gradient(90deg, #1f1d47 0%, #1f78ff 20%, #3eaeff 49.5%, #1f78ff 80%, #1f1d47 100%);
  width: 100%;
}
.nav__inner-link.vip p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: #fff;
}
.nav__inner-link.vip p span {
  font-weight: 700;
}
.nav__inner-link.vip .nav__inner-link-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #15152d;
  border-radius: 5px;
  width: 151px;
}
.nav__inner-link.vip .nav__inner-link-content svg {
  width: 17px;
  height: 15px;
  color: #ffd024;
  margin-right: 8px;
  margin-top: -2px;
}
.nav__dropdown {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  z-index: -1;
  color: #fff;
  opacity: 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.nav__dropdown-title {
  display: none;
}
.nav__dropdown-inner {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: -webkit-gradient(linear, left top, left bottom, from(#2a2954), color-stop(12%, #302f61), to(#313062));
  background: linear-gradient(180deg, #2a2954 0%, #302f61 12%, #313062 100%);
  border: 1px solid #1d1d3a;
  border-radius: 0 0 5px 5px;
  padding: 19px 19px 18px 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.nav__dropdown-play {
  display: block;
  width: calc(33.33% - 5px);
  position: relative;
  height: 128px;
  overflow: hidden;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 0.5px solid #cd8d2e;
  border-radius: 5px;
}
.nav__dropdown-play-title {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.nav__dropdown-play-title svg {
  width: 15px !important;
  height: 15px !important;
  color: #ffc026 !important;
  margin-right: 9px !important;
}
.nav__dropdown-play-title h6 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  text-shadow: 0px 1px 2px rgba(12, 7, 34, 0.3);
}
.nav__dropdown-play-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}
.nav__dropdown-play-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.nav__dropdown-play-pack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  opacity: 1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.nav__dropdown-play-pack.hov {
  opacity: 0;
}
.nav__dropdown-play-pack img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.nav__dropdown-play-element {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: radial-gradient(28.25% 28.25% at 32.04% 18.09%, #4f118d 0%, rgba(79, 17, 141, 0) 100%);
}
.nav__dropdown-play:hover .nav__dropdown-play-image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.nav__dropdown-play:hover .nav__dropdown-play-pack {
  opacity: 0;
}
.nav__dropdown-play:hover .nav__dropdown-play-pack.hov {
  opacity: 1;
}
.nav__dropdown-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 60px;
  margin-bottom: 6px;
}
.nav__dropdown-row-image {
  width: calc(33.3334% - 4px);
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #9695ce;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}
.nav__dropdown-row-image:hover {
  opacity: 0.8;
}
.nav__dropdown-row-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.nav__dropdown-content {
  width: 100%;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.nav__dropdown-content .nav__dropdown-item {
  width: calc(60% - 5px);
}
.nav__dropdown-leaderboard {
  width: calc(40% - 5px);
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #f172f9;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}
.nav__dropdown-leaderboard:hover {
  opacity: 0.8;
}
.nav__dropdown-leaderboard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.nav__dropdown-col {
  width: calc(33.33% - 5px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.nav__dropdown-col-wrapper {
  width: calc(66.66% - 5px);
}
.nav__dropdown-item {
  position: relative;
  min-height: 60px;
  border-radius: 5px;
  overflow: hidden;
  border: 0.3px solid #cd8d2e;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.nav__dropdown-item-title {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 9px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.nav__dropdown-item-title h5 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 15px;
  line-height: 18px;
  color: #ffc026;
  margin-right: 10px;
}
.nav__dropdown-item-title h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
}
.nav__dropdown-item-title h6 b {
  font-weight: 900;
}
.nav__dropdown-item-title svg {
  width: 12px !important;
  height: 13px !important;
  color: #ffc026 !important;
  margin-right: 9px !important;
}
.nav__dropdown-item-title-body h6 {
  margin-bottom: 5px;
}
.nav__dropdown-item-title-progress {
  background: #302f5c;
  border-radius: 8px;
  position: relative;
  width: 130px;
  height: 8px;
}
.nav__dropdown-item-title-progress-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  background: #1f78ff;
  border-radius: 8px;
}
.nav__dropdown-item-bg {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-10.16%, rgba(141, 65, 17, 0.4)), color-stop(64.67%, rgba(141, 65, 17, 0.1)));
  background: linear-gradient(180deg, rgba(141, 65, 17, 0.4) -10.16%, rgba(141, 65, 17, 0.1) 64.67%);
  z-index: 1;
}
.nav__dropdown-item-bg.black {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-10.16%, rgba(141, 65, 17, 0.4)), color-stop(64.67%, rgba(141, 65, 17, 0.1))), #222149;
  background: linear-gradient(180deg, rgba(141, 65, 17, 0.4) -10.16%, rgba(141, 65, 17, 0.1) 64.67%), #222149;
}
.nav__dropdown-item-bg.black.hov {
  background: -webkit-gradient(linear, left top, right top, color-stop(-70.03%, rgba(155, 65, 19, 0.2)), color-stop(83%, #9b4113)), #222149;
  background: linear-gradient(90deg, rgba(155, 65, 19, 0.2) -70.03%, #9b4113 83%), #222149;
}
.nav__dropdown-item-bg.hov {
  background: -webkit-gradient(linear, left top, right top, color-stop(-70.03%, rgba(155, 65, 19, 0.2)), color-stop(83%, #9b4113));
  background: linear-gradient(90deg, rgba(155, 65, 19, 0.2) -70.03%, #9b4113 83%);
  opacity: 0;
}
.nav__dropdown-item-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.nav__dropdown-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.nav__dropdown-item-image2 {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 0;
}
.nav__dropdown-item-image2 img {
  width: 100%;
}
.nav__dropdown-item-image2.hov {
  mix-blend-mode: lighten;
  z-index: 1;
  opacity: 0;
}
.nav__dropdown-item-ranks {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 140px;
  z-index: 1;
}
.nav__dropdown-item-ranks img {
  width: 100%;
}
.nav__dropdown-item:hover .nav__dropdown-item-bg {
  opacity: 0;
}
.nav__dropdown-item:hover .nav__dropdown-item-bg.hov {
  opacity: 1;
}
.nav__dropdown-item:hover .nav__dropdown-item-image2 {
  opacity: 0;
}
.nav__dropdown-item:hover .nav__dropdown-item-image2.hov {
  opacity: 1;
}
.nav__dropdown-jackpot {
  position: relative;
  min-height: 60px;
  border-radius: 5px;
  overflow: hidden;
  border: 0.5px solid #9695ce;
  background: -webkit-gradient(linear, left top, right top, from(#813cee), color-stop(49.5%, #6f30d3), to(#400499));
  background: linear-gradient(90deg, #813cee 0%, #6f30d3 49.5%, #400499 100%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.nav__dropdown-jackpot-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  bottom: 0;
  right: 128px;
  width: 200px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 6;
}
.nav__dropdown-jackpot-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.nav__dropdown-jackpot-coins {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 321px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.nav__dropdown-jackpot-coins img {
  width: 100%;
}
.nav__dropdown-jackpot-ticket {
  position: absolute;
  top: 0;
  right: 135px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 12px;
}
.nav__dropdown-jackpot-ticket img {
  width: 100%;
}
.nav__dropdown-jackpot-content {
  position: absolute;
  z-index: 5;
  top: 8px;
  right: 20px;
}
.nav__dropdown-jackpot-content h4 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 15px;
  line-height: 100%;
  text-align: center;
  color: #fff;
  margin-bottom: 5px;
}
.nav__dropdown-jackpot-content h5 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #fff;
  position: relative;
}
.nav__dropdown-jackpot-content h5::before {
  position: absolute;
  content: "";
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: calc(100% + 20px);
  height: calc(100% + 2px);
  background: #B05AF9;
  border-radius: 5px;
  z-index: -1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.nav__dropdown-jackpot:hover {
  border: 1px solid #b05af9;
}
.nav__dropdown-jackpot:hover .nav__dropdown-jackpot-image {
  width: 258px;
}
.nav__dropdown-jackpot:hover .nav__dropdown-jackpot-content h5::before {
  width: 202px;
}
.nav__dropdown.pack .nav__dropdown-play {
  border: 0.3px solid #f172f9;
  -webkit-box-shadow: 0px 4px 4px rgba(24, 24, 53, 0.2);
          box-shadow: 0px 4px 4px rgba(24, 24, 53, 0.2);
}
.nav__dropdown.pack .nav__dropdown-play-title svg {
  color: #f172f9 !important;
}
.nav__dropdown.pack .nav__dropdown-item {
  border: 0.3px solid #f172f9;
}
.nav__dropdown.pack .nav__dropdown-item-title h5 {
  color: #f172f9;
}
.nav__dropdown.pack .nav__dropdown-item-bg {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-10.16%, rgba(79, 17, 141, 0.5)), color-stop(64.67%, rgba(79, 17, 141, 0.1)));
  background: linear-gradient(180deg, rgba(79, 17, 141, 0.5) -10.16%, rgba(79, 17, 141, 0.1) 64.67%);
}
.nav__dropdown.pack .nav__dropdown-item-bg.hov {
  background: -webkit-gradient(linear, left top, right top, color-stop(-70.03%, rgba(70, 20, 126, 0.14)), color-stop(83%, rgba(70, 20, 126, 0.7)));
  background: linear-gradient(90deg, rgba(70, 20, 126, 0.14) -70.03%, rgba(70, 20, 126, 0.7) 83%);
}
.nav__dropdown.active {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  max-height: 165px;
}
.nav__head {
  display: none;
}
.nav__btns {
  display: none;
}
.nav__more {
  display: none;
}

.burger {
  display: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 7, 34, 0.8);
  border-radius: 6px;
  overflow: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 999;
}
.modal::-webkit-scrollbar {
  display: none;
}
.modal__inner {
  width: 100%;
  max-width: 490px;
  min-height: 422px;
  border-radius: 5px;
  margin: auto;
  padding: 40px 30px 30px 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  background: -webkit-gradient(linear, left bottom, left top, from(#2f2e5f), to(#2f2e5f)), #22214a;
  background: linear-gradient(0deg, #2f2e5f, #2f2e5f), #22214a;
  border: 2px solid #181835;
}
.modal__inner-close {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 15px;
  right: 15px;
  position: absolute;
  border-radius: 50%;
  background: none;
  overflow: hidden;
  color: #8988bc;
  cursor: pointer;
}
.modal__inner-close svg {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 12px;
  height: 12px;
  pointer-events: none;
}
.modal__inner-close:hover svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.modal__inner-title {
  margin-bottom: 40px;
}
.modal__inner-title.mb {
  margin-bottom: 98px;
}
.modal__inner-title h4 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
}
.modal__inner .button.continue {
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: calc(100% - 60px);
}
.modal__head {
  display: none;
}
.modalProgress {
  position: relative;
  padding-left: 24px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 26px;
}
.modalProgress__line {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 100%;
  height: 5px;
  background: #100f2d;
}
.modalProgress__line span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 0;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#30ac72), to(#60c946));
  background: linear-gradient(180deg, #30ac72 0%, #60c946 100%);
  z-index: 1;
}
.modalProgress__steps {
  width: calc(100% - 150px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.modalProgress__step {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  background: #181835;
}
.modalProgress__step::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background: #3d3c7b;
  border-radius: 50%;
  content: "";
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 0;
}
.modalProgress__step::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: -webkit-gradient(linear, left top, left bottom, from(#30ac72), to(#60c946));
  background: linear-gradient(180deg, #30ac72 0%, #60c946 100%);
  border-radius: 50%;
  content: "";
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 0;
}
.modalProgress__step span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #181835;
  z-index: 1;
  border-radius: 50%;
}
.modalProgress__step img {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 2;
}
.modalProgress__step img.green {
  opacity: 0;
}
.modalProgress__step.active::before {
  opacity: 0;
}
.modalProgress__step.active::after {
  opacity: 1;
}
.modalProgress__step.active img {
  opacity: 0;
}
.modalProgress__step.active img.green {
  opacity: 1;
}
.modalProgress__coin {
  width: 92px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  border-radius: 20.5px;
  padding: 0 36px 0 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.modalProgress__coin svg {
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 23px;
  height: 21px;
  color: #3eaeff;
  z-index: 2;
}
.modalProgress__coin svg path.otherpath {
  color: #005cc9;
}
.modalProgress__coin p {
  position: relative;
  z-index: 2;
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #8988bc;
}
.modalProgress__coin::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background: #3d3c7b;
  border-radius: 20.5px;
  content: "";
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 0;
}
.modalProgress__coin::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: -webkit-gradient(linear, left top, left bottom, from(#30ac72), to(#60c946));
  background: linear-gradient(180deg, #30ac72 0%, #60c946 100%);
  border-radius: 20.5px;
  content: "";
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 0;
}
.modalProgress__coin span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #181835;
  z-index: 1;
  border-radius: 20.5px;
}
.modalProgress__coin.active p {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-9.09%, #37b06c), to(#60c947)), #8988bc;
  background: linear-gradient(180deg, #37b06c -9.09%, #60c947 100%), #8988bc;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.modalProgress__coin.active::before {
  opacity: 0;
}
.modalProgress__coin.active::after {
  opacity: 1;
}
.modalStep .input__outer {
  margin-bottom: 7px;
}
.modalStep .input__row-label {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #8988bc;
  margin-bottom: 3px;
}
.modalStep .input__row-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.modalStep .input__row .input__outer {
  width: calc(33.33% - 5px);
  margin-bottom: 0;
}
.modalStep .input__row .customSelect {
  width: calc(33.33% - 5px);
}
.modalStep__btn {
  margin-top: 44px;
  display: block;
  border-radius: 5px;
  padding: 9px;
  background: none;
  cursor: pointer;
  width: 100%;
  position: relative;
  -webkit-box-shadow: 0px 4px 4px 0px #18183580;
          box-shadow: 0px 4px 4px 0px #18183580;
}
.modalStep__btn-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: none;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  padding: 4px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalStep__btn-inner::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #16512e;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalStep__btn-inner::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(#2cc07a), to(#6be24d));
  background: linear-gradient(180deg, #2cc07a 0%, #6be24d 100%);
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalStep__btn p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalStep__btn svg {
  width: 33px;
  height: 30px;
  z-index: 1;
  color: #3eaeff;
  margin-left: 4px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalStep__btn svg path {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalStep__btn svg path.otherpath {
  color: #005cc9;
}
.modalStep__btn span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalStep__btn span.default::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#30ac72), to(#60c946));
  background: linear-gradient(180deg, #30ac72 0%, #60c946 100%);
}
.modalStep__btn span.hov {
  opacity: 0;
}
.modalStep__btn span.hov::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border-radius: 5px;
  z-index: 0;
  background: #fff;
}
.modalStep__btn span.hov::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#30ac72), to(#60c946));
  background: linear-gradient(180deg, #30ac72 0%, #60c946 100%);
}
.modalStep__btn span.active {
  opacity: 0;
}
.modalStep__btn span.active::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 5px;
  z-index: 0;
  background: #fff;
}
.modalStep__btn span.active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
  background: #30ac53;
}
.modalStep__btn:hover span.default {
  opacity: 0;
}
.modalStep__btn:hover span.hov {
  opacity: 1;
}
.modalStep__btn:hover p {
  color: #16512e;
}
.modalStep__btn:hover svg {
  color: #ffffff;
}
.modalStep__btn:hover svg path.otherpath {
  color: #2187ff;
}
.modalStep__btn:hover .modalStep__btn-inner::before {
  opacity: 0;
}
.modalStep__btn:hover .modalStep__btn-inner::after {
  opacity: 1;
}
.modalStep__btn.active span.default {
  opacity: 0;
}
.modalStep__btn.active span.hov {
  opacity: 0;
}
.modalStep__btn.active span.active {
  opacity: 1;
}
.modalStep__btn.active p {
  color: #16512e;
}
.modalStep__btn.active svg {
  color: #ffffff;
  width: 23px;
  height: 16px;
  margin-left: 8px;
}
.modalStep__btn.active svg path.otherpath {
  color: #2187ff;
}
.modalStep__btn.active .modalStep__btn-inner {
  padding: 7px 0;
}
.modalStep__btn.active .modalStep__btn-inner::before {
  opacity: 0;
}
.modalStep__btn.active .modalStep__btn-inner::after {
  opacity: 0;
}
.modalStep__claimed {
  margin-top: 44px;
  display: block;
  border-radius: 5px;
  padding: 9px;
  background: none;
  cursor: pointer;
  width: 100%;
  position: relative;
  -webkit-box-shadow: 0px 4px 4px 0px #18183580;
          box-shadow: 0px 4px 4px 0px #18183580;
}
.modalStep__claimed-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: none;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  padding: 7px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalStep__claimed span.default {
  opacity: 0;
}
.modalStep__claimed span.hov {
  opacity: 0;
}
.modalStep__claimed span.active {
  opacity: 1;
}
.modalStep__claimed p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #16512e;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalStep__claimed svg {
  color: #ffffff;
  width: 23px;
  height: 16px;
  z-index: 1;
  margin-left: 8px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalStep__claimed span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalStep__claimed span.active {
  opacity: 1;
}
.modalStep__claimed span.active::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 5px;
  z-index: 0;
  background: #fff;
}
.modalStep__claimed span.active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
  background: #30ac53;
}
.modalLogin .input__outer {
  margin-bottom: 7px;
}
.modalLogin .input__outer:last-child {
  margin-bottom: 0;
}
.modalLogin__inputs {
  margin-bottom: 16px;
}
.modalLogin__link {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-underline-position: under;
  color: #8988bc;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalLogin__link:hover {
  color: #fff;
}
.modalOffer {
  width: 100%;
  min-height: 419px;
  max-width: 1170px;
  border-radius: 8px;
  overflow: hidden;
  margin: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.modalOffer__close {
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 22px;
  right: 16px;
  position: absolute;
  border-radius: 50%;
  background: none;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}
.modalOffer__close svg {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 18px;
  height: 18px;
  pointer-events: none;
  color: #8988bc;
}
.modalOffer__close:hover svg {
  color: #4e4d75;
}
.modalOffer__content {
  width: 63%;
  max-width: 736px;
  background: #d5d5f1;
  padding: 42px 48px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.modalOffer__content-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 34px;
}
.modalOffer__content-head-image {
  width: 180px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  overflow: hidden;
}
.modalOffer__content-head-image img {
  width: 100%;
}
.modalOffer__content-head-info {
  width: calc(100% - 197px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.modalOffer__content-head-info h3 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #0c0722;
  text-transform: uppercase;
}
.modalOffer__content-head-info h3 span {
  font-weight: 900;
}
.modalOffer__content-head-info h4 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 1;
  color: #307bd3;
}
.modalOffer__content-head-seperator {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #0c0722;
  margin: 0 5px;
}
.modalOffer__content-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 70px;
  background: #efefff;
  border-radius: 5px;
  margin-bottom: 18px;
  padding: 0 28px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.modalOffer__content-item:last-child {
  margin-bottom: 0;
}
.modalOffer__content-item-icon {
  width: 24px;
  height: 24px;
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 25px;
  line-height: 1;
  color: #313062;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 30px;
}
.modalOffer__content-item-icon svg {
  width: 100%;
  height: 100%;
}
.modalOffer__content-item p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #313062;
}
.modalOffer__content-item p b {
  font-weight: 800;
}
.modalOffer__content-item p b.black {
  font-weight: 900;
}
.modalOffer__content-item p span {
  text-decoration: underline;
  text-underline-position: under;
}
.modalOffer__content-item p i {
  font-style: normal;
  text-decoration: underline;
  text-underline-position: under;
}
.modalOffer__info {
  background: #efefff;
  width: 37%;
  max-width: 434px;
  padding: 56px 40px 42px 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
.modalOffer__info-title {
  margin-bottom: 60px;
}
.modalOffer__info-title h3 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #0c0722;
}
.modalOffer__info-desc p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #313062;
  margin-bottom: 9px;
}
.modalOffer__info-desc p:last-child {
  margin-bottom: 0;
}
.modalOffer__info-desc p span {
  font-weight: 800;
}
.modalOffer__info-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  position: absolute;
  bottom: 42px;
  left: 40px;
  cursor: pointer;
  background: none;
  width: calc(100% - 80px);
  -webkit-box-shadow: 0px 2px 2px rgba(21, 17, 50, 0.2);
          box-shadow: 0px 2px 2px rgba(21, 17, 50, 0.2);
  padding: 27px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.modalOffer__info-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 1;
  color: #fff;
  text-shadow: 0px 1px 2px #1ea855;
}
.modalOffer__info-btn span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalOffer__info-btn span.default {
  background: -webkit-gradient(linear, left top, left bottom, from(#34c05e), to(#008748));
  background: linear-gradient(180deg, #34c05e 0%, #008748 100%);
}
.modalOffer__info-btn span.hov {
  opacity: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#33dd66), to(#09b564));
  background: linear-gradient(180deg, #33dd66 0%, #09b564 100%);
}
.modalOffer__info-btn:hover {
  padding: 26.5px 0;
}
.modalOffer__info-btn:hover span.default {
  opacity: 0;
}
.modalOffer__info-btn:hover span.hov {
  opacity: 1;
}
.modalOffer__info-btn:hover p {
  font-size: 18px;
}
.modalJackpot {
  width: 100%;
  max-width: 797px;
  overflow: hidden;
  margin: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #d5d5f1;
  border-radius: 5px;
  padding: 50px 63px 41px 50px;
}
.modalJackpot__close {
  width: 12px;
  height: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 16px;
  right: 13px;
  position: absolute;
  border-radius: 50%;
  background: none;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}
.modalJackpot__close svg {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 12px;
  height: 12px;
  pointer-events: none;
  color: #8988bc;
}
.modalJackpot__close:hover svg {
  color: #4e4d75;
}
.modalJackpot__inner {
  width: 100%;
}
.modalJackpot__icon {
  position: absolute;
  width: 53px;
  height: 53px;
  z-index: 2;
  top: 0;
  left: 0;
  background: #1f78ff;
  border-radius: 0 0 4px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.modalJackpot__icon svg {
  width: 28px;
  height: 28px;
  color: #fff;
}
.modalJackpot__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 40px;
}
.modalJackpot__head h3 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #0c0722;
}
.modalJackpot__head h3 span {
  font-weight: 900;
}
.modalJackpot__head h4 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 1;
  color: #307bd3;
}
.modalJackpot__head-seperator {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #0c0722;
  margin: 0 5px;
}
.modalJackpot__desc {
  background: #efefff;
  border-radius: 5px;
  padding: 24px 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 30px;
}
.modalJackpot__desc p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #313062;
  margin-bottom: 20px;
  max-width: 410px;
}
.modalJackpot__desc ul li {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #313062;
  list-style-type: disc;
  list-style-position: inside;
}
.modalJackpot__desc ul li span {
  font-weight: 700;
}
.modalJackpot__rows {
  margin-bottom: 30px;
}
.modalJackpot__rows:last-child {
  margin-bottom: 0;
}
.modalJackpot__row {
  width: 100%;
  border-radius: 5px;
  background: #efefff;
  padding: 10px 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 70px;
  margin-bottom: 9px;
}
.modalJackpot__row:last-child {
  margin-bottom: 0;
}
.modalJackpot__row p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #313062;
  max-width: 580px;
}
.modalJackpot__row p span {
  font-weight: 700;
}
.modalJackpot__row p span.black {
  font-weight: 900;
}
.modalJackpot__row p span svg {
  width: 37px;
  height: 12px;
  color: #313062;
}
.modalJackpot__row p a {
  color: #313062;
  text-decoration: underline;
  text-underline-position: under;
}
.modalJackpot__disclaimer {
  width: 100%;
  background: #efefff;
  border-radius: 5px;
  padding: 20px 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.modalJackpot__disclaimer h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #313062;
  margin-bottom: 24px;
}
.modalJackpot__disclaimer p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #313062;
  margin-bottom: 20px;
}
.modalJackpot__disclaimer p span {
  font-weight: 700;
}
.modalJackpot__disclaimer p.light {
  margin-bottom: 0;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #313062;
  text-align: center;
}
.modalJackpot__disclaimer ul {
  padding-left: 22px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 28px;
}
.modalJackpot__disclaimer ul li {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #313062;
  margin-bottom: 20px;
  list-style-type: disc;
}
.modalJackpot__disclaimer ul li:last-child {
  margin-bottom: 0;
}
.modalProfile {
  width: 100%;
  max-width: 822px;
  overflow: hidden;
  margin: auto;
}
.modalProfile__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 2;
  bottom: -2px;
}
.modalProfile__btn {
  cursor: pointer;
  width: 219px;
  height: 45px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
  background: #0f0b26;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid #181835;
}
.modalProfile__btn::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(47, 46, 95, 0.5)), to(rgba(47, 46, 95, 0.5))), #0f0b26;
  background: linear-gradient(0deg, rgba(47, 46, 95, 0.5), rgba(47, 46, 95, 0.5)), #0f0b26;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__btn::after {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #8988bc;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__btn p {
  position: relative;
  z-index: 2;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  text-align: center;
  color: #8988bc;
  text-shadow: 0px 2px 2px rgba(47, 46, 95, 0.2);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__btn:first-child {
  border-radius: 5px 0 0 0;
  border-right: 1px solid #181835;
}
.modalProfile__btn:nth-child(2) {
  border-right: 1px solid #181835;
  border-left: 1px solid #181835;
}
.modalProfile__btn:last-child {
  border-radius: 0 5px 0 0;
  border-left: 1px solid #181835;
}
.modalProfile__btn:hover {
  border: 2px solid #2f2e5f;
}
.modalProfile__btn:hover::before {
  opacity: 0;
}
.modalProfile__btn:hover::after {
  opacity: 1;
  background: #8988bc;
}
.modalProfile__btn:hover p {
  font-weight: 900;
  color: #fff;
}
.modalProfile__btn.active {
  border: 2px solid #2f2e5f;
}
.modalProfile__btn.active::before {
  opacity: 0;
}
.modalProfile__btn.active::after {
  opacity: 1;
}
.modalProfile__btn.active p {
  font-weight: 900;
  color: #fff;
}
.modalProfile__inner {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  background: #d5d5f1;
  border-radius: 5px;
  padding: 43px 51px 43px 46px;
  background: #2f2e5f;
  border: 2px solid #181835;
  border-radius: 0 5px 5px 5px;
  min-height: 628px;
}
.modalProfile__inner-close {
  width: 12px;
  height: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 16px;
  right: 16px;
  position: absolute;
  border-radius: 50%;
  background: none;
  overflow: hidden;
  cursor: pointer;
  z-index: 11;
}
.modalProfile__inner-close svg {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 12px;
  height: 12px;
  pointer-events: none;
  color: #8988bc;
}
.modalProfile__inner-close:hover svg {
  color: #4e4d75;
}
.modalProfile__title h3 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}
.modalProfile__title.mb {
  margin-bottom: 40px;
}
.modalProfile__vip-slider {
  max-width: 550px;
  padding-top: 72px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: static;
}
.modalProfile__vip-slider-outer {
  padding-left: 35px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  background: #100f2d;
  border-radius: 5px;
  min-height: 329px;
}
.modalProfile__vip-slider-wrapper {
  padding-top: 50px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}
.modalProfile__vip-slider-wrapper-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 50px);
  background: -webkit-gradient(linear, right top, left top, from(#100f2d), color-stop(12.5%, rgba(16, 15, 45, 0)), color-stop(90.5%, rgba(16, 15, 45, 0)), to(#100f2d));
  background: linear-gradient(270deg, #100f2d 0%, rgba(16, 15, 45, 0) 12.5%, rgba(16, 15, 45, 0) 90.5%, #100f2d 100%);
  z-index: 1;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__vip-slider-item {
  padding-top: 15px;
  height: 190px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #1c1c43;
  border-radius: 5px 5px 0 0;
  position: relative;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__vip-slider-item-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 95px;
  margin: 0 auto 12px auto;
}
.modalProfile__vip-slider-item-image img {
  height: 100%;
}
.modalProfile__vip-slider-item-icon {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 23px;
  height: 16px;
}
.modalProfile__vip-slider-item-icon svg {
  width: 100%;
  height: 100%;
  color: #32ae70;
}
.modalProfile__vip-slider-item-outer {
  margin: 0 5px;
  position: relative;
  z-index: 5;
}
.modalProfile__vip-slider-item-claimed {
  background: #32ae70;
  height: 35px;
  width: 100%;
  cursor: pointer;
  border-radius: 0 0 5px 5px;
}
.modalProfile__vip-slider-item-claimed p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #242352;
  text-align: center;
}
.modalProfile__vip-slider-item-disabled {
  cursor: pointer;
  background: #8988bc;
  width: 100%;
  height: 35px;
  border-radius: 0 0 5px 5px;
}
.modalProfile__vip-slider-item-disabled p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #424272;
}
.modalProfile__vip-slider-item-disabled p b {
  font-weight: 900;
}
.modalProfile__vip-slider-item-btn {
  height: 35px;
  width: 100%;
  background: none;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}
.modalProfile__vip-slider-item-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #fff;
}
.modalProfile__vip-slider-item-btn p b {
  font-weight: 900;
}
.modalProfile__vip-slider-item-btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__vip-slider-item-btn span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  content: "";
  border-radius: 0 0 5px 5px;
}
.modalProfile__vip-slider-item-btn span:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  content: "";
  border-radius: 0 0 5px 5px;
}
.modalProfile__vip-slider-item-btn span.default::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#1f60ab), to(#1e5ca4));
  background: linear-gradient(180deg, #1f60ab 0%, #1e5ca4 100%);
}
.modalProfile__vip-slider-item-btn span.default::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#3e91f2), to(#307bd3)), #307bd3;
  background: linear-gradient(180deg, #3e91f2 0%, #307bd3 100%), #307bd3;
}
.modalProfile__vip-slider-item-btn span.hov {
  opacity: 0;
}
.modalProfile__vip-slider-item-btn span.hov::before {
  -webkit-filter: blur(1px);
          filter: blur(1px);
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#b0f0ff));
  background: linear-gradient(180deg, #ffffff 0%, #b0f0ff 100%);
}
.modalProfile__vip-slider-item-btn span.hov::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#3eb3f2), to(#3e91f2)), #307bd3;
  background: linear-gradient(180deg, #3eb3f2 0%, #3e91f2 100%), #307bd3;
}
.modalProfile__vip-slider-item-btn span.active {
  opacity: 0;
}
.modalProfile__vip-slider-item-btn span.active::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#b0f0ff));
  background: linear-gradient(180deg, #ffffff 0%, #b0f0ff 100%);
}
.modalProfile__vip-slider-item-btn span.active::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#3eb3f2), to(#3e91f2)), #307bd3;
  background: linear-gradient(180deg, #3eb3f2 0%, #3e91f2 100%), #307bd3;
}
.modalProfile__vip-slider-item-btn:hover span.hov {
  opacity: 1;
}
.modalProfile__vip-slider-item-btn:focus span.hov {
  opacity: 0;
}
.modalProfile__vip-slider-item-btn:focus span.active {
  opacity: 1;
}
.modalProfile__vip-slider-item-arrow {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: -8px;
  width: 15px;
  height: 15px;
  background: #1c1c43;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__vip-slider-item-level {
  position: absolute;
  top: -64px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 26px;
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 2;
}
.modalProfile__vip-slider-item-level::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 5px;
  background: #5e5c9e;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__vip-slider-item-level p {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  color: #c9c9f4;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__vip-slider-item-level.active::before {
  background: #3eaeff;
}
.modalProfile__vip-slider-item-level.active p {
  color: #fff;
}
.modalProfile__vip-slider-item-progress {
  width: calc(100% + 10px);
  position: absolute;
  left: -5px;
  top: -54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.modalProfile__vip-slider-item-progress-line {
  width: 50%;
  height: 6px;
  background: #5e5c9e;
  position: relative;
}
.modalProfile__vip-slider-item-progress-line span {
  position: absolute;
  left: 0;
  top: 0;
  height: 6px;
  background: #3eaeff;
}
.modalProfile__vip-slider-item h5 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  margin-bottom: 8px;
}
.modalProfile__vip-slider-item h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #8988bc;
  text-align: center;
}
.modalProfile__vip-slider-item h6 span {
  color: #c9c9f4;
}
.modalProfile__vip-slider-item::before {
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: calc(100% + 2px);
  height: calc(100% + 1px);
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, color-stop(6.05%, #7977be), to(#313062));
  background: linear-gradient(180deg, #7977be 6.05%, #313062 100%);
  z-index: -1;
  border-radius: 5px 5px 0 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
}
.modalProfile__vip-slider-item.claimed {
  background: #242352;
}
.modalProfile__vip-slider-item.claimed h5 {
  opacity: 0.5;
}
.modalProfile__vip-slider-item.claimed .modalProfile__vip-slider-item-image {
  opacity: 0.5;
}
.modalProfile__vip-slider-item.claimed .modalProfile__vip-slider-item-arrow {
  background: #242352;
}
.modalProfile__vip-slider-item.current {
  background: #2d2c61;
}
.modalProfile__vip-slider-item.current::before {
  opacity: 1;
}
.modalProfile__vip-slider-item.current .modalProfile__vip-slider-item-arrow {
  background: #2d2c61;
  border-top: 1px solid #7977be;
  border-left: 1px solid #7977be;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.modalProfile__vip-slider-tooltip {
  position: absolute;
  top: -48px;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  padding: 6px 8px;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.modalProfile__vip-slider-tooltip-wrapper {
  width: calc(100% + 10px);
  position: absolute;
  left: -5px;
  top: -54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.modalProfile__vip-slider-tooltip-outer {
  width: 50%;
  position: relative;
}
.modalProfile__vip-slider-tooltip::before {
  position: absolute;
  bottom: -5px;
  left: 50%;
  content: "";
  width: 10px;
  height: 10px;
  background: #fff;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.modalProfile__vip-slider-tooltip p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #3eaeff;
  text-shadow: 0px 1px 2px rgba(94, 92, 158, 0.3);
  white-space: nowrap;
}
.modalProfile__vip-slider-tooltip p span {
  color: #19174f;
  text-shadow: 0px 1px 2px rgba(94, 92, 158, 0.3);
}
.modalProfile__vip-slider .slick-list {
  overflow: unset;
}
.modalProfile__vip-slider .slick-prev {
  transform: translate(0, 0);
  -moz-ransform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  top: 8px;
  right: 35px;
  left: unset;
  width: 31px;
  height: 31px;
  background: #545390;
  border-radius: 8px;
  overflow: hidden;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__vip-slider .slick-prev:hover {
  opacity: 0.8;
}
.modalProfile__vip-slider .slick-prev.slick-disabled {
  opacity: 0.5;
}
.modalProfile__vip-slider .slick-prev::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  opacity: 1;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: url("../assets/icons/chevronLeft.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.modalProfile__vip-slider .slick-next {
  transform: translate(0, 0);
  -moz-ransform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  top: 8px;
  right: 0;
  width: 31px;
  height: 31px;
  background: #545390;
  border-radius: 8px;
  overflow: hidden;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__vip-slider .slick-next:hover {
  opacity: 0.8;
}
.modalProfile__vip-slider .slick-next.slick-disabled {
  opacity: 0.5;
}
.modalProfile__vip-slider .slick-next::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  opacity: 1;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: url("../assets/icons/chevronRight.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.modalProfile__vip-desc p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #8988bc;
}
.modalProfile__vip .modalProfile__prof-content-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  max-width: 576px;
  width: 100%;
  background: #181835;
  border: 1px solid #9796d0;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 10;
  padding: 12px 33px 27px 15px;
  margin-bottom: 0;
}
.modalProfile__vip .modalProfile__prof-content-item-btn {
  display: none;
}
.modalProfile__vip .modalProfile__prof-content-item-row-inner {
  width: 100%;
}
.modalProfile__vip-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 7, 34, 0.8);
  border-radius: 0 4px 4px 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 9;
}
.modalProfile__prof-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 32px;
}
.modalProfile__prof-avatar {
  width: 254px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.modalProfile__prof-avatar-image {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  overflow: hidden;
}
.modalProfile__prof-avatar-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.modalProfile__prof-avatar-image-outer {
  width: 205px;
  height: 205px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #100f2d;
  border: 1px solid #3d3c7b;
  border-radius: 5px;
}
.modalProfile__prof-avatar-icon {
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.modalProfile__prof-avatar-icon input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  -webkit-appearance: none;
}
.modalProfile__prof-avatar-icon svg {
  width: 47px;
  height: 47px;
  color: #383771;
}
.modalProfile__prof-avatar-btns {
  margin-left: 7px;
}
.modalProfile__prof-avatar-btn {
  width: 42px;
  height: 42px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #242352;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
  cursor: pointer;
}
.modalProfile__prof-avatar-btn input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  -webkit-appearance: none;
}
.modalProfile__prof-avatar-btn:last-child {
  margin-bottom: 0;
}
.modalProfile__prof-avatar-btn svg {
  width: 12px;
  height: 12px;
  color: #8988bc;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__prof-avatar-btn.edit svg {
  width: 19px;
  height: 19px;
}
.modalProfile__prof-avatar-btn:hover {
  background: #100f2d;
}
.modalProfile__prof-avatar-btn:hover svg {
  color: #fff;
}
.modalProfile__prof-info {
  max-width: 414px;
}
.modalProfile__prof-level {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #100f2d;
  border: 1px solid #3d3c7b;
  border-radius: 5px;
  padding: 9px 13px;
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.modalProfile__prof-level-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 67px;
  height: 42px;
  margin-right: 20px;
}
.modalProfile__prof-level-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.modalProfile__prof-level h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}
.modalProfile__prof-ranks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.modalProfile__prof-rank {
  width: 130px;
  height: 130px;
  background: #242352;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 12px;
}
.modalProfile__prof-rank:last-child {
  margin-right: 0;
}
.modalProfile__prof-rank-image {
  height: 109px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.modalProfile__prof-rank-image img {
  height: 100%;
}
.modalProfile__prof-rank-icon {
  width: 47px;
  height: 47px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.modalProfile__prof-rank-icon svg {
  width: 100%;
  height: 100%;
  color: #383771;
}
.modalProfile__prof-content {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #100f2d;
  border: 1px solid #3d3c7b;
  border-radius: 5px;
  padding: 16px;
}
.modalProfile__prof-content-item {
  margin-bottom: 12px;
}
.modalProfile__prof-content-item:last-child {
  margin-bottom: 0;
}
.modalProfile__prof-content-item-title {
  margin-bottom: 10px;
}
.modalProfile__prof-content-item-title p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #b5b4e7;
}
.modalProfile__prof-content-item-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.modalProfile__prof-content-item-row-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: calc(100% - 247px);
}
.modalProfile__prof-content-item-row .button.link {
  width: 115px;
  height: 33px;
}
.modalProfile__prof-content-item-btn {
  cursor: pointer;
  width: 229px;
  height: 33px;
  border-radius: 5px;
  background: #242352;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__prof-content-item-btn P {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #8988bc;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__prof-content-item-btn:hover {
  background: #434284;
}
.modalProfile__prof-content-item-btn:hover p {
  color: #b5b4e9;
}
.modalProfile__prof-content-item-btn:focus {
  background: #52519a;
}
.modalProfile__prof-content-item-btn:focus p {
  color: #b5b4e9;
}
.modalProfile__prof-content-item-input {
  width: calc(100% - 124px);
}
.modalProfile__prof-content-item-input .input {
  width: 100%;
}
.modalProfile__prof-content-item-input .input-outer {
  width: 100%;
}
.modalProfile__prof-content-item-input .input input {
  background: #2f2e5f;
  border: unset;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  padding: 10px 16px;
  border: 1px solid #2f2e5f;
}
.modalProfile__sec-email {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #100f2d;
  border: 1px solid #313062;
  border-radius: 5px;
  padding: 18px 18px 26px 18px;
  position: relative;
  margin-bottom: 36px;
}
.modalProfile__sec-email-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 14px;
}
.modalProfile__sec-email-title h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #b5b4e7;
  margin-right: 6px;
}
.modalProfile__sec-email-title-verif {
  padding: 0 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 16px;
  border-radius: 5px;
  background: #37b06c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__sec-email-title-verif p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 1;
  color: #0c0722;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__sec-email-title-verif.error {
  background: #b03749;
}
.modalProfile__sec-email-title-verif.error p {
  color: #fff;
}
.modalProfile__sec-email .input__outer {
  width: 100%;
  max-width: 285px;
  margin-bottom: 12px;
}
.modalProfile__sec-email .input input {
  background: #2f2e5f;
  border: unset;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  padding: 9px 10px;
}
.modalProfile__sec-email .input input::-webkit-input-placeholder {
  color: #fff;
}
.modalProfile__sec-email .input input::-moz-placeholder {
  color: #fff;
}
.modalProfile__sec-email .input input:-ms-input-placeholder {
  color: #fff;
}
.modalProfile__sec-email .input input::-ms-input-placeholder {
  color: #fff;
}
.modalProfile__sec-email .input input::placeholder {
  color: #fff;
}
.modalProfile__sec-email-btn {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: none;
}
.modalProfile__sec-email-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #b5b4e7;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalProfile__sec-email-btn:hover p {
  color: #fff;
}
.modalProfile__sec-email .button.link {
  width: 100px;
  height: 30px;
  position: absolute;
  bottom: 20px;
  right: 24px;
  z-index: 2;
}
.modalProfile__sec-pass {
  padding: 17px 16px 22px 16px;
  background: #100f2d;
  border: 1px solid #313062;
  border-radius: 5px;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.modalProfile__sec-pass-title {
  margin-bottom: 10px;
}
.modalProfile__sec-pass-title h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
}
.modalProfile__sec-pass .input__outer {
  width: 100%;
  max-width: 347px;
  margin-bottom: 8px;
}
.modalProfile__sec-pass .input__outer:last-child {
  margin-bottom: 0;
}
.modalProfile__sec-pass .input__outer label {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #8988bc;
  margin-bottom: 4px;
}
.modalProfile__sec-pass .input input {
  background: #2f2e5f;
  border: 1px solid #2f2e5f;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  padding: 8px 10px;
}
.modalProfile__sec-pass .input input::-webkit-input-placeholder {
  color: #fff;
}
.modalProfile__sec-pass .input input::-moz-placeholder {
  color: #fff;
}
.modalProfile__sec-pass .input input:-ms-input-placeholder {
  color: #fff;
}
.modalProfile__sec-pass .input input::-ms-input-placeholder {
  color: #fff;
}
.modalProfile__sec-pass .input input::placeholder {
  color: #fff;
}
.modalProfile__sec-pass .input.wicon input {
  padding: 8px 32px 8px 10px;
}
.modalProfile__sec-pass .button.link {
  width: 100px;
  height: 30px;
  position: absolute;
  bottom: 22px;
  right: 24px;
  z-index: 2;
}
.modalRoob {
  width: 100%;
  max-width: 659px;
  overflow: hidden;
  margin: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 31px 43px 40px 46px;
  min-height: 679px;
  background: #2f2e5f;
  border: 2px solid #181835;
  border-radius: 5px;
}
.modalRoob__close {
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 42px;
  right: 24px;
  position: absolute;
  border-radius: 50%;
  background: none;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}
.modalRoob__close svg {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 18px;
  height: 18px;
  pointer-events: none;
  color: #8988bc;
}
.modalRoob__close:hover svg {
  color: #4e4d75;
}
.modalRoob__inner {
  width: 100%;
}
.modalRoob__inner-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 43px;
}
.modalRoob__inner-title p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}
.modalRoob__inner-title img {
  width: 165px;
  height: 43px;
  margin: -4px 10px 0 10px;
}
.modalRoob__number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  z-index: 5;
}
.modalRoob__number p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #a69be6;
}
.modalRoob__step1 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 111px;
  position: relative;
  padding-left: 25px;
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
          box-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 24px;
}
.modalRoob__step1-content {
  margin-left: 130px;
  position: relative;
  z-index: 5;
}
.modalRoob__step1-content h5 {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 25px;
  text-align: right;
  letter-spacing: 0.08em;
  color: #6ab8ff;
  text-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
}
.modalRoob__step1-content h6 {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: right;
  color: #e0f0ff;
  text-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
}
.modalRoob__step1-image {
  z-index: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  max-width: 514px;
}
.modalRoob__step1-image img {
  height: 100%;
}
.modalRoob__step1-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  max-width: 398px;
  height: 100%;
  background: linear-gradient(90.01deg, #0e0b28 24.64%, rgba(14, 11, 40, 0) 99.99%);
  border-radius: 8px;
}
.modalRoob__step1-bg2 {
  position: absolute;
  top: 0;
  left: 56px;
  width: 100%;
  z-index: 1;
  max-width: 439px;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#0a0c1e), to(rgba(10, 12, 30, 0)));
  background: linear-gradient(90deg, #0a0c1e 0%, rgba(10, 12, 30, 0) 100%);
  border-radius: 8px;
}
.modalRoob__step2 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 111px;
  position: relative;
  padding-left: 21px;
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
          box-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 24px;
}
.modalRoob__step2-content {
  position: relative;
  z-index: 5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 24px;
}
.modalRoob__step2-content p {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.08em;
  color: #fff;
}
.modalRoob__step2-content img {
  margin-left: 10px;
  width: 115px;
  height: 51px;
  border-radius: 8px;
}
.modalRoob__step2-image {
  z-index: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  max-width: 320px;
}
.modalRoob__step2-image img {
  height: 100%;
}
.modalRoob__step2-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, color-stop(47.5%, #0e0b28), to(rgba(14, 11, 40, 0)));
  background: linear-gradient(90deg, #0e0b28 47.5%, rgba(14, 11, 40, 0) 100%);
  border-radius: 8px;
}
.modalRoob__step2-bg2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  max-width: 260px;
  background: #0e0b28;
  border-radius: 8px;
}
.modalRoob__step2-bg3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  max-width: 439px;
  background: -webkit-gradient(linear, left top, right top, from(#0a0c1e), to(rgba(10, 12, 30, 0)));
  background: linear-gradient(90deg, #0a0c1e 0%, rgba(10, 12, 30, 0) 100%);
  border-radius: 8px;
}
.modalRoob__step3 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 111px;
  position: relative;
  padding-left: 21px;
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
          box-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 70px;
}
.modalRoob__step3-content {
  position: relative;
  z-index: 5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 125px;
}
.modalRoob__step3-content p {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
  color: #fff;
}
.modalRoob__step3-image {
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  max-width: 253px;
}
.modalRoob__step3-image img {
  height: 100%;
}
.modalRoob__step3-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  height: 100%;
  max-width: 263px;
  background: #0e0b28;
}
.modalRoob__step3-bg2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, color-stop(47.5%, #0e0b28), to(rgba(14, 11, 40, 0)));
  background: linear-gradient(90deg, #0e0b28 47.5%, rgba(14, 11, 40, 0) 100%);
  border-radius: 8px;
}
.modalRoob__step3-bg3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  max-width: 514px;
  background: #0e0b28;
  border-radius: 8px;
}
.modalRoob__btn {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 74px;
  background: none;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalRoob__btn:hover {
  opacity: 0.8;
}
.modalRoob__btn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.modalPack {
  width: 100%;
  max-width: 659px;
  overflow: hidden;
  margin: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 43px 40px 46px;
  min-height: 679px;
  background: #2f2e5f;
  border: 2px solid #181835;
  border-radius: 5px;
}
.modalPack__close {
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 42px;
  right: 24px;
  position: absolute;
  border-radius: 50%;
  background: none;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}
.modalPack__close svg {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 18px;
  height: 18px;
  pointer-events: none;
  color: #8988bc;
}
.modalPack__close:hover svg {
  color: #4e4d75;
}
.modalPack__inner {
  width: 100%;
}
.modalPack__inner-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 25px;
}
.modalPack__inner-title p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}
.modalPack__inner-title img {
  width: 175px;
  height: 75px;
  margin: -3px 8px 0 8px;
}
.modalPack__number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  z-index: 5;
}
.modalPack__number p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #a69be6;
}
.modalPack__step1 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 111px;
  position: relative;
  padding-left: 25px;
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
          box-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 24px;
}
.modalPack__step1-content {
  margin-left: 130px;
  position: relative;
  z-index: 5;
}
.modalPack__step1-content h5 {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 25px;
  text-align: right;
  letter-spacing: 0.08em;
  color: #6ab8ff;
  text-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
}
.modalPack__step1-content h6 {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: right;
  color: #e0f0ff;
  text-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
}
.modalPack__step1-image {
  z-index: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}
.modalPack__step1-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.modalPack__step1-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  background: linear-gradient(90.01deg, #0e0b28 24.64%, rgba(14, 11, 40, 0) 99.99%);
  border-radius: 8px;
}
.modalPack__step2 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 111px;
  position: relative;
  padding-left: 21px;
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
          box-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 24px;
}
.modalPack__step2-content {
  position: relative;
  z-index: 5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 24px;
}
.modalPack__step2-content p {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.08em;
  color: #fff;
}
.modalPack__step2-content img {
  margin-left: 10px;
  width: 163px;
  height: 53px;
  border-radius: 8px;
}
.modalPack__step2-image {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  max-width: 244px;
}
.modalPack__step2-image img {
  height: 100%;
}
.modalPack__step2-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, color-stop(47.5%, #0e0b28), to(rgba(14, 11, 40, 0)));
  background: linear-gradient(90deg, #0e0b28 47.5%, rgba(14, 11, 40, 0) 100%);
  border-radius: 8px;
}
.modalPack__step2-bg2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  background: #0e0b28;
  border-radius: 8px;
}
.modalPack__step3 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 111px;
  position: relative;
  padding-left: 21px;
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
          box-shadow: 0px 2px 4px rgba(14, 11, 40, 0.5);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 70px;
}
.modalPack__step3-content {
  position: relative;
  z-index: 5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 125px;
}
.modalPack__step3-content p {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
  color: #fff;
}
.modalPack__step3-image {
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  max-width: 253px;
}
.modalPack__step3-image img {
  height: 100%;
}
.modalPack__step3-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  height: 100%;
  max-width: 263px;
  background: #0e0b28;
}
.modalPack__step3-bg2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, color-stop(47.5%, #0e0b28), to(rgba(14, 11, 40, 0)));
  background: linear-gradient(90deg, #0e0b28 47.5%, rgba(14, 11, 40, 0) 100%);
  border-radius: 8px;
}
.modalPack__step3-bg3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  max-width: 514px;
  background: #0e0b28;
  border-radius: 8px;
}
.modalPack .button.play {
  width: 100%;
  height: 74px;
}
.modalShop {
  width: 100%;
  min-height: 335px;
  max-width: 1170px;
  border-radius: 8px;
  overflow: hidden;
  margin: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.modalShop__close {
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 22px;
  right: 16px;
  position: absolute;
  border-radius: 50%;
  background: none;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}
.modalShop__close svg {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 18px;
  height: 18px;
  pointer-events: none;
  color: #8988bc;
}
.modalShop__close:hover svg {
  color: #4e4d75;
}
.modalShop__content {
  width: 63%;
  max-width: 736px;
  background: #d5d5f1;
  padding: 40px 60px 57px 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.modalShop__content-image {
  width: 100%;
  position: relative;
  padding-bottom: 100%;
  z-index: 2;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalShop__content-image-outer {
  width: 35%;
  max-width: 204px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.modalShop__content-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalShop__content-info {
  width: calc(65% - 30px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.modalShop__content-info h3 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 200%;
  letter-spacing: 0.06em;
  color: #0c0722;
  margin-bottom: 8px;
}
.modalShop__content-info h3 span {
  font-weight: 900;
}
.modalShop__content-desc {
  background: #efefff;
  border-radius: 5px;
  min-height: 175px;
  padding: 18px 20px 30px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #313062;
}
.modalShop__content-desc p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #313062;
}
.modalShop__info {
  background: #efefff;
  width: 37%;
  max-width: 434px;
  padding: 40px 38px 57px 38px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.modalShop__info-btn {
  background: none;
  padding: 15px 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  position: relative;
  -webkit-box-shadow: 0px 2px 2px rgba(21, 17, 50, 0.2);
          box-shadow: 0px 2px 2px rgba(21, 17, 50, 0.2);
  border-radius: 5px;
  overflow: hidden;
}
.modalShop__info-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 26.2672px;
  line-height: 32px;
  color: #fff;
  text-shadow: 0px 2.63549px 5.27099px #00489c;
}
.modalShop__info-btn img {
  width: 25px;
  height: 42px;
  margin-left: 10px;
  -webkit-transform: rotate(16.92deg);
          transform: rotate(16.92deg);
  -webkit-filter: drop-shadow(0px 2.63549px 5.27099px #00489c);
          filter: drop-shadow(0px 2.63549px 5.27099px #00489c);
}
.modalShop__info-btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 100%;
  height: 100%;
}
.modalShop__info-btn span.default {
  background: -webkit-gradient(linear, left top, left bottom, from(#34c05e), to(#008748));
  background: linear-gradient(180deg, #34c05e 0%, #008748 100%);
}
.modalShop__info-btn span.hov {
  opacity: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#33dd66), to(#09b564));
  background: linear-gradient(180deg, #33dd66 0%, #09b564 100%);
}
.modalShop__info-btn:hover span.hov {
  opacity: 1;
}
.modalBonus {
  width: 100%;
  max-width: 659px;
  border-radius: 5px;
  border: 2px solid #181835;
  background: #2f2e5f;
  overflow: hidden;
  margin: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
.modalBonus__close {
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 29px;
  right: 24px;
  position: absolute;
  border-radius: 50%;
  background: none;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}
.modalBonus__close svg {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 18px;
  height: 18px;
  pointer-events: none;
  color: #8988bc;
}
.modalBonus__close:hover svg {
  color: #4e4d75;
}
.modalBonus__title {
  padding: 26px 36px 19px 58px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.modalBonus__title h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}
.modalBonus__action {
  padding: 42px 40px 24px 58px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: rgba(24, 24, 53, 0.5);
}
.modalBonus__action .bonusSelect {
  margin-bottom: 38px;
}
.modalBonus__action-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.modalBonus__action-btns h6 {
  width: 20px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 15px;
  line-height: 1;
  color: #8988bc;
}
.modalBonus__action-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  min-height: 44px;
  width: 100%;
  max-width: 330px;
  background: #008748;
  border: 1px solid #007034;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalBonus__action-btn:hover {
  opacity: 0.8;
}
.modalBonus__action-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
}
.modalBonus__action-btn p span {
  font-weight: 900;
}
.modalBonus__action-btn.register {
  width: 163px;
  background: #307bd3;
  border: 1px solid #1e5ca4;
}
.modalBonus__action-btn.register p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 1;
  color: #fff;
  text-shadow: 0px 1px 2px rgba(23, 35, 140, 0.3);
}
.modalBonus__action-btn.register p span {
  font-weight: 900px;
}
.modalBonus__footer {
  padding: 11px 40px 17px 58px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.modalBonus__footer-box {
  border: 1px solid #8988bc;
  background: #181835;
  border-radius: 3px;
  padding: 16px 20px 10px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 20px;
}
.modalBonus__footer-box h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #8988bc;
  margin-bottom: 24px;
}
.modalBonus__footer-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.modalBonus__footer-item {
  width: 145px;
  height: 44px;
  position: relative;
  margin: 10px 25px 10px 0;
  border-radius: 5px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalBonus__footer-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(24, 24, 53, 0.5);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.modalBonus__footer-item img {
  width: 100%;
  height: 100%;
}
.modalBonus__footer-item.active {
  border: 1px solid #50ff20;
}
.modalBonus__footer-item.active::before {
  opacity: 0;
}
.modalBonus__footer-desc {
  padding: 27px 32px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #8988bc;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;
}
.modalBonus__footer-desc-elem {
  background: #181835;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  position: relative;
}
.modalBonus__footer-desc-elem::before {
  position: absolute;
  left: 8px;
  top: 4px;
  width: 6px;
  height: 10px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  content: "";
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
}
.modalBonus__footer-desc-elem.active::before {
  opacity: 1;
}
.modalBonus__footer-desc p {
  width: calc(100% - 48px);
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #181835;
}
.modalBonus__footer-desc p span {
  font-weight: 800;
}
.modalBonus__footer-desc p b {
  font-weight: 900;
}
.modalBonus__footer .button.play {
  width: 100%;
  height: 74px;
}
.modalSuccess {
  width: 100%;
  max-width: 659px;
  border-radius: 5px;
  border: 2px solid #181835;
  background: #2f2e5f;
  overflow: hidden;
  margin: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  padding: 35px 45px 50px 45px;
}
.modalSuccess__close {
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 36px;
  right: 24px;
  position: absolute;
  border-radius: 50%;
  background: none;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}
.modalSuccess__close svg {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 18px;
  height: 18px;
  pointer-events: none;
  color: #8988bc;
}
.modalSuccess__close:hover svg {
  color: #4e4d75;
}
.modalSuccess__title {
  margin-bottom: 24px;
}
.modalSuccess__title h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}
.modalSuccess__image {
  width: 100%;
  max-width: 280px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
}
.modalSuccess__image img {
  width: 100%;
}
.modalSuccess__desc {
  background: rgba(24, 24, 53, 0.5);
  border-radius: 20px;
  min-height: 163px;
  padding: 24px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.modalSuccess__desc h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  margin-bottom: 8px;
}
.modalSuccess__desc p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #8988bc;
}

.input {
  position: relative;
}
.input__outer label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #8988bc;
  margin-bottom: 3px;
}
.input__outer label svg {
  width: 37px;
  height: 12px;
  margin-right: 4px;
}
.input button {
  width: 20px;
  height: 20px;
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 12px;
}
.input button svg {
  width: 100%;
  height: 100%;
  color: #fff;
}
.input input,
.input textarea {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: block;
  resize: none;
  padding: 13px 18px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #100f2d;
  border: 1px solid #3d3c7b;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #fff;
  width: 100%;
}
.input input::-webkit-input-placeholder, .input textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #3c3b7a;
}
.input input::-moz-placeholder, .input textarea::-moz-placeholder {
  opacity: 1;
  color: #3c3b7a;
}
.input input:-ms-input-placeholder, .input textarea:-ms-input-placeholder {
  opacity: 1;
  color: #3c3b7a;
}
.input input::-ms-input-placeholder, .input textarea::-ms-input-placeholder {
  opacity: 1;
  color: #3c3b7a;
}
.input input::placeholder,
.input textarea::placeholder {
  opacity: 1;
  color: #3c3b7a;
}
.input.error input,
.input.error textarea {
  border: 1px solid #b03749;
}
.input.wicon input {
  padding: 13px 38px 13px 18px;
}

.customSelect {
  position: relative;
  z-index: 1;
}
.customSelect__selected {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
  border: 1px solid #3d3c7b;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #100f2d;
  padding: 13px 18px;
  border-radius: 5px;
}
.customSelect__selected span {
  display: block;
  width: calc(100% - 20px);
  text-align: left;
  color: #fff;
}
.customSelect__selected span.placeholder {
  color: #3c3b7a;
}
.customSelect__selected svg {
  width: 16px;
  height: 16px;
  color: #8988bc;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.customSelect__options {
  position: absolute;
  top: 100%;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  left: 0;
  width: 100%;
  border-radius: 6px;
  background: #fff;
  border: 1px solid #3d3c7b;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  background: #100f2d;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.customSelect__options::-webkit-scrollbar {
  display: none;
}
.customSelect__options span {
  display: block;
  text-align: center;
}
.customSelect.active {
  z-index: 2;
}
.customSelect.active .customSelect__selected svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.customSelect.active .customSelect__options {
  max-height: 200px;
  overflow-y: auto;
  opacity: 1;
}
.customSelect.error .customSelect__selected {
  border: 1px solid #b03749;
}
.customSelect__option {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #3d3c7b;
  color: #fff;
}
.customSelect__option:last-child {
  border-bottom: unset;
}
.bonusSelect {
  position: relative;
  z-index: 1;
  width: 100%;
  cursor: pointer;
  border: 1px solid #8988bc;
  border-radius: 3px;
  background: #181835;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.bonusSelect__selected {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 15px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.bonusSelect__selected svg {
  width: 16px;
  height: 16px;
  color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.bonusSelect__selected-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bonusSelect__selected-inner-icon {
  width: 22px;
  height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 6px;
}
.bonusSelect__selected-inner-icon svg {
  width: 100%;
  height: 100%;
  color: #fff;
  -webkit-transform: rotate(0deg) !important;
          transform: rotate(0deg) !important;
}
.bonusSelect__selected-inner p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #fff;
}
.bonusSelect__options {
  width: 100%;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.bonusSelect__options::-webkit-scrollbar {
  display: none;
}
.bonusSelect__options span {
  display: block;
  text-align: center;
}
.bonusSelect.active {
  z-index: 2;
}
.bonusSelect.active .bonusSelect__selected svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.bonusSelect.active .bonusSelect__options {
  max-height: 200px;
  overflow-y: auto;
  opacity: 1;
}
.bonusSelect__option {
  padding: 15px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.bonusSelect__option svg {
  width: 22px;
  height: 22px;
  color: #fff;
  margin-right: 6px;
}
.bonusSelect__option p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #fff;
}

.languageSelect {
  position: relative;
  z-index: 1;
}
.languageSelect__selected {
  cursor: pointer;
  width: 44px;
  height: 34px;
  padding: 7px 13px 7px 11px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #1f1d47;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 5px 0 0 5px;
  overflow: hidden;
}
.languageSelect__selected-image {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #2d2c61;
  -webkit-box-shadow: 0px 2px 2px 0px #100f2d80;
          box-shadow: 0px 2px 2px 0px #100f2d80;
  border-radius: 17.5px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.languageSelect__selected-image img {
  width: 100%;
  height: 100%;
}
.languageSelect__options {
  position: absolute;
  top: 100%;
  background: #100f2d;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 0px 1px 4px rgba(173, 172, 222, 0.1);
          box-shadow: 0px 1px 4px rgba(173, 172, 222, 0.1);
  left: 0;
  width: 100%;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.languageSelect__options::-webkit-scrollbar {
  display: none;
}
.languageSelect:hover .languageSelect__selected {
  background: #3d3c6c;
  padding: 5px 11px 6px 10px;
}
.languageSelect:hover .languageSelect__selected-image {
  width: 23px;
  height: 23px;
  -webkit-box-shadow: 0px 2px 2px 0px #2d2c61;
          box-shadow: 0px 2px 2px 0px #2d2c61;
}
.languageSelect.active {
  z-index: 2;
}
.languageSelect.active .languageSelect__selected {
  background: #adacde;
  padding: 7px 13px 7px 11px;
  border-radius: 5px 5px 0 0;
}
.languageSelect.active .languageSelect__selected-image {
  border: 1px solid #adacde;
  width: 20px;
  height: 20px;
  -webkit-box-shadow: 0px 2px 2px 0px #3d3c6c80;
          box-shadow: 0px 2px 2px 0px #3d3c6c80;
}
.languageSelect.active .languageSelect__options {
  max-height: 200px;
  overflow-y: auto;
  opacity: 1;
}
.languageSelect__option {
  cursor: pointer;
  width: 44px;
  height: 34px;
  padding: 7px 13px 7px 11px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.languageSelect__option-image {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #2d2c61;
  -webkit-box-shadow: 0px 2px 2px 0px #100f2d80;
          box-shadow: 0px 2px 2px 0px #100f2d80;
  border-radius: 17.5px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.languageSelect__option-image img {
  width: 100%;
  height: 100%;
}
.languageSelect__option:hover {
  background: #3d3c6c;
  padding: 5px 11px 6px 10px;
}
.languageSelect__option:hover .languageSelect__option-image {
  width: 23px;
  height: 23px;
  -webkit-box-shadow: 0px 2px 2px 0px #2d2c61;
          box-shadow: 0px 2px 2px 0px #2d2c61;
}

.Toastify__close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 1;
}
.Toastify__close-button svg {
  width: 12px;
  height: 12px;
  color: #8988bc;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.Toastify__close-button:hover svg {
  color: #fff;
}
.Toastify__toast {
  padding: 0;
  min-height: 70px;
  background: #1f1d47;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: unset;
}
.Toastify__toast::before {
  position: absolute;
  left: 50%;
  top: calc(50% + 1px);
  width: calc(100% + 2px);
  height: calc(100% + 4px);
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  border-radius: 5px;
  z-index: -1;
}
.Toastify__toast-container {
  width: 340px;
}
.Toastify__toast-body {
  background: #1f1d47;
  -webkit-box-flex: unset;
      -ms-flex: unset;
          flex: unset;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.Toastify__toast-body > div:last-child {
  width: calc(100% - 52px);
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  padding: 11px 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.Toastify__toast-body h6 {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #b5b4e2;
  margin-bottom: 5px;
}
.Toastify__toast-body p {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #b5b4e2;
}
.Toastify__toast-icon {
  width: 52px;
  min-height: 70px;
  border-radius: 5px 0 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #0d0823;
  margin-right: 0;
}
.Toastify__toast.toastSuccess::before {
  background: #47d764;
}
.Toastify__toast.toastSuccess .Toastify__toast-icon svg {
  width: 18px;
  height: 13px;
  color: #47d764;
}
.Toastify__toast.toastError::before {
  background: #d74747;
}
.Toastify__toast.toastError .Toastify__toast-icon svg {
  width: 17px;
  height: 17px;
  color: #d74747;
}
.Toastify__toast.toastWarn::before {
  background: #d7ae47;
}
.Toastify__toast.toastWarn .Toastify__toast-icon svg {
  width: 7px;
  height: 22px;
  color: #d7ae47;
}
.Toastify__toast.toastInfo::before {
  background: #307bd3;
}
.Toastify__toast.toastInfo .Toastify__toast-icon svg {
  width: 8px;
  height: 27px;
  color: #307bd3;
}

.loader {
  width: 100px;
  padding: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #307bd3;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  -webkit-animation: l3 1s infinite linear;
          animation: l3 1s infinite linear;
}
.loader__outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 150px 0 200px 0;
}
.loader__outer h1 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 23px;
  line-height: 20px;
  color: #fff;
  margin-bottom: 64px;
}
.loader__outer.fixed {
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@-webkit-keyframes l3 {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@keyframes l3 {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
.notify {
  position: relative;
  margin-right: 20px;
}
.notify__btn {
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #2f2e5f;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 15.29px;
  cursor: pointer;
  position: relative;
  border: 1px solid #181835;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.notify__btn svg {
  width: 13px;
  height: 14px;
  color: #adacde;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.notify__btn-number {
  position: absolute;
  top: -5px;
  right: -5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #1f78ff;
  border-radius: 15.29px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 15px;
  min-height: 15px;
  padding: 3px;
}
.notify__btn-number p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 9px;
  line-height: 1;
  color: #fff;
}
.notify__btn:hover {
  background: #3f3b80;
  border: 1px solid #fff;
}
.notify__btn:hover svg {
  color: #fff;
}
.notify__btn.active {
  background: #3f3b80;
  border: 1px solid #fff;
}
.notify__btn.active svg {
  color: #fff;
}
.notify__menu {
  position: absolute;
  top: calc(100% + 15px);
  right: 0;
  background: #363270;
  width: 516px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 22px;
  border-radius: 10px;
}
.notify__menu::before {
  position: absolute;
  right: 6px;
  top: -6px;
  width: 20px;
  height: 20px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background: #363270;
  content: "";
  z-index: 1;
}
.notify__menu-inner {
  max-height: 700px;
  overflow-y: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.notify__menu-inner::-webkit-scrollbar {
  display: none;
}
.notify__menu-head {
  background: #1f1d47;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 22px 22px 22px 31px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 26px;
}
.notify__menu-head h4 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
}
.notify__menu-head-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #1f78ff;
  border-radius: 30px;
  min-width: 30px;
  min-height: 30px;
}
.notify__menu-head-number p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 18px;
  line-height: 1;
  color: #fff;
}
.notify__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 11px 50px 10px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #0a0a17;
  border: 1px solid #0a0a17;
  position: relative;
  border-radius: 10px;
  margin-bottom: 16px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}
.notify__item:last-child {
  margin-bottom: 0;
}
.notify__item-image {
  width: 58px;
  height: 58px;
  position: relative;
  background: #1f1d47;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.notify__item-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.notify__item-image-illu {
  position: absolute;
  bottom: 0;
  right: -11px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  overflow: hidden;
}
.notify__item-image-illu img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: unset;
}
.notify__item-image-illu.kick {
  background: #32ae70;
}
.notify__item-image-illu.gtb {
  background: purple;
}
.notify__item-image-illu.rewards {
  background: #f3c24d;
}
.notify__item-image-illu.vip {
  background: #b03749;
}
.notify__item-image-illu.info {
  background: #1f78ff;
}
.notify__item-content {
  width: calc(100% - 86px);
}
.notify__item-content h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.05em;
  color: #7c77bf;
  margin-bottom: 4px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.notify__item-content p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.05em;
  color: #7c77bf;
}
.notify__item-close {
  position: absolute;
  top: 50%;
  background: none;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 30px;
  width: 13px;
  height: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}
.notify__item-close svg {
  width: 100%;
  height: 100%;
  color: #3f3b80;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.notify__item-close:hover svg {
  color: #d9d9d9;
}
.notify__item.unread {
  border: 1px solid #7c77bf;
  background: #3f3b80;
}
.notify__item.unread .notify__item-content h6 {
  color: #fff;
}
.notify__item.unread .notify__item-close:hover svg {
  opacity: 0.8;
}
.notify__item.unread .notify__item-close svg {
  color: #d9d9d9;
}

.offr {
  position: relative;
}
.offr::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  background: -webkit-gradient(linear, right top, left top, from(#100f2d), color-stop(19.5%, rgba(16, 15, 45, 0)), color-stop(80.5%, rgba(16, 15, 45, 0)), to(#0c0722));
  background: linear-gradient(270deg, #100f2d 0%, rgba(16, 15, 45, 0) 19.5%, rgba(16, 15, 45, 0) 80.5%, #0c0722 100%);
}
.offr:hover .offr__prof {
  opacity: 1;
}
.offr:hover .offr__pop {
  opacity: 1;
}
.offr:hover .offr__rolex {
  opacity: 1;
}
.offr__inner {
  position: relative;
  z-index: 2;
  padding: 85px 0 120px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.offr__inner h3 {
  margin-bottom: 16px;
}
.offr__inner h3 span {
  font-weight: 600;
}
.offr__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.offr__row-btns {
  position: absolute;
  right: -5px;
  bottom: 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 2;
}
.offr__row-info {
  width: 32px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  background: #2c84ec;
  border: 0.5px solid #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin-right: 6px;
  -webkit-box-shadow: 0px 2px 6px rgba(16, 15, 45, 0.3);
          box-shadow: 0px 2px 6px rgba(16, 15, 45, 0.3);
}
.offr__row-info svg {
  width: 16px;
  height: 16px;
  color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__row-info:hover {
  background: #2c9fec;
}
.offr__row-info:hover svg {
  width: 19px;
  height: 19px;
}
.offr__row-info:focus {
  background: #2c9fec;
}
.offr__row-info:focus svg {
  width: 16px;
  height: 16px;
}
.offr__row-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 7px 8px 7px 17px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 162px;
  border: 1px solid #fff;
  border-radius: 5px;
  position: relative;
  background: none;
  cursor: pointer;
  -webkit-box-shadow: 0px 2px 6px rgba(16, 15, 45, 0.3);
          box-shadow: 0px 2px 6px rgba(16, 15, 45, 0.3);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  overflow: hidden;
}
.offr__row-btn::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(#34c05e), to(#008748));
  background: linear-gradient(180deg, #34c05e 0%, #008748 100%);
  z-index: -1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__row-btn::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(#33dd66), to(#09b564));
  background: linear-gradient(180deg, #33dd66 0%, #09b564 100%);
  z-index: 0;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__row-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  text-shadow: 0px 1px 2px #1b8b4e;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__row-btn-icon {
  width: 16px;
  height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 1;
}
.offr__row-btn-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  background: #187147;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.offr__row-btn-icon::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  background: #43e274;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__row-btn-icon svg {
  width: 16px;
  height: 16px;
  color: #fff;
}
.offr__row-btn:hover p {
  font-size: 11px;
  line-height: 13px;
}
.offr__row-btn:hover::after {
  opacity: 1;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  z-index: -1;
}
.offr__row-btn:hover .offr__row-btn-icon {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}
.offr__row-btn:hover .offr__row-btn-icon::after {
  opacity: 1;
}
.offr__row-btn:focus p {
  font-size: 10px;
  line-height: 12px;
}
.offr__row-btn:focus::after {
  opacity: 1;
  -webkit-filter: unset;
          filter: unset;
  z-index: -1;
}
.offr__row-btn:focus .offr__row-btn-icon::after {
  opacity: 1;
}
.offr__row-bonus {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.offr__row-bonus img {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.offr__row-bonus p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #c9c9f4;
}
.offr__prof {
  opacity: 0.6;
  width: calc(33.3334% - 20px);
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__prof-wrapper {
  position: relative;
  height: 203px;
  margin-bottom: 16px;
}
.offr__prof-inner {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
}
.offr__prof-inner::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 33px;
  background: #efefff;
  content: "";
  z-index: 1;
}
.offr__prof-inner-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: -1;
}
.offr__prof-inner-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.offr__prof-inner-image {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 189px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__prof-inner-image img {
  width: 100%;
}
.offr__prof-inner-logo {
  position: absolute;
  top: 8px;
  left: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 72px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__prof-inner-logo img {
  width: 100%;
}
.offr__prof-inner-candy {
  position: absolute;
  left: 0;
  bottom: 26px;
  width: 89px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__prof-inner-candy img {
  width: 100%;
}
.offr__prof-inner-bomb {
  position: absolute;
  right: 0;
  bottom: 12px;
  width: 84px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__prof-inner-bomb img {
  width: 100%;
}
.offr__prof-inner-roobet {
  position: absolute;
  top: 0;
  left: 5px;
  width: 97px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
}
.offr__prof-inner-roobet img {
  width: 100%;
}
.offr__prof-tag {
  position: absolute;
  right: -5px;
  top: -5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 106px;
  height: 26px;
  border-radius: 5px;
  background: #424e96;
}
.offr__prof-tag::before {
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  border-radius: 5px;
  background: -webkit-gradient(linear, left top, left bottom, from(#19d5fa), to(#0070c6));
  background: linear-gradient(180deg, #19d5fa 0%, #0070c6 100%);
  z-index: -1;
}
.offr__prof-tag img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.offr__prof-tag p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-shadow: 0px 2px 4px rgba(84, 28, 167, 0.3);
}
.offr__prof:hover .offr__prof-inner-image {
  width: 184px;
  top: 48px;
}
.offr__prof:hover .offr__prof-inner-logo {
  width: 70px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
.offr__prof:hover .offr__prof-inner-roobet {
  opacity: 1;
}
.offr__prof:hover .offr__prof-inner-candy {
  opacity: 0.8;
  bottom: 20px;
  width: 72px;
}
.offr__prof:hover .offr__prof-inner-bomb {
  opacity: 0.5;
  width: 72px;
}
.offr__pop {
  opacity: 0.6;
  width: calc(33.3334% - 20px);
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__pop-wrapper {
  position: relative;
  height: 203px;
  margin-bottom: 10px;
}
.offr__pop-inner {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
}
.offr__pop-inner::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 33px;
  background: #efefff;
  content: "";
  z-index: 1;
}
.offr__pop-inner-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -3;
  background: -webkit-gradient(linear, left top, left bottom, from(#591bab), color-stop(68.24%, #a345b9), to(#e86cff));
  background: linear-gradient(180deg, #591bab 0%, #a345b9 68.24%, #e86cff 100%);
}
.offr__pop-inner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  opacity: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#591bab), color-stop(68.24%, #a345b9), to(#e86cff));
  background: linear-gradient(180deg, #591bab 0%, #a345b9 68.24%, #e86cff 100%);
  mix-blend-mode: overlay;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__pop-inner-image {
  position: absolute;
  top: 46px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 205px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__pop-inner-image img {
  width: 100%;
}
.offr__pop-inner-text {
  position: absolute;
  top: 102px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 159px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__pop-inner-text img {
  width: 100%;
}
.offr__pop-inner-coinleft {
  position: absolute;
  bottom: 19px;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 154px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__pop-inner-coinleft img {
  width: 100%;
}
.offr__pop-inner-coinright {
  position: absolute;
  bottom: 23px;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 172px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__pop-inner-coinright img {
  width: 100%;
}
.offr__pop-inner-ellipse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__pop-inner-ellipse.one {
  width: 114px;
  height: 114px;
  border: 12px solid #6321ad;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.offr__pop-inner-ellipse.two {
  width: 216px;
  height: 216px;
  border: 20px solid #6321ad;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.offr__pop-inner-ellipse.three {
  width: 322px;
  height: 322px;
  border: 30px solid #6321ad;
}
.offr__pop-inner-roobet {
  position: absolute;
  top: 0;
  left: 5px;
  width: 97px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
.offr__pop-inner-roobet img {
  width: 100%;
}
.offr__pop-tag {
  position: absolute;
  right: -5px;
  top: -5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 106px;
  height: 26px;
  border-radius: 5px;
  background: #8928af;
}
.offr__pop-tag::before {
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  border-radius: 5px;
  background: -webkit-gradient(linear, left top, left bottom, from(#6824ae), to(#4c0a96));
  background: linear-gradient(180deg, #6824ae 0%, #4c0a96 100%);
  z-index: -1;
}
.offr__pop-tag img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.offr__pop-tag p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-shadow: 0px 2px 4px rgba(84, 28, 167, 0.3);
}
.offr__pop:hover .offr__pop-inner-ellipse.one {
  width: 185px;
  height: 185px;
  border: 12px solid #6321ad;
  -webkit-filter: blur(25px);
          filter: blur(25px);
  -webkit-transform: translateX(-50%) translateY(0);
          transform: translateX(-50%) translateY(0);
  top: -4px;
}
.offr__pop:hover .offr__pop-inner-ellipse.two {
  top: 48px;
  -webkit-transform: translateX(-50%) translateY(0);
          transform: translateX(-50%) translateY(0);
  width: 77px;
  height: 77px;
  border: 10px solid #6321ad;
}
.offr__pop:hover .offr__pop-inner-ellipse.three {
  top: -88px;
  -webkit-transform: translateX(-50%) translateY(0);
          transform: translateX(-50%) translateY(0);
  width: 149px;
  height: 149px;
  border: 100px solid #6321ad;
}
.offr__pop:hover .offr__pop-inner-image {
  top: 39px;
  width: 225px;
}
.offr__pop:hover .offr__pop-inner-overlay {
  opacity: 1;
  z-index: -1;
}
.offr__pop:hover .offr__pop-inner-text {
  width: 175px;
}
.offr__pop:hover .offr__pop-inner-roobet {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.offr__rolex {
  opacity: 0.6;
  width: calc(33.3334% - 20px);
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__rolex-wrapper {
  position: relative;
  height: 203px;
  margin-bottom: 16px;
}
.offr__rolex-inner {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.offr__rolex-inner::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 33px;
  background: #efefff;
  content: "";
  z-index: 1;
}
.offr__rolex-image {
  position: absolute;
  top: -13px;
  left: -40px;
  width: 142px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__rolex-image img {
  width: 100%;
}
.offr__rolex-content {
  position: absolute;
  right: 18px;
  top: 20px;
  min-width: 188px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__rolex-content h5 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1.01165px 2.0233px rgba(51, 94, 224, 0.5);
  margin-bottom: 5px;
  position: relative;
  z-index: 2;
}
.offr__rolex-content h4 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #82f9ff;
  text-shadow: 0px 1.01165px 2.0233px rgba(51, 94, 224, 0.5);
  margin-bottom: 70px;
  position: relative;
  z-index: 2;
}
.offr__rolex-content h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10.1165px;
  line-height: 12px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1.01165px 2.0233px rgba(51, 94, 224, 0.5);
  position: relative;
  z-index: 2;
}
.offr__rolex-btn {
  width: 188px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 57px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-shadow: 0px 2.0233px 10.1165px #386fcf;
          box-shadow: 0px 2.0233px 10.1165px #386fcf;
  border-radius: 100px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__rolex-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  width: 188px;
}
.offr__rolex-btn span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.offr__rolex-btn span::before {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 192px;
  height: 54px;
  content: "";
  border-radius: 100px;
  background: -webkit-gradient(linear, left top, left bottom, from(#4ddbff), to(#00aaff));
  background: linear-gradient(180deg, #4ddbff 0%, #00aaff 100%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__rolex-btn span::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 188px;
  height: 50px;
  background: #00ccff;
  content: "";
  border-radius: 100px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offr__rolex-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#2245bb), to(#00abff)), #22214a;
  background: linear-gradient(180deg, #2245bb 0%, #00abff 100%), #22214a;
}
.offr__rolex-bg2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 143px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(34, 69, 187, 0)), to(#69cdfe));
  background: linear-gradient(180deg, rgba(34, 69, 187, 0) 0%, #69cdfe 100%);
  mix-blend-mode: overlay;
}
.offr__rolex:hover .offr__rolex-image {
  top: -37px;
  left: -50px;
  width: 181px;
}
.offr__rolex:hover .offr__rolex-btn {
  width: 228px;
}
.offr__rolex:hover .offr__rolex-btn span::before {
  width: 232px;
}
.offr__rolex:hover .offr__rolex-btn span::after {
  width: 228px;
}
.offr__rolex:hover .offr__rolex-content {
  top: 14px;
}

.offers {
  position: relative;
}
.offers::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  background: -webkit-gradient(linear, right top, left top, from(#100f2d), color-stop(19.5%, rgba(16, 15, 45, 0)), color-stop(80.5%, rgba(16, 15, 45, 0)), to(#0c0722));
  background: linear-gradient(270deg, #100f2d 0%, rgba(16, 15, 45, 0) 19.5%, rgba(16, 15, 45, 0) 80.5%, #0c0722 100%);
}
.offers::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  content: "";
  background: rgba(16, 15, 45, 0.5);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offers:hover::after {
  opacity: 0;
  z-index: -1;
}
.offers__inner {
  padding: 60px 0 180px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.offers__inner h3 {
  margin-bottom: 30px;
}
.offersItem {
  margin-bottom: 29px;
  background: #efefff;
  border-radius: 8px;
  padding: 10px 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 75px;
}
.offersItem:last-child {
  margin-bottom: 0;
}
.offersItem__content {
  width: calc(100% - 326px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 180px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  background: rgba(150, 149, 206, 0.3);
  border-radius: 8px;
  height: 55px;
}
.offersItem__content-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  overflow: hidden;
}
.offersItem__content-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.offersItem__content-image.disabled img {
  -webkit-filter: brightness(20%);
          filter: brightness(20%);
}
.offersItem__content-cols {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.offersItem__content-col {
  width: 33.3334%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.offersItem__content-col h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 1;
  text-align: center;
  color: #0c0722;
  margin-bottom: 12px;
}
.offersItem__content-col h6 span {
  font-weight: 900;
}
.offersItem__content-col p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 1;
  text-align: center;
  color: #307bd3;
}
.offersItem__content-col-empty {
  width: 14px;
  height: 3px;
  background: #9191c4;
}
.offersItem__icon {
  width: 55px;
  height: 44px;
  background: #307bd3;
  -webkit-box-shadow: 0px 2px 2px rgba(21, 17, 50, 0.2);
          box-shadow: 0px 2px 2px rgba(21, 17, 50, 0.2);
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
}
.offersItem__icon svg {
  width: 24px;
  height: 24px;
  color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offersItem__icon:hover {
  border: 1px solid #fff;
  background: #2c9fec;
}
.offersItem__icon:hover svg {
  width: 27px;
  height: 27px;
}
.offersItem__icon:focus {
  border: 1px solid #fff;
  background: #2c9fec;
}
.offersItem__icon:focus svg {
  width: 24px;
  height: 24px;
}
.offersItem__btn {
  width: 246px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  border-radius: 5px;
  padding: 11.5px 0;
  background: none;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offersItem__btn span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.offersItem__btn span.default {
  opacity: 1;
}
.offersItem__btn span.default::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border-radius: 5px;
  z-index: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#289c64), to(#167346));
  background: linear-gradient(180deg, #289c64 0%, #167346 100%);
}
.offersItem__btn span.default::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#34c05e), to(#008748));
  background: linear-gradient(180deg, #34c05e 0%, #008748 100%);
}
.offersItem__btn span.hov {
  opacity: 0;
}
.offersItem__btn span.hov::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 5px;
  z-index: 0;
  background: #fff;
}
.offersItem__btn span.hov::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#33dd66), to(#09b564));
  background: linear-gradient(180deg, #33dd66 0%, #09b564 100%);
}
.offersItem__btn span.active {
  opacity: 0;
}
.offersItem__btn span.active::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  border-radius: 5px;
  z-index: 0;
  background: #fff;
  background: -webkit-gradient(linear, left top, left bottom, from(#33dd66), to(#09b564));
  background: linear-gradient(180deg, #33dd66 0%, #09b564 100%);
}
.offersItem__btn span.active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 0;
}
.offersItem__btn:hover {
  -webkit-box-shadow: 0px 2px 2px rgba(21, 17, 50, 0.2);
          box-shadow: 0px 2px 2px rgba(21, 17, 50, 0.2);
}
.offersItem__btn:hover span.default {
  opacity: 0;
}
.offersItem__btn:hover span.hov {
  opacity: 1;
}
.offersItem__btn:focus {
  -webkit-box-shadow: 0px 2px 2px rgba(21, 17, 50, 0.2);
          box-shadow: 0px 2px 2px rgba(21, 17, 50, 0.2);
}
.offersItem__btn:focus span.default {
  opacity: 0;
}
.offersItem__btn:focus span.hov {
  opacity: 0;
}
.offersItem__btn:focus span.active {
  opacity: 1;
}
.offersItem__btn p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1px 2px #1ea855;
}

.tos-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #ffffff;
}

.tos-content h1 {
  text-align: center;
  margin-bottom: 40px;
}

.tos-content section {
  margin-bottom: 30px;
}

.tos-content h2 {
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.tos-content h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.tos-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.tos-content a {
  color: #007BFF;
  text-decoration: none;
}

.tos-content a:hover {
  text-decoration: underline;
}

.privacy-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #ffffff;
}

.privacy-content h1 {
  text-align: center;
  margin-bottom: 40px;
}

.privacy-content section {
  margin-bottom: 30px;
}

.privacy-content h2 {
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.privacy-content h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.privacy-content ul,
.privacy-content ol {
  list-style-type: disc;
  padding-left: 20px;
}

.privacy-content ol {
  list-style-type: decimal;
}

.privacy-content a {
  color: #007BFF;
  text-decoration: none;
}

.privacy-content a:hover {
  text-decoration: underline;
}

.privacy-content em {
  font-style: italic;
}

.guessContent__prizes-hugeShards {
  cursor: pointer;
  position: relative;
  padding-right: 9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background: #a419df;
  border: 1px solid #E15EFF;
  border-radius: 1.62177px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 126px;
  height: 28px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin: 6px 7px 6px 0;
}

.guessContent__prizes-hugeShards img {
  position: absolute;
  left: 5px;
  bottom: 0;
  height: 26px;
}

.guessContent__prizes-hugeShards p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 10.6303px;
  line-height: 13px;
  text-align: right;
  color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.guessContent__prizes-hugeShards:hover {
  background: #e15eff;
  border: 1px solid #fff;
}

.guessContent__prizes-hugeShards:hover p {
  color: #ffffff;
}

.switchpr {
  background: #22214a;
  position: relative;
  position: relative;
}
.switchpr::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  content: "";
  background: -webkit-gradient(linear, right top, left top, from(#100f2d), color-stop(19.5%, rgba(16, 15, 45, 0)), color-stop(80.5%, rgba(16, 15, 45, 0)), to(#0c0722));
  background: linear-gradient(270deg, #100f2d 0%, rgba(16, 15, 45, 0) 19.5%, rgba(16, 15, 45, 0) 80.5%, #0c0722 100%);
}
.switchpr::after {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  content: "";
  background: rgba(16, 15, 45, 0.5);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchpr__head {
  position: absolute;
  left: 0;
  top: 0;
  height: 74px;
  width: 100%;
  z-index: 2;
  background: -webkit-gradient(linear, right top, left top, from(#0c0722), color-stop(19.66%, rgba(16, 15, 45, 0)), color-stop(79.28%, rgba(16, 15, 45, 0)), to(#0c0722)), #100f2d;
  background: linear-gradient(270deg, #0c0722 0%, rgba(16, 15, 45, 0) 19.66%, rgba(16, 15, 45, 0) 79.28%, #0c0722 100%), #100f2d;
}
.switchpr__inner {
  padding-bottom: 140px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  z-index: 2;
}
.switchpr__wrapper {
  padding-top: 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: rgba(20, 18, 50, 0.3);
}
.switchpr__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 70px;
}
.switchpr__btn {
  width: 233px;
  margin-right: 20px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 40px;
  background: none;
  padding: 0 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.switchpr__btn:last-child {
  margin-right: 0;
}
.switchpr__btn svg {
  margin-right: 10px;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchpr__btn svg path {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchpr__btn p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  color: #fff;
  position: relative;
  z-index: 2;
}
.switchpr__btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  z-index: 1;
}
.switchpr__btn span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  border-radius: 5px;
  content: "";
}
.switchpr__btn span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  content: "";
}
.switchpr__btn span.default::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#1d1c42), to(#191838));
  background: linear-gradient(180deg, #1d1c42 0%, #191838 100%);
}
.switchpr__btn span.default::after {
  background: #100f2d;
}
.switchpr__btn span.hov {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
}
.switchpr__btn span.active {
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchpr__btn.roobet svg {
  width: 24px;
  height: 24px;
  color: #68689f;
}
.switchpr__btn.roobet svg path.otherpath {
  color: #9695ce;
}
.switchpr__btn.roobet span.hov::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#f5aa28), to(#be6620));
  background: linear-gradient(180deg, #f5aa28 0%, #be6620 100%);
}
.switchpr__btn.roobet span.hov::after {
  background: radial-gradient(38.37% 53.66% at 13.3% 53.66%, rgba(165, 73, 3, 0.5) 0%, rgba(107, 47, 0, 0.5) 100%), #100f2d;
}
.switchpr__btn.roobet span.active::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#f5aa28), to(#be6620));
  background: linear-gradient(180deg, #f5aa28 0%, #be6620 100%);
}
.switchpr__btn.roobet span.active::after {
  background: radial-gradient(38.37% 53.66% at 13.3% 53.66%, rgba(107, 47, 0, 0.5) 0%, rgba(85, 28, 1, 0.5) 100%), #100f2d;
}
.switchpr__btn.pack svg {
  width: 16px;
  height: 16px;
  color: #9695ce;
}
.switchpr__btn.pack svg path.otherpath {
  color: #3b3a70;
}
.switchpr__btn.pack span.hov::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#d4a1ff), to(#bc6cff));
  background: linear-gradient(180deg, #d4a1ff 0%, #bc6cff 100%);
}
.switchpr__btn.pack span.hov::after {
  background: radial-gradient(38.37% 53.66% at 13.3% 53.66%, rgba(126, 44, 233, 0.5) 0%, rgba(85, 37, 147, 0.5) 100%), #100f2d;
}
.switchpr__btn.pack span.active::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#d4a1ff), to(#bc6cff));
  background: linear-gradient(180deg, #d4a1ff 0%, #bc6cff 100%);
}
.switchpr__btn.pack span.active::after {
  background: radial-gradient(38.37% 53.66% at 13.3% 53.66%, rgba(85, 37, 147, 0.5) 0%, rgba(43, 27, 85, 0.5) 100%), #100f2d;
}
.switchpr__btn:hover span.hov {
  opacity: 1;
}
.switchpr__btn:hover p {
  font-weight: 500;
}
.switchpr__btn:hover.roobet svg {
  width: 25px;
  height: 25px;
  color: #f5aa28;
}
.switchpr__btn:hover.roobet svg path.otherpath {
  color: #be6620;
}
.switchpr__btn:hover.pack svg {
  width: 18px;
  height: 18px;
  color: #d4a1ff;
}
.switchpr__btn:hover.pack svg path.otherpath {
  color: #7940c3;
}
.switchpr__btn.active span.active {
  opacity: 1;
}
.switchpr__btn.active.roobet svg {
  color: #f5aa28;
}
.switchpr__btn.active.roobet svg path.otherpath {
  color: #be6620;
}
.switchpr__btn.active.pack svg {
  color: #d4a1ff;
}
.switchpr__btn.active.pack svg path.otherpath {
  color: #7940c3;
}
.switchpr__btn.active:hover span.hov {
  opacity: 1;
}
.switchpr__btn.active:hover span.active {
  opacity: 0;
}
.switchprPack {
  padding: 0 16px 16px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: none;
  -webkit-animation: fadeIn 0.3s linear;
          animation: fadeIn 0.3s linear;
}
.switchprPack__title {
  display: none;
}
.switchprPack__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}
.switchprPack__board {
  position: relative;
  padding: 16px 0 18px 53px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  width: 65.5%;
  height: 182px;
}
.switchprPack__board::before {
  border: 1px solid #9695ce;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  content: "";
  background: -webkit-gradient(linear, left top, right top, from(rgba(70, 20, 126, 0.2)), color-stop(90%, #191839)), #22214a;
  background: linear-gradient(90deg, rgba(70, 20, 126, 0.2) 0%, #191839 90%), #22214a;
}
.switchprPack__board-bg3 {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
  position: absolute;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  content: "";
  border: 1px solid #9747ff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: -webkit-gradient(linear, left top, right top, color-stop(-70.03%, rgba(70, 20, 126, 0.2)), color-stop(83%, #46147e)), #22214a;
  background: linear-gradient(90deg, rgba(70, 20, 126, 0.2) -70.03%, #46147e 83%), #22214a;
}
.switchprPack__board-icon {
  width: 23px;
  height: 21px;
  top: 18px;
  left: 18px;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.switchprPack__board-icon svg {
  width: 100%;
  height: 100%;
  color: #bc6cff;
}
.switchprPack__board-content h2 {
  line-height: 28px;
  color: #fff;
  margin-bottom: 11px;
}
.switchprPack__board-content h2 span {
  font-weight: 600;
}
.switchprPack__board-content p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #e5bdee;
  max-width: 245px;
}
.switchprPack__board-podium {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  position: absolute;
  bottom: 1px;
  right: 1px;
}
.switchprPack__board-podium-first {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  background: -webkit-gradient(linear, left top, left bottom, from(#f7c9ff), to(#8c3fe2));
  background: linear-gradient(180deg, #f7c9ff 0%, #8c3fe2 100%);
  opacity: 0.7;
  height: 106px;
  width: 98px;
  border-radius: 8px 8px 0 0;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.switchprPack__board-podium-second {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  background: -webkit-gradient(linear, left top, left bottom, from(#4a489d), to(#22214a));
  background: linear-gradient(180deg, #4a489d 0%, #22214a 100%);
  opacity: 0.7;
  width: 98px;
  height: 72px;
  border-radius: 8px 0 0 0;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.switchprPack__board-podium-third {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  background: #7038af;
  opacity: 0.7;
  width: 66px;
  height: 41px;
  border-radius: 0 0 3px 0;
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}
.switchprPack__board-star {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__board-star img {
  width: 100%;
}
.switchprPack__board-star.one {
  width: 28px;
  top: 74px;
  right: 204px;
}
.switchprPack__board-star.two {
  width: 26px;
  bottom: 48px;
  right: 27px;
}
.switchprPack__board-star.three {
  width: 17px;
  top: 45px;
  right: 55px;
}
.switchprPack__board-bg {
  position: absolute;
  right: 1px;
  bottom: 1px;
  height: 100%;
  width: 328px;
  background: radial-gradient(54.96% 51.65% at 60.16% 81.87%, #ffffff 0%, #8c40e2 66.78%, rgba(140, 64, 226, 0) 100%);
  mix-blend-mode: overlay;
  opacity: 0.7;
}
.switchprPack__board-bg2 {
  position: absolute;
  right: 1px;
  bottom: 1px;
  height: 100%;
  width: 328px;
  background: radial-gradient(65.29% 64.88% at 65.74% 81.87%, #e178fb 0%, #8c40e2 15%, rgba(35, 26, 73, 0) 100%);
  mix-blend-mode: overlay;
  opacity: 0.7;
}
.switchprPack__board-crown {
  position: absolute;
  top: 20px;
  right: 84px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 60px;
  z-index: 2;
}
.switchprPack__board-crown img {
  width: 100%;
}
.switchprPack__board-image {
  position: absolute;
  top: 62px;
  right: 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 161px;
  z-index: 2;
}
.switchprPack__board-image img {
  width: 100%;
}
.switchprPack__board-winner {
  position: absolute;
  top: 98px;
  right: 52px;
  width: 124px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 20px;
  z-index: 2;
}
.switchprPack__board-winner p {
  position: relative;
  z-index: 2;
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  color: #f6dfff;
  margin-top: -2px;
}
.switchprPack__board-winner p span {
  color: #e29bff;
}
.switchprPack__board-winner::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background: -webkit-gradient(linear, left top, left bottom, from(#da9aff), color-stop(145%, #c457f2));
  background: linear-gradient(180deg, #da9aff 0%, #c457f2 145%);
  border-radius: 5px;
}
.switchprPack__board-winner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  background: -webkit-gradient(linear, left bottom, left top, from(#3f26cd), color-stop(95%, #6447ff));
  background: linear-gradient(360deg, #3f26cd 0%, #6447ff 95%);
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
.switchprPack__board .button.green {
  height: 38px;
  position: absolute;
  bottom: 10px;
  right: 12px;
  width: 204px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__board:hover .button.green {
  bottom: 22px;
}
.switchprPack__board:hover::before {
  opacity: 0;
}
.switchprPack__board:hover .switchprPack__board-bg3 {
  opacity: 1;
}
.switchprPack__board:hover .switchprPack__board-podium-first {
  width: 120px;
  height: 130px;
  opacity: 0.7;
}
.switchprPack__board:hover .switchprPack__board-podium-second {
  width: 120px;
  height: 88px;
  opacity: 0.7;
}
.switchprPack__board:hover .switchprPack__board-podium-third {
  width: 57px;
  height: 50px;
  opacity: 0.7;
}
.switchprPack__board:hover .switchprPack__board-star.one {
  width: 31px;
  top: 82px;
  right: 248px;
}
.switchprPack__board:hover .switchprPack__board-star.two {
  width: 33px;
  bottom: 30px;
  right: 15px;
}
.switchprPack__board:hover .switchprPack__board-star.three {
  width: 25px;
  top: 16px;
  right: 16px;
}
.switchprPack__register {
  width: 32.5%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  padding: 18px 0 0 38px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.switchprPack__register-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 14px;
  left: 16px;
  z-index: 2;
}
.switchprPack__register-icon p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: #bc6cff;
}
.switchprPack__register-content {
  position: relative;
  z-index: 2;
}
.switchprPack__register-content h2 {
  margin-bottom: 10px;
}
.switchprPack__register-content h2 span {
  font-weight: 600;
}
.switchprPack__register-content p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #e5bdee;
  max-width: 252px;
}
.switchprPack__register-image {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-filter: blur(2px);
          filter: blur(2px);
  opacity: 0.5;
}
.switchprPack__register-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__register-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(70, 20, 126, 0.2)), color-stop(90%, #191839)), #22214a;
  background: linear-gradient(90deg, rgba(70, 20, 126, 0.2) 0%, #191839 90%), #22214a;
  border: 1px solid #9695ce;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__register-bg2 {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, color-stop(-70%, rgba(70, 20, 126, 0.2)), color-stop(83%, #46147e));
  background: linear-gradient(90deg, rgba(70, 20, 126, 0.2) -70%, #46147e 83%);
  border: 1px solid #984ae9;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__register-btn {
  z-index: 2;
  position: absolute;
  bottom: 13px;
  left: 50%;
  cursor: pointer;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 100%;
  max-width: 276px;
  height: 37px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: none;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__register-btn h6 {
  position: relative;
  z-index: 2;
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 17px;
  line-height: 21px;
  color: #fff;
  text-shadow: 0px 1px 2px rgba(23, 35, 140, 0.3);
  margin-right: 10px;
}
.switchprPack__register-btn h6 b {
  font-weight: 400;
}
.switchprPack__register-btn-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  z-index: 2;
}
.switchprPack__register-btn-inner p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #bc6cff;
  margin-right: 4px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__register-btn-inner svg {
  width: 16px;
  height: 16px;
  color: #ce93ff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__register-btn-inner svg path {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__register-btn-inner svg path.otherpath {
  color: #8c3fe2;
}
.switchprPack__register-btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__register-btn span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 5px;
}
.switchprPack__register-btn span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.switchprPack__register-btn span.default::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#bc6cff), to(#8b3ee1));
  background: linear-gradient(180deg, #bc6cff 0%, #8b3ee1 100%);
}
.switchprPack__register-btn span.default::after {
  background: radial-gradient(83.1% 436.25% at 50% 50%, #2b1b55 0%, #1d1b38 100%);
}
.switchprPack__register-btn span.hov {
  opacity: 0;
}
.switchprPack__register-btn span.hov::before {
  -webkit-filter: blur(1px);
          filter: blur(1px);
  background: -webkit-gradient(linear, left top, left bottom, from(#f8a8ff), to(#bc6cff));
  background: linear-gradient(180deg, #f8a8ff 0%, #bc6cff 100%);
}
.switchprPack__register-btn span.hov::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#bc6cff), to(#8b3ee1));
  background: linear-gradient(180deg, #bc6cff 0%, #8b3ee1 100%);
}
.switchprPack__register-btn span.active {
  opacity: 0;
}
.switchprPack__register-btn span.active::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#f8a8ff), to(#bc6cff));
  background: linear-gradient(180deg, #f8a8ff 0%, #bc6cff 100%);
}
.switchprPack__register-btn span.active::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#bc6cff), to(#8b3ee1));
  background: linear-gradient(180deg, #bc6cff 0%, #8b3ee1 100%);
}
.switchprPack__register-btn:hover .switchprPack__register-btn-inner p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #f7a7ff;
  text-shadow: 0px 1px 2px rgba(23, 35, 140, 0.3);
}
.switchprPack__register-btn:hover .switchprPack__register-btn-inner svg {
  color: #f7a7ff;
}
.switchprPack__register-btn:hover .switchprPack__register-btn-inner svg path.otherpath {
  color: #f7a7ff;
}
.switchprPack__register-btn:hover span.hov {
  opacity: 1;
}
.switchprPack__register-btn:focus .switchprPack__register-btn-inner p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #f7a7ff;
  text-shadow: 0px 1px 2px rgba(23, 35, 140, 0.3);
}
.switchprPack__register-btn:focus .switchprPack__register-btn-inner svg {
  color: #f7a7ff;
}
.switchprPack__register-btn:focus .switchprPack__register-btn-inner svg path.otherpath {
  color: #f7a7ff;
}
.switchprPack__register-btn:focus span.hov {
  opacity: 0;
}
.switchprPack__register-btn:focus span.active {
  opacity: 1;
}
.switchprPack__register-btn:focus:hover span.hov {
  opacity: 1;
}
.switchprPack__register-btn:focus:hover span.active {
  opacity: 0;
}
.switchprPack__register:hover .switchprPack__register-bg {
  opacity: 0;
}
.switchprPack__register:hover .switchprPack__register-bg2 {
  opacity: 1;
  z-index: 1;
}
.switchprPack__register:hover .switchprPack__register-image img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.switchprPack__register:hover .switchprPack__register-btn {
  bottom: 25px;
}
.switchprPack__free {
  width: 100%;
  position: relative;
  padding: 18px 0 0 53px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 218px;
  border-radius: 5px;
  overflow: hidden;
}
.switchprPack__free-icon {
  position: relative;
  z-index: 2;
  position: absolute;
  top: 14px;
  left: 18px;
  width: 23px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.switchprPack__free-icon svg {
  width: 100%;
  height: 100%;
  color: #bc6cff;
}
.switchprPack__free-content {
  position: relative;
  z-index: 2;
  max-width: 245px;
}
.switchprPack__free-content h2 {
  margin-bottom: 14px;
}
.switchprPack__free-content h2 span {
  font-weight: 600;
}
.switchprPack__free-content p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #e5bdee;
  max-width: 245px;
}
.switchprPack__free-buy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  bottom: 12px;
  right: 460px;
  width: 88px;
  height: 33px;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__free-buy::before {
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: -webkit-gradient(linear, left top, left bottom, from(#fefefd), to(#f68fff));
  background: linear-gradient(180deg, #fefefd 0%, #f68fff 100%);
}
.switchprPack__free-buy::after {
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#9f46ff), to(#8134dc));
  background: linear-gradient(180deg, #9f46ff 0%, #8134dc 100%);
}
.switchprPack__free-buy p {
  position: relative;
  z-index: 2;
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 15px;
  line-height: 18px;
  color: #f8d6fe;
}
.switchprPack__free-buy p span {
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  margin-left: 3px;
}
.switchprPack__free-gift {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  bottom: 14px;
  right: 262px;
  width: 116px;
  height: 42px;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__free-gift::before {
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  background: -webkit-gradient(linear, left top, left bottom, from(#fefefd), to(#76fbff));
  background: linear-gradient(180deg, #fefefd 0%, #76fbff 100%);
}
.switchprPack__free-gift::after {
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#3d8eee), to(#327ed7));
  background: linear-gradient(180deg, #3d8eee 0%, #327ed7 100%);
}
.switchprPack__free-gift p {
  position: relative;
  z-index: 2;
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  text-shadow: 0px 2px 4px #2266b7;
}
.switchprPack__free-gift p span {
  font-size: 17px;
  line-height: 21px;
  margin-left: 8px;
}
.switchprPack__free-cars {
  position: absolute;
  top: 29px;
  right: 34px;
  width: 206px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__free-cars-item {
  height: 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  margin-bottom: 11px;
  padding-left: 23px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__free-cars-item:last-child {
  margin-bottom: 0;
}
.switchprPack__free-cars-item::after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) skewX(-20deg);
          transform: translate(-50%, -50%) skewX(-20deg);
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(90.02deg, rgba(143, 254, 254, 0.5) 1.28%, rgba(1, 201, 253, 0.5) 16.09%, rgba(1, 65, 142, 0.5) 99.98%), #01418e;
  mix-blend-mode: color-dodge;
  border-radius: 0 8px 8px 0;
}
.switchprPack__free-cars-item p {
  position: relative;
  z-index: 2;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background: -webkit-gradient(linear, left top, right top, from(#ffffff), to(#8f6bff));
  background: linear-gradient(90deg, #ffffff 0%, #8f6bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-filter: blur(0.15px);
          filter: blur(0.15px);
}
.switchprPack__free-cars-item.first {
  width: 198px;
}
.switchprPack__free-cars-item.second {
  width: 184px;
}
.switchprPack__free-cars-item.third {
  width: 171px;
}
.switchprPack__free-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__free-image img {
  height: 103%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__free-image2 {
  display: none;
}
.switchprPack__free .button.green {
  height: 40px;
  position: absolute;
  bottom: 15px;
  right: 23px;
  width: 176px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__free:hover .switchprPack__free-image {
  width: 110%;
  height: 105%;
}
.switchprPack__free:hover .switchprPack__free-cars {
  right: 20px;
  width: 241px;
}
.switchprPack__free:hover .switchprPack__free-cars-item.first {
  width: 231px;
}
.switchprPack__free:hover .switchprPack__free-cars-item.second {
  width: 215px;
}
.switchprPack__free:hover .switchprPack__free-cars-item.third {
  width: 200px;
}
.switchprPack__free:hover .switchprPack__free-buy {
  right: 511px;
}
.switchprPack__free:hover .switchprPack__free-gift {
  width: 144px;
}
.switchprPack__cols {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.switchprPack__deposit {
  width: calc(32.75% - 10px);
  position: relative;
  border: 1px solid #9695ce;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 184px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.switchprPack__deposit-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 2;
}
.switchprPack__deposit-image img {
  width: 100%;
}
.switchprPack__deposit-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  top: 44px;
  z-index: 5;
}
.switchprPack__deposit-content h2 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 50.5825px;
  line-height: 1;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1.01165px 2.0233px rgba(51, 94, 224, 0.5);
}
.switchprPack__deposit-content h3 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20.233px;
  line-height: 1;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1.01165px 2.0233px rgba(51, 94, 224, 0.5);
}
.switchprPack__deposit-bg {
  width: 160px;
  height: 93px;
  top: 26px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  position: absolute;
  background: #b168ff;
  mix-blend-mode: overlay;
  border: 10px solid rgba(137, 58, 221, 0.5);
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__deposit-bg2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(#d865ff), color-stop(103.3%, #2b0086));
  background: linear-gradient(360deg, #d865ff 0%, #2b0086 103.3%);
  mix-blend-mode: overlay;
  border-radius: 5;
  z-index: -4;
}
.switchprPack__deposit-bg3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(105.28% 186.26% at 50% 182.97%, rgba(124, 64, 252, 0) 0%, #2b0086 100%);
  border-radius: 5;
  z-index: -5;
}
.switchprPack__deposit-plusbg {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  z-index: -6;
}
.switchprPack__deposit-plusbg img {
  width: 100%;
}
.switchprPack__deposit-bg4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#7c40fc), to(#ae40fc)), #22214a;
  background: linear-gradient(180deg, #7c40fc 0%, #ae40fc 100%), #22214a;
  border-radius: 5px;
  z-index: -7;
}
.switchprPack__deposit:hover .switchprPack__deposit-bg {
  top: 0;
  width: 100%;
  height: 100%;
}
.switchprPack__rolex {
  width: 32.5%;
  position: relative;
  border: 1px solid #9695ce;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 184px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.switchprPack__rolex-image {
  position: absolute;
  top: -13px;
  left: -40px;
  width: 142px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__rolex-image img {
  width: 100%;
}
.switchprPack__rolex-content {
  position: absolute;
  right: 18px;
  top: 20px;
  min-width: 188px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__rolex-content h5 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1.01165px 2.0233px rgba(51, 94, 224, 0.5);
  margin-bottom: 5px;
}
.switchprPack__rolex-content h4 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #82f9ff;
  text-shadow: 0px 1.01165px 2.0233px rgba(51, 94, 224, 0.5);
  margin-bottom: 75px;
}
.switchprPack__rolex-content h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10.1165px;
  line-height: 12px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1.01165px 2.0233px rgba(51, 94, 224, 0.5);
}
.switchprPack__rolex-btn {
  width: 188px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 57px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-shadow: 0px 2.0233px 10.1165px #386fcf;
          box-shadow: 0px 2.0233px 10.1165px #386fcf;
  border-radius: 100px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__rolex-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  width: 188px;
}
.switchprPack__rolex-btn span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.switchprPack__rolex-btn span::before {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 192px;
  height: 54px;
  content: "";
  border-radius: 100px;
  background: -webkit-gradient(linear, left top, left bottom, from(#4ddbff), to(#00aaff));
  background: linear-gradient(180deg, #4ddbff 0%, #00aaff 100%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__rolex-btn span::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 188px;
  height: 50px;
  background: #00ccff;
  content: "";
  border-radius: 100px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__rolex-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#2245bb), to(#00abff)), #22214a;
  background: linear-gradient(180deg, #2245bb 0%, #00abff 100%), #22214a;
}
.switchprPack__rolex-bg2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 143px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(34, 69, 187, 0)), to(#69cdfe));
  background: linear-gradient(180deg, rgba(34, 69, 187, 0) 0%, #69cdfe 100%);
  mix-blend-mode: overlay;
}
.switchprPack__rolex:hover .switchprPack__rolex-image {
  top: -37px;
  left: -50px;
  width: 181px;
}
.switchprPack__rolex:hover .switchprPack__rolex-btn {
  width: 228px;
}
.switchprPack__rolex:hover .switchprPack__rolex-btn span::before {
  width: 232px;
}
.switchprPack__rolex:hover .switchprPack__rolex-btn span::after {
  width: 228px;
}
.switchprPack__rolex:hover .switchprPack__rolex-content {
  top: 14px;
}
.switchprPack__iphone {
  width: calc(32.75% - 10px);
  position: relative;
  border: 1px solid #9695ce;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 184px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.switchprPack__iphone-image {
  position: absolute;
  top: -7px;
  left: -66px;
  width: 161px;
  height: 276px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 2;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__iphone-image img {
  width: 100%;
}
.switchprPack__iphone-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#02727a), to(#00ac79)), #22214a;
  background: linear-gradient(180deg, #02727a 0%, #00ac79 100%), #22214a;
}
.switchprPack__iphone-bg2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 83px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(19, 218, 112, 0)), to(#13da70));
  background: linear-gradient(180deg, rgba(19, 218, 112, 0) 0%, #13da70 100%);
  mix-blend-mode: overlay;
}
.switchprPack__iphone-bg3 {
  position: absolute;
  top: 35px;
  left: -60px;
  width: 393px;
  height: 176px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(19, 218, 112, 0)), to(#13da70));
  background: linear-gradient(180deg, rgba(19, 218, 112, 0) 0%, #13da70 100%);
  mix-blend-mode: overlay;
  -webkit-transform: rotate(39.42deg);
          transform: rotate(39.42deg);
}
.switchprPack__iphone-content {
  position: absolute;
  z-index: 2;
  right: 18px;
  top: 20px;
  min-width: 188px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.switchprPack__iphone-content h5 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1.01165px 2.0233px rgba(51, 94, 224, 0.5);
  margin-bottom: 5px;
}
.switchprPack__iphone-content h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10.1165px;
  line-height: 12px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1.01165px 2.0233px rgba(51, 94, 224, 0.5);
}
.switchprPack__iphone-content-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 13px;
  height: 16px;
  margin-bottom: 80px;
}
.switchprPack__iphone-content-icon svg {
  width: 100%;
  height: 100%;
  color: #00f261;
}
.switchprPack__iphone-btn {
  width: 188px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 57px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-shadow: 0px 2.02px 10.12px 0px #0a878f;
          box-shadow: 0px 2.02px 10.12px 0px #0a878f;
  border-radius: 100px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__iphone-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  width: 188px;
}
.switchprPack__iphone-btn span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.switchprPack__iphone-btn span::before {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 192px;
  height: 54px;
  content: "";
  border-radius: 100px;
  background: -webkit-gradient(linear, left top, left bottom, from(#6cf542), to(#00c271));
  background: linear-gradient(180deg, #6cf542 0%, #00c271 100%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__iphone-btn span::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 188px;
  height: 50px;
  background: #19db66;
  content: "";
  border-radius: 100px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprPack__iphone:hover .switchprPack__iphone-image {
  top: -36px;
  left: -73px;
  width: 182px;
  height: 319px;
}
.switchprPack__iphone:hover .switchprPack__iphone-btn {
  width: 228px;
}
.switchprPack__iphone:hover .switchprPack__iphone-btn span::before {
  width: 232px;
}
.switchprPack__iphone:hover .switchprPack__iphone-btn span::after {
  width: 228px;
}
.switchprPack.active {
  display: block;
}
.switchprRoob {
  padding: 0 16px 16px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: none;
  -webkit-animation: fadeIn 0.3s linear;
          animation: fadeIn 0.3s linear;
}
.switchprRoob__title {
  display: none;
}
.switchprRoob__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}
.switchprRoob__reward {
  position: relative;
  padding: 18px 0 0 52px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  width: 65.5%;
  height: 182px;
}
.switchprRoob__reward-icon {
  width: 21px;
  height: 21px;
  top: 18px;
  left: 18px;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.switchprRoob__reward-icon svg {
  width: 100%;
  height: 100%;
  color: #ffc026;
}
.switchprRoob__reward-content {
  max-width: 250px;
}
.switchprRoob__reward-content h2 {
  line-height: 28px;
  color: #fff;
  margin-bottom: 11px;
}
.switchprRoob__reward-content h2 span {
  font-weight: 600;
}
.switchprRoob__reward-content p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #e5bdee;
}
.switchprRoob__reward-rank {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__reward-rank img {
  width: 100%;
}
.switchprRoob__reward-rank.first {
  top: 30px;
  right: 85px;
  width: 74px;
  -webkit-transform: rotate(5deg);
          transform: rotate(5deg);
}
.switchprRoob__reward-rank.second {
  top: 33px;
  right: 89px;
  width: 68px;
  -webkit-transform: rotate(6.74deg);
          transform: rotate(6.74deg);
  opacity: 0;
}
.switchprRoob__reward-rank.third {
  top: 44px;
  right: 99px;
  width: 46px;
  -webkit-transform: rotate(5.56deg);
          transform: rotate(5.56deg);
  opacity: 0;
}
.switchprRoob__reward-rank.fourth {
  top: 58px;
  right: 111px;
  width: 21px;
  -webkit-transform: rotate(4deg);
          transform: rotate(4deg);
  opacity: 0;
}
.switchprRoob__reward-btn {
  position: absolute;
  bottom: 17px;
  right: 20px;
  height: 37px;
  width: 204px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  background: none;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__reward-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #fff;
  text-shadow: 0px 1px 2px rgba(23, 35, 140, 0.3);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__reward-btn p b {
  font-weight: 900;
}
.switchprRoob__reward-btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__reward-btn span.default::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(#ffc026), to(#a7541d));
  background: linear-gradient(180deg, #ffc026 0%, #a7541d 100%);
  border-radius: 5px;
}
.switchprRoob__reward-btn span.default::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  content: "";
  background: radial-gradient(83.1% 436.25% at 50% 50%, #432835 0%, #1d1b38 100%);
  border-radius: 5px;
}
.switchprRoob__reward-btn span.hov {
  opacity: 0;
}
.switchprRoob__reward-btn span.hov::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(#ffe387), to(#f7b625));
  background: linear-gradient(180deg, #ffe387 0%, #f7b625 100%);
  border-radius: 5px;
}
.switchprRoob__reward-btn span.hov::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(#ffc026), to(#a7541d)), radial-gradient(83.1% 436.25% at 50% 50%, #432835 0%, #1d1b38 100%);
  background: linear-gradient(180deg, #ffc026 0%, #a7541d 100%), radial-gradient(83.1% 436.25% at 50% 50%, #432835 0%, #1d1b38 100%);
  border-radius: 5px;
}
.switchprRoob__reward-btn span.active {
  opacity: 0;
}
.switchprRoob__reward-btn span.active::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(#ffe387), to(#f7b625));
  background: linear-gradient(180deg, #ffe387 0%, #f7b625 100%);
  border-radius: 5px;
}
.switchprRoob__reward-btn span.active::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  content: "";
  border-radius: 5px;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffc026), to(#a7541d)), radial-gradient(83.1% 436.25% at 50% 50%, #432835 0%, #1d1b38 100%);
  background: linear-gradient(180deg, #ffc026 0%, #a7541d 100%), radial-gradient(83.1% 436.25% at 50% 50%, #432835 0%, #1d1b38 100%);
}
.switchprRoob__reward-btn:hover {
  -webkit-box-shadow: 0px 2px 6px rgba(126, 37, 10, 0.5);
          box-shadow: 0px 2px 6px rgba(126, 37, 10, 0.5);
}
.switchprRoob__reward-btn:hover span.hov {
  opacity: 1;
}
.switchprRoob__reward-btn:hover p {
  font-size: 18px;
  line-height: 22px;
}
.switchprRoob__reward-btn:focus span.hov {
  opacity: 0;
}
.switchprRoob__reward-btn:focus span.active {
  opacity: 1;
}
.switchprRoob__reward-btn:focus p {
  font-size: 17px;
  line-height: 21px;
}
.switchprRoob__reward-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(155, 65, 19, 0.2)), color-stop(90%, #191839)), #22214a;
  background: linear-gradient(90deg, rgba(155, 65, 19, 0.2) 0%, #191839 90%), #22214a;
  border: 1px solid #9695ce;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.switchprRoob__reward-bg2 {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, color-stop(-70.03%, rgba(155, 65, 19, 0.2)), color-stop(83%, #9b4113)), #22214a;
  background: linear-gradient(90deg, rgba(155, 65, 19, 0.2) -70.03%, #9b4113 83%), #22214a;
  border: 1px solid #a9551d;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__reward:hover .switchprRoob__reward-bg2 {
  opacity: 1;
}
.switchprRoob__reward:hover .switchprRoob__reward-btn {
  bottom: 27px;
}
.switchprRoob__reward:hover .switchprRoob__reward-rank.first {
  top: 15px;
  right: 76px;
  width: 89px;
}
.switchprRoob__reward:hover .switchprRoob__reward-rank.second {
  -webkit-transform: rotate(-5.44deg);
          transform: rotate(-5.44deg);
  width: 68px;
  top: 39px;
  right: 161px;
  opacity: 0.5;
}
.switchprRoob__reward:hover .switchprRoob__reward-rank.third {
  -webkit-transform: rotate(-20.37deg);
          transform: rotate(-20.37deg);
  width: 46px;
  top: 64px;
  right: 227px;
  opacity: 0.5;
}
.switchprRoob__reward:hover .switchprRoob__reward-rank.fourth {
  -webkit-transform: rotate(-38.26deg);
          transform: rotate(-38.26deg);
  width: 22px;
  top: 96px;
  right: 272px;
  opacity: 0.5;
}
.switchprRoob__register {
  width: 32.5%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  padding: 18px 0 0 38px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.switchprRoob__register-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 14px;
  left: 16px;
  z-index: 2;
}
.switchprRoob__register-icon p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: #ffc026;
}
.switchprRoob__register-content {
  position: relative;
  z-index: 2;
}
.switchprRoob__register-content h2 {
  margin-bottom: 10px;
}
.switchprRoob__register-content h2 span {
  font-weight: 600;
}
.switchprRoob__register-content p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #eec8bd;
  max-width: 252px;
}
.switchprRoob__register-image {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  height: 105px;
  border-radius: 5px;
  -webkit-filter: blur(2px);
          filter: blur(2px);
  opacity: 0.2;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__register-image img {
  height: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__register-image2 {
  position: absolute;
  z-index: 2;
  top: 87px;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  border-radius: 5px;
  opacity: 0.2;
  mix-blend-mode: plus-lighter;
}
.switchprRoob__register-image2 img {
  width: 100%;
}
.switchprRoob__register-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(155, 65, 19, 0.2)), color-stop(90%, #191839)), #22214a;
  background: linear-gradient(90deg, rgba(155, 65, 19, 0.2) 0%, #191839 90%), #22214a;
  border: 1px solid #9695ce;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__register-bg2 {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, color-stop(-70.03%, rgba(155, 65, 19, 0.2)), color-stop(83%, #9b4113));
  background: linear-gradient(90deg, rgba(155, 65, 19, 0.2) -70.03%, #9b4113 83%);
  border: 1px solid #a9551d;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__register-btn {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 2;
  position: absolute;
  bottom: 13px;
  left: 50%;
  cursor: pointer;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 100%;
  max-width: 276px;
  height: 37px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: none;
}
.switchprRoob__register-btn h6 {
  position: relative;
  z-index: 2;
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 17px;
  line-height: 21px;
  color: #fff;
  text-shadow: 0px 1px 2px rgba(23, 35, 140, 0.3);
  margin-right: 10px;
}
.switchprRoob__register-btn h6 b {
  font-weight: 400;
}
.switchprRoob__register-btn-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  z-index: 2;
}
.switchprRoob__register-btn-inner img {
  width: 83px;
  height: 11px;
  margin-right: 6px;
}
.switchprRoob__register-btn-inner svg {
  width: 23px;
  height: 23px;
  color: #ffc026;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__register-btn-inner svg path {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__register-btn-inner svg path.otherpath {
  color: #ffc026;
}
.switchprRoob__register-btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__register-btn span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 5px;
}
.switchprRoob__register-btn span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.switchprRoob__register-btn span.default::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffc026), to(#a7541d));
  background: linear-gradient(180deg, #ffc026 0%, #a7541d 100%);
}
.switchprRoob__register-btn span.default::after {
  background: radial-gradient(83.1% 436.25% at 50% 50%, #432835 0%, #1d1b38 100%);
}
.switchprRoob__register-btn span.hov {
  opacity: 0;
}
.switchprRoob__register-btn span.hov::before {
  -webkit-filter: blur(1px);
          filter: blur(1px);
  background: -webkit-gradient(linear, left top, left bottom, from(#ffe387), to(#f7b625));
  background: linear-gradient(180deg, #ffe387 0%, #f7b625 100%);
}
.switchprRoob__register-btn span.hov::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffc026), to(#a7541d));
  background: linear-gradient(180deg, #ffc026 0%, #a7541d 100%);
}
.switchprRoob__register-btn span.active {
  opacity: 0;
}
.switchprRoob__register-btn span.active::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffe387), to(#f7b625));
  background: linear-gradient(180deg, #ffe387 0%, #f7b625 100%);
}
.switchprRoob__register-btn span.active::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffc026), to(#a7541d));
  background: linear-gradient(180deg, #ffc026 0%, #a7541d 100%);
}
.switchprRoob__register-btn:hover .switchprRoob__register-btn-inner img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}
.switchprRoob__register-btn:hover .switchprRoob__register-btn-inner svg {
  color: #fff;
}
.switchprRoob__register-btn:hover .switchprRoob__register-btn-inner svg path.otherpath {
  color: #fff;
}
.switchprRoob__register-btn:hover span.hov {
  opacity: 1;
}
.switchprRoob__register-btn:focus .switchprRoob__register-btn-inner img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}
.switchprRoob__register-btn:focus .switchprRoob__register-btn-inner svg {
  color: #fff;
}
.switchprRoob__register-btn:focus .switchprRoob__register-btn-inner svg path.otherpath {
  color: #fff;
}
.switchprRoob__register-btn:focus span.hov {
  opacity: 0;
}
.switchprRoob__register-btn:focus span.active {
  opacity: 1;
}
.switchprRoob__register-btn:focus:hover span.hov {
  opacity: 1;
}
.switchprRoob__register-btn:focus:hover span.active {
  opacity: 0;
}
.switchprRoob__register:hover .switchprRoob__register-bg {
  opacity: 0;
}
.switchprRoob__register:hover .switchprRoob__register-bg2 {
  opacity: 1;
  z-index: 1;
}
.switchprRoob__register:hover .switchprRoob__register-image {
  height: 100%;
}
.switchprRoob__register:hover .switchprRoob__register-btn {
  bottom: 25px;
}
.switchprRoob__jack {
  width: 100%;
  position: relative;
  padding: 18px 0 0 53px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 218px;
  border-radius: 5px;
  overflow: hidden;
}
.switchprRoob__jack-icon {
  position: relative;
  z-index: 2;
  position: absolute;
  top: 16px;
  left: 18px;
  width: 22px;
  height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.switchprRoob__jack-icon svg {
  width: 100%;
  height: 100%;
  color: #000;
}
.switchprRoob__jack-content {
  max-width: 380px;
  position: relative;
  z-index: 1;
}
.switchprRoob__jack-content h2 {
  color: #fff;
  margin-bottom: 11px;
}
.switchprRoob__jack-content h2 span {
  font-weight: 700;
}
.switchprRoob__jack-content-text {
  width: 100%;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  background: -webkit-gradient(linear, left top, right top, color-stop(61.6%, #c66aff), to(#883ced));
  background: linear-gradient(90deg, #c66aff 61.6%, #883ced 100%);
  padding: 0 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.switchprRoob__jack-content-text p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #000;
}
.switchprRoob__jack-content-text p span {
  font-weight: 800;
}
.switchprRoob__jack-image {
  position: absolute;
  bottom: 0;
  left: 192px;
  width: 560px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 3;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__jack-image img {
  width: 100%;
}
.switchprRoob__jack-coins {
  position: absolute;
  bottom: 0;
  right: 109px;
  width: 1098px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 1;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__jack-coins img {
  width: 100%;
}
.switchprRoob__jack-tickets {
  position: absolute;
  top: -140px;
  left: 171px;
  width: 704px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__jack-tickets img {
  width: 100%;
}
.switchprRoob__jack-info {
  position: absolute;
  top: 18px;
  right: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  z-index: 3;
}
.switchprRoob__jack-timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 9px;
}
.switchprRoob__jack-timer p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  margin-right: 8px;
}
.switchprRoob__jack-timer-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.switchprRoob__jack-timer-item {
  width: 46px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  background: #fff;
  margin-right: 3px;
}
.switchprRoob__jack-timer-item:last-child {
  margin-right: 0;
}
.switchprRoob__jack-timer-item h6 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #100f2d;
}
.switchprRoob__jack-timer-item h6 span {
  font-weight: 800;
}
.switchprRoob__jack-slot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  position: relative;
}
.switchprRoob__jack-slot-name {
  background: #c66aff;
  border-radius: 5px 0 0 5px;
  width: 193px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__jack-slot-name p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: #000;
  margin-right: 14px;
}
.switchprRoob__jack-slot-name p span {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  color: #fff;
}
.switchprRoob__jack-slot-name h6 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 15px;
  line-height: 15px;
  color: #000;
}
.switchprRoob__jack-slot-ticket {
  width: 124px;
  position: relative;
  border-radius: 0 5px 5px 0;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-right: 35px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.switchprRoob__jack-slot-ticket img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 11px;
  width: 20px;
  height: 20px;
}
.switchprRoob__jack-slot-ticket p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #fff;
}
.switchprRoob__jack-slot-ticket-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}
.switchprRoob__jack-slot-ticket-bg::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, color-stop(60.89%, #7937e2), to(#5b1dba));
  background: linear-gradient(90deg, #7937e2 60.89%, #5b1dba 100%);
  content: "";
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__jack-slot-ticket-bg::after {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, color-stop(60.89%, #8e35f4), to(#791de2));
  background: linear-gradient(90deg, #8e35f4 60.89%, #791de2 100%);
  content: "";
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__jack-slot-overlay {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: #0e388d;
  mix-blend-mode: overlay;
  -webkit-filter: blur(2px);
          filter: blur(2px);
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: -1;
}
.switchprRoob__jack-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#813cee), color-stop(49.5%, #6f30d3), to(#400499));
  background: linear-gradient(90deg, #813cee 0%, #6f30d3 49.5%, #400499 100%);
  border-radius: 5px;
}
.switchprRoob__jack-bg2 {
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#f8bd28), color-stop(49.5%, #ec8423), to(#c94c1a));
  background: linear-gradient(90deg, #f8bd28 0%, #ec8423 49.5%, #c94c1a 100%);
  border: 1px solid #f6b227;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.switchprRoob__jack .button.green {
  z-index: 3;
  position: absolute;
  bottom: 14px;
  right: 18px;
  width: 176px;
  height: 40px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.switchprRoob__jack:hover .button.green {
  bottom: 29px;
}
.switchprRoob__jack:hover .switchprRoob__jack-tickets {
  top: -21px;
}
.switchprRoob__jack:hover .switchprRoob__jack-coins {
  right: 101px;
}
.switchprRoob__jack:hover .switchprRoob__jack-image {
  width: 623px;
  bottom: -13px;
  left: 184px;
}
.switchprRoob__jack:hover .switchprRoob__jack-slot-overlay {
  background: #fff;
}
.switchprRoob__jack:hover .switchprRoob__jack-slot-name {
  background: #ee99ff;
}
.switchprRoob__jack:hover .switchprRoob__jack-slot-ticket-bg::after {
  opacity: 1;
}
.switchprRoob.active {
  display: block;
}
.switchpr:hover::after {
  opacity: 0;
  z-index: -1;
}

.ticketj__inner {
  padding: 190px 0 130px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ticketj__inner h2 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  color: #fff;
  margin-bottom: 30px;
}
.ticketj__table-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 28px 0 106px;
  margin-bottom: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ticketj__table-head-item {
  width: 33.3334%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ticketj__table-head-item:last-child {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.ticketj__table-head-item:last-child p {
  width: 110px;
}
.ticketj__table-head-item p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #fff;
}
.ticketj__table-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #9faac1;
  margin-bottom: 26px;
  border-radius: 5px;
  overflow: hidden;
}
.ticketj__table-row:last-child {
  margin-bottom: 0;
}
.ticketj__table-row:first-child {
  background: -webkit-gradient(linear, left top, right top, from(#aa5fff), to(#400599));
  background: linear-gradient(90deg, #aa5fff 0%, #400599 100%);
  -webkit-box-shadow: 0px 2px 10px #100f2d;
          box-shadow: 0px 2px 10px #100f2d;
  height: 90px;
}
.ticketj__table-row:first-child .ticketj__table-number {
  height: 90px;
}
.ticketj__table-row:first-child .ticketj__table-number p {
  color: #fff;
  text-shadow: 0px 2px 4px #7834cf;
}
.ticketj__table-row:first-child .ticketj__table-item p {
  font-size: 17px;
  color: #fff;
}
.ticketj__table-row:first-child .ticketj__table-item-tickets h6 {
  font-weight: 900;
  color: #fff;
}
.ticketj__table-row:first-child .ticketj__table-item-tickets img {
  margin-left: 2px;
  margin-right: 10px;
}
.ticketj__table-row:nth-child(2) {
  background: -webkit-gradient(linear, left top, right top, from(#307bd3), to(#400599)), #307bd3;
  background: linear-gradient(90deg, #307bd3 0%, #400599 100%), #307bd3;
}
.ticketj__table-row:nth-child(2) .ticketj__table-number {
  height: 68px;
}
.ticketj__table-row:nth-child(2) .ticketj__table-number p {
  color: #fff;
  text-shadow: 0px 2px 4px #364abb;
}
.ticketj__table-row:nth-child(2) .ticketj__table-item p {
  font-size: 17px;
  color: #fff;
}
.ticketj__table-row:nth-child(2) .ticketj__table-item-tickets img {
  margin-left: 2px;
  margin-right: 10px;
}
.ticketj__table-row:nth-child(2) .ticketj__table-item-tickets h6 {
  font-weight: 900;
  color: #fff;
}
.ticketj__table-row:nth-child(3) {
  background: -webkit-gradient(linear, left top, right top, from(#6e9cd4), to(#400599)), #6e9cd4;
  background: linear-gradient(90deg, #6e9cd4 0%, #400599 100%), #6e9cd4;
}
.ticketj__table-row:nth-child(3) .ticketj__table-number {
  height: 68px;
}
.ticketj__table-row:nth-child(3) .ticketj__table-number p {
  color: #fff;
  text-shadow: 0px 2px 4px #5b5dbc;
}
.ticketj__table-row:nth-child(3) .ticketj__table-item p {
  font-size: 17px;
  color: #fff;
}
.ticketj__table-row:nth-child(3) .ticketj__table-item-tickets img {
  margin-left: 2px;
  margin-right: 10px;
}
.ticketj__table-row:nth-child(3) .ticketj__table-item-tickets h6 {
  font-weight: 900;
  color: #fff;
}
.ticketj__table-row:nth-child(4) {
  background: -webkit-gradient(linear, left top, right top, from(rgba(209, 225, 244, 0.2)), to(rgba(64, 5, 153, 0.2))), #d1e1f4;
  background: linear-gradient(90deg, rgba(209, 225, 244, 0.2) 0%, rgba(64, 5, 153, 0.2) 100%), #d1e1f4;
}
.ticketj__table-row:nth-child(4) .ticketj__table-number {
  height: 68px;
}
.ticketj__table-row:nth-child(4) .ticketj__table-item-tickets img {
  margin-left: 2px;
  margin-right: 10px;
}
.ticketj__table-row:nth-child(4) .ticketj__table-item-tickets h6 {
  font-weight: 900;
}
.ticketj__table-row:nth-child(4) .ticketj__table-item p {
  font-size: 17px;
}
.ticketj__table-row:nth-child(5) {
  background: -webkit-gradient(linear, left top, right top, from(rgba(209, 225, 244, 0.2)), to(rgba(64, 5, 153, 0.2))), #d1e1f4;
  background: linear-gradient(90deg, rgba(209, 225, 244, 0.2) 0%, rgba(64, 5, 153, 0.2) 100%), #d1e1f4;
}
.ticketj__table-row:nth-child(5) .ticketj__table-number {
  height: 68px;
}
.ticketj__table-row:nth-child(5) .ticketj__table-item-tickets img {
  margin-left: 2px;
  margin-right: 10px;
}
.ticketj__table-row:nth-child(5) .ticketj__table-item-tickets h6 {
  font-weight: 900;
}
.ticketj__table-row:nth-child(5) .ticketj__table-item p {
  font-size: 17px;
}
.ticketj__table-row.shards {
  height: 48px;
  margin-bottom: 10px;
}
.ticketj__table-number {
  width: 68px;
  height: 52px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.ticketj__table-number p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #242352;
}
.ticketj__table-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: calc(100% - 68px);
  padding: 0 28px 0 38px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ticketj__table-items.shards {
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -webkit-box-align: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: center;
  padding: 0 18px 0 24px;
}
.ticketj__table-item {
  width: 33.3334%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ticketj__table-item:last-child {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.ticketj__table-item p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #0c0722;
}
.ticketj__table-item-title {
  display: none;
}
.ticketj__table-item-tickets {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ticketj__table-item-tickets h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: 0.06em;
  color: #0c0722;
}
.ticketj__table-item-tickets img {
  width: 29px;
  height: 29px;
  margin-left: 8px;
}
.ticketj__table-item-tickets img.shards {
  width: 19px;
  height: 32px;
  -webkit-transform: rotate(16.92deg);
          transform: rotate(16.92deg);
  margin-right: 10px;
}
.ticketj__table-item-prize {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  width: 157px;
  height: 90px;
}
.ticketj__table-item-prize-car {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 298px;
  height: 86px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.ticketj__table-item-prize-car img {
  width: 100%;
}
.ticketj__table-item-rolex {
  width: 157px;
  height: 68px;
  position: relative;
}
.ticketj__table-item-rolex-image {
  width: 131px;
  position: absolute;
  bottom: 0;
  left: calc(50% + 14px);
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.ticketj__table-item-rolex-image img {
  width: 100%;
}
.ticketj__table-item-eth {
  width: 157px;
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-right: 9px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ticketj__table-item-eth-box {
  width: 108px;
  height: 29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-left: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: -webkit-gradient(linear, left bottom, left top, from(#833eda), to(#833eda)), #6e9cd4;
  background: linear-gradient(0deg, #833eda, #833eda), #6e9cd4;
  border-radius: 100px;
  position: relative;
}
.ticketj__table-item-eth-box h6 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #fff;
}
.ticketj__table-item-eth-box img {
  width: 35px;
  position: absolute;
  right: -2px;
  top: 50%;
  -webkit-transform: rotate(20.3deg) translateY(-50%);
          transform: rotate(20.3deg) translateY(-50%);
}
.ticketj__table-item-shard {
  width: 157px;
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-right: 9px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ticketj__table-item-shard-box {
  width: 108px;
  height: 29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-left: 28px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #d5d6e6;
  border-radius: 100px;
  position: relative;
}
.ticketj__table-item-shard-box h6 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #0c0722;
}
.ticketj__table-item-shard-box img {
  width: 33px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: rotate(26.01deg) translateY(-50%);
          transform: rotate(26.01deg) translateY(-50%);
}
.ticketj__table-item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ticketj__table-item-shards img {
  width: 29px;
  height: 29px;
  margin-left: 2px;
}
.ticketj__table-item .crownImg {
  width: 30px;
}
.ticketj.pack .ticketj__inner {
  padding: 45px 0 225px 0;
}
.ticketj.pack .ticketj__table-head {
  padding: 0 56px 0 106px;
}
.ticketj.pack .ticketj__table-head-item:last-child p {
  width: unset;
  text-align: right;
}
.ticketj.pack .ticketj__table-item-tickets {
  margin-right: 0;
}
.ticketj.pack .ticketj__table-items {
  padding: 0 56px 0 38px;
}
.ticketj.pack .ticketj__table-row:first-child .ticketj__table-number {
  background: #a030d3;
}
.ticketj.pack .ticketj__table-row:nth-child(2) .ticketj__table-number {
  background: #b564da;
}
.ticketj.pack .ticketj__table-row:nth-child(3) .ticketj__table-number {
  background: #c5a6d2;
}

.ticketh__inner {
  padding: 190px 0 130px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ticketh__inner h2 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  color: #fff;
  margin-bottom: 30px;
}
.ticketh__table-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 18px 0 106px;
  margin-bottom: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ticketh__table-head-item {
  width: 33.3334%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ticketh__table-head-item:last-child {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.ticketh__table-head-item:last-child p {
  width: 110px;
}
.ticketh__table-head-item p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #fff;
}
.ticketh__table-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #d6dbe1;
  margin-bottom: 26px;
  border-radius: 5px;
  overflow: hidden;
}
.ticketh__table-row:last-child {
  margin-bottom: 0;
}
.ticketh__table-row:first-child {
  background: #ffffff;
}
.ticketh__table-row:first-child .ticketh__table-number {
  width: 68px;
  height: 68px;
  background: #307bd3;
}
.ticketh__table-row:first-child .ticketh__table-number p {
  color: #fff;
}
.ticketh__table-row:first-child .ticketh__table-item p {
  font-size: 17px;
}
.ticketh__table-row:first-child .ticketh__table-item-tickets h6 {
  font-weight: 900;
}
.ticketh__table-row:nth-child(2) .ticketh__table-number {
  background: #759cc9;
}
.ticketh__table-row:nth-child(3) .ticketh__table-number {
  background: #9db5d1;
}
.ticketh__table-row.shards {
  height: 48px;
  margin-bottom: 10px;
}
.ticketh__table-number {
  width: 68px;
  height: 52px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.ticketh__table-number p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #242352;
}
.ticketh__table-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: calc(100% - 68px);
  padding: 0 18px 0 38px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ticketh__table-items.shards {
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -webkit-box-align: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: center;
  padding: 0 18px 0 24px;
}
.ticketh__table-item {
  width: 33.3334%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ticketh__table-item:last-child {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.ticketh__table-item p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #0c0722;
}
.ticketh__table-item-title {
  display: none;
}
.ticketh__table-item-tickets {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 10px;
}
.ticketh__table-item-tickets h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: 0.06em;
  color: #0c0722;
}
.ticketh__table-item-tickets img {
  width: 29px;
  height: 29px;
  margin-left: 2px;
}
.ticketh__table-item-tickets img.shards {
  width: 19px;
  height: 32px;
  -webkit-transform: rotate(16.92deg);
          transform: rotate(16.92deg);
  margin-right: 10px;
}
.ticketh__table-item-prize {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ticketh__table-item-prize h6 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.06em;
  color: #0c0722;
}
.ticketh__table-item-prize-car {
  width: 109px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: 10px;
}
.ticketh__table-item-prize-car img {
  width: 100px;
}
.ticketh__table-item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ticketh__table-item-shards img {
  width: 29px;
  height: 29px;
  margin-left: 2px;
}
.ticketh__table-item .crownImg {
  width: 30px;
}
.ticketh.pack .ticketh__inner {
  padding: 45px 0 225px 0;
}
.ticketh.pack .ticketh__table-head {
  padding: 0 56px 0 106px;
}
.ticketh.pack .ticketh__table-head-item:last-child p {
  width: unset;
  text-align: right;
}
.ticketh.pack .ticketh__table-item-tickets {
  margin-right: 0;
}
.ticketh.pack .ticketh__table-items {
  padding: 0 56px 0 38px;
}
.ticketh.pack .ticketh__table-row:first-child .ticketh__table-number {
  background: #a030d3;
}
.ticketh.pack .ticketh__table-row:nth-child(2) .ticketh__table-number {
  background: #b564da;
}
.ticketh.pack .ticketh__table-row:nth-child(3) .ticketh__table-number {
  background: #c5a6d2;
}

.cta {
  background: rgba(34, 33, 74, 0.5);
}
.cta__inner {
  padding: 30px 0;
}
.ctaBox {
  overflow: hidden;
  position: relative;
  min-height: 180px;
  border-radius: 5px;
  padding: 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ctaBox__content {
  position: relative;
  margin-right: 80px;
  z-index: 2;
}
.ctaBox__content h1 {
  margin-bottom: 20px;
}
.ctaBox__content h1 span {
  font-weight: 700;
}
.ctaBox__content p {
  margin-bottom: 12px;
}
.ctaBox__content .button.register {
  width: 100%;
  max-width: 181px;
  padding: 10px 0;
}
.ctaBox__content .button.register p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1px 2px rgba(23, 35, 140, 0.3);
}
.ctaBox__items {
  width: 100%;
  max-width: 351px;
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.ctaBox__jack {
  width: calc(33.3334% - 8px);
  position: relative;
  min-height: 96px;
  border-radius: 5px;
  border: 0.5px solid #3d8eed;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.ctaBox__jack-inner {
  overflow: hidden;
}
.ctaBox__jack-car {
  position: absolute;
  top: calc(50% - 7px);
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  left: 50%;
  width: 91px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ctaBox__jack-car img {
  width: 100%;
}
.ctaBox__jack-ticket {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ctaBox__jack-ticket img {
  width: 100%;
}
.ctaBox__jack-ticket.one {
  width: 11px;
  top: 5px;
  right: 24px;
}
.ctaBox__jack-ticket.two {
  top: 25px;
  width: 9px;
  right: 4px;
}
.ctaBox__jack-ticket.three {
  width: 7px;
  left: 5px;
  top: 20px;
}
.ctaBox__jack-content {
  position: absolute;
  bottom: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 0 0 5px 5px;
  background: -webkit-gradient(linear, left top, left bottom, from(#30ac72), to(#60c946)), #31ad73;
  background: linear-gradient(180deg, #30ac72 0%, #60c946 100%), #31ad73;
}
.ctaBox__jack-content p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1px 2px #0a7f66;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ctaBox__jack-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}
.ctaBox__jack-bg::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(#ffc042), to(#e39536));
  background: linear-gradient(180deg, #ffc042 0%, #e39536 100%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ctaBox__jack-bg::after {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(#ffd342), to(#e3d236));
  background: linear-gradient(180deg, #ffd342 0%, #e3d236 100%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ctaBox__jack-coin {
  position: absolute;
  bottom: 21.5px;
  left: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: -1;
}
.ctaBox__jack-coin img {
  width: 100%;
}
.ctaBox__jack:hover .ctaBox__jack-ticket.one {
  top: 7px;
  right: 33px;
}
.ctaBox__jack:hover .ctaBox__jack-ticket.two {
  -webkit-transform: rotate(-54deg);
          transform: rotate(-54deg);
  top: 28px;
  right: 1px;
}
.ctaBox__jack:hover .ctaBox__jack-ticket.three {
  top: 25px;
  left: 0;
  -webkit-transform: rotate(78deg);
          transform: rotate(78deg);
}
.ctaBox__jack:hover .ctaBox__jack-bg::before {
  opacity: 0;
}
.ctaBox__jack:hover .ctaBox__jack-bg::after {
  opacity: 1;
}
.ctaBox__jack:hover .ctaBox__jack-car {
  width: 97px;
}
.ctaBox__jack:hover .ctaBox__jack-content {
  height: 28px;
}
.ctaBox__jack:hover .ctaBox__jack-content p {
  font-size: 9px;
  line-height: 11px;
}
.ctaBox__jack:hover .ctaBox__jack-content p span {
  color: #ffe572;
}
.ctaBox__bonus {
  width: calc(33.3334% - 8px);
  position: relative;
  min-height: 96px;
  border-radius: 5px;
  border: 0.5px solid #3d8eed;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.ctaBox__bonus-inner {
  overflow: hidden;
}
.ctaBox__bonus-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  overflow: hidden;
}
.ctaBox__bonus-bg img {
  width: 100%;
  opacity: 0.1;
  mix-blend-mode: hard-light;
}
.ctaBox__bonus-bg2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: #001270;
}
.ctaBox__bonus-cube {
  position: absolute;
  top: 2px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ctaBox__bonus-cube img {
  width: 100%;
}
.ctaBox__bonus-element {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  z-index: 1;
  width: 83px;
  height: 80px;
  background: radial-gradient(50% 50% at 50% 50%, #0c68c8 0%, rgba(26, 32, 63, 0) 100%);
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ctaBox__bonus-element2 {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  z-index: 1;
  width: 100%;
  height: 84px;
  background: radial-gradient(66.46% 66.46% at 50% 50%, #58aaff 0%, rgba(26, 32, 63, 0) 100%);
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ctaBox__bonus-star {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 2;
}
.ctaBox__bonus-star img {
  width: 100%;
}
.ctaBox__bonus-star.one {
  width: 8px;
  top: 15px;
  left: 21px;
}
.ctaBox__bonus-star.two {
  width: 15px;
  top: 32px;
  right: 4px;
}
.ctaBox__bonus-star.three {
  width: 8px;
  right: 26px;
  top: 28px;
  opacity: 0;
}
.ctaBox__bonus-content {
  position: absolute;
  bottom: -1px;
  z-index: 3;
  left: -1px;
  width: calc(100% + 2px);
  height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 0 0 5px 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  background: -webkit-gradient(linear, left top, left bottom, from(#3e91f2), to(#307bd3));
  background: linear-gradient(180deg, #3e91f2 0%, #307bd3 100%);
}
.ctaBox__bonus-content p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1px 2px #0a7f66;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ctaBox__bonus:hover .ctaBox__bonus-cube {
  -webkit-transform: translateX(-50%) rotate(6.48deg);
          transform: translateX(-50%) rotate(6.48deg);
}
.ctaBox__bonus:hover .ctaBox__bonus-content {
  height: 28px;
}
.ctaBox__bonus:hover .ctaBox__bonus-content p {
  font-size: 9px;
  line-height: 11px;
}
.ctaBox__bonus:hover .ctaBox__bonus-element {
  opacity: 0;
}
.ctaBox__bonus:hover .ctaBox__bonus-element2 {
  opacity: 1;
}
.ctaBox__bonus:hover .ctaBox__bonus-star.one {
  width: 12px;
  top: 10px;
  left: 8px;
}
.ctaBox__bonus:hover .ctaBox__bonus-star.two {
  width: 20px;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  top: 45px;
  right: 5px;
}
.ctaBox__bonus:hover .ctaBox__bonus-star.three {
  opacity: 1;
  top: 12px;
  right: 21px;
}
.ctaBox__vip {
  width: calc(33.3334% - 8px);
  position: relative;
  min-height: 96px;
  border-radius: 5px;
  border: 0.5px solid #3d8eed;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.ctaBox__vip-inner {
  overflow: hidden;
}
.ctaBox__vip-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#1a203f), to(#0d1129));
  background: linear-gradient(180deg, #1a203f 0%, #0d1129 100%);
  border-radius: 5px;
  z-index: -1;
}
.ctaBox__vip-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  border-radius: 5px;
  background: -webkit-gradient(linear, left top, left bottom, from(#1a203f), color-stop(23.5%, rgba(20, 25, 53, 0)), color-stop(73.5%, rgba(19, 24, 51, 0)), to(#0d1129));
  background: linear-gradient(180deg, #1a203f 0%, rgba(20, 25, 53, 0) 23.5%, rgba(19, 24, 51, 0) 73.5%, #0d1129 100%);
  z-index: 1;
}
.ctaBox__vip-element {
  position: absolute;
  width: 83px;
  height: 78px;
  left: 10px;
  top: 0;
  z-index: -1;
  background: radial-gradient(50% 50% at 50% 50%, #0c68c8 0%, rgba(26, 32, 63, 0) 100%);
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ctaBox__vip-element.two {
  opacity: 0;
  background: radial-gradient(50% 50% at 50% 50%, #e2811f 0%, rgba(26, 32, 63, 0) 100%);
}
.ctaBox__vip-rank {
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ctaBox__vip-rank-scroll {
  position: absolute;
  left: -18px;
  top: 50%;
  width: 12px;
  height: 12px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.ctaBox__vip-rank-scroll::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  width: 12px;
  height: 12px;
  background: #3eaeff;
  border-radius: 2px;
  z-index: 1;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}
.ctaBox__vip-rank-scroll::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 2px;
  height: 72px;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(0), color-stop(0%, #1a203f), color-stop(50.5%, #3eaeff), to(#111630));
  background: linear-gradient(0, #1a203f 0%, #3eaeff 50.5%, #111630 100%);
  z-index: -1;
}
.ctaBox__vip-rank-scroll p {
  position: relative;
  z-index: 2;
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 7px;
  line-height: 1;
  color: #fff;
  text-shadow: 0px 1px 1px #3e8bff;
}
.ctaBox__vip-rank-image {
  width: 51px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.ctaBox__vip-rank-image img {
  width: 100%;
}
.ctaBox__vip-rank.two {
  -webkit-transform: translateY(-120%) translateX(-50%);
          transform: translateY(-120%) translateX(-50%);
  opacity: 0;
  top: 12px;
}
.ctaBox__vip-rank.two .ctaBox__vip-rank-scroll::after {
  background: #e2811f;
}
.ctaBox__vip-rank.two .ctaBox__vip-rank-scroll::before {
  background: -webkit-gradient(linear, left top, left bottom, from(0), color-stop(0%, #1a203f), color-stop(50.5%, #e25e1f), to(#111630));
  background: linear-gradient(0, #1a203f 0%, #e25e1f 50.5%, #111630 100%);
}
.ctaBox__vip-rank.two .ctaBox__vip-rank-scroll p {
  text-shadow: 0px 1px 1px #e2531f;
}
.ctaBox__vip-rank.two .ctaBox__vip-rank-image {
  width: 49px;
}
.ctaBox__vip-content {
  position: absolute;
  bottom: -1px;
  z-index: 3;
  left: -1px;
  width: calc(100% + 2px);
  height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 0 0 5px 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  background: -webkit-gradient(linear, left top, left bottom, from(#3e91f2), to(#307bd3));
  background: linear-gradient(180deg, #3e91f2 0%, #307bd3 100%);
}
.ctaBox__vip-content p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1px 2px #0a7f66;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ctaBox__vip:hover .ctaBox__vip-content {
  height: 28px;
}
.ctaBox__vip:hover .ctaBox__vip-content p {
  font-size: 9px;
  line-height: 11px;
}
.ctaBox__vip:hover .ctaBox__vip-rank {
  -webkit-transform: translateY(120%) translateX(-50%);
          transform: translateY(120%) translateX(-50%);
  opacity: 0;
}
.ctaBox__vip:hover .ctaBox__vip-rank.two {
  -webkit-transform: translateY(0) translateX(-50%);
          transform: translateY(0) translateX(-50%);
  opacity: 1;
}
.ctaBox__vip:hover .ctaBox__vip-element {
  opacity: 0;
}
.ctaBox__vip:hover .ctaBox__vip-element.two {
  opacity: 0.5;
}
.ctaBox__character {
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 259px;
}
.ctaBox__character img {
  width: 100%;
}
.ctaBox__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(48, 47, 129, 0)), to(#537cdb));
  background: linear-gradient(90deg, rgba(48, 47, 129, 0) 0%, #537cdb 100%);
  mix-blend-mode: hard-light;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 1;
}
.ctaBox__bg3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(48, 47, 129, 0)), to(rgba(48, 47, 129, 0.5)));
  background: linear-gradient(90deg, rgba(48, 47, 129, 0) 0%, rgba(48, 47, 129, 0.5) 100%);
  mix-blend-mode: hard-light;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ctaBox__bg2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#302f81), to(#537cdb));
  background: linear-gradient(90deg, #302f81 0%, #537cdb 100%);
}
.ctaBox__ellipse {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: -55px;
  width: 519px;
  height: 519px;
  background: #1e2f81;
  opacity: 1;
  border-radius: 50%;
}
.ctaBox__ellipse2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 171px;
  width: 492px;
  height: 492px;
  background: #1e2f81;
  opacity: 0.5;
  border-radius: 50%;
}
.ctaBox__star {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 2;
}
.ctaBox__star img {
  width: 100%;
}
.ctaBox__star.one {
  width: 27px;
  left: 848px;
  top: 115px;
}
.ctaBox__star.two {
  width: 13px;
  top: 63px;
  right: 53px;
}
.ctaBox__star.three {
  width: 37px;
  top: 23px;
  left: 413px;
}
.ctaBox:hover .ctaBox__bg {
  opacity: 0;
}
.ctaBox:hover .ctaBox__bg3 {
  opacity: 1;
}
.cta.kick .ctaBox__character {
  width: 236px;
}
.cta.kick .ctaBox__ellipse {
  background: #014f08;
  z-index: 1;
}
.cta.kick .ctaBox__ellipse2 {
  background: #01490c;
  z-index: 1;
}
.cta.kick .ctaBox__bg {
  background: -webkit-gradient(linear, left top, right top, from(rgba(47, 129, 64, 0)), to(#53db77));
  background: linear-gradient(90deg, rgba(47, 129, 64, 0) 0%, #53db77 100%);
  z-index: 0;
}
.cta.kick .ctaBox__bg2 {
  z-index: 0;
  background: -webkit-gradient(linear, left top, right top, from(#016d0a), to(#1c6225));
  background: linear-gradient(90deg, #016d0a 0%, #1c6225 100%);
}
.cta.kick .ctaBox__bg4 {
  position: absolute;
  opacity: 0.8;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(47, 129, 64, 0)), to(#53db77));
  background: linear-gradient(180deg, rgba(47, 129, 64, 0) 0%, #53db77 100%);
  z-index: 2;
  mix-blend-mode: hard-light;
}
.cta.kick .ctaBox__content {
  margin-right: 155px;
}
.cta.kick .ctaBox__content h1 {
  font-weight: 800;
}
.cta.kick .ctaBox__content p {
  color: #fff4a1;
}
.cta.kick .ctaBox__content p b {
  font-weight: 800;
}
.cta.kick .ctaBox__content p span {
  font-weight: 900;
}
.cta.kick .ctaBox__content-btn {
  width: 100%;
  max-width: 181px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 40px;
  background: -webkit-gradient(linear, left top, left bottom, from(#55dc23), to(#00ad1d));
  background: linear-gradient(180deg, #55dc23 0%, #00ad1d 100%);
  border: 2px solid #fff;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}
.cta.kick .ctaBox__content-btn:hover {
  opacity: 0.8;
}
.cta.kick .ctaBox__content-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #fff;
  text-shadow: 0px 1px 2px rgba(23, 35, 140, 0.3);
  margin-bottom: 0;
}
.cta.kick .ctaBox__content-btn p span {
  font-weight: 900;
}
.cta.kick .ctaBox__kick {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 142px;
}
.cta.kick .ctaBox__kick img {
  width: 100%;
}
.cta.pred .ctaBox__guess {
  position: absolute;
  top: -140px;
  right: 125px;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 436px;
  height: 432px;
}
.cta.pred .ctaBox__guess img {
  width: 100%;
}
.cta.pred .ctaBox__timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 11px;
  right: 13px;
  z-index: 2;
}
.cta.pred .ctaBox__timer p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #fff;
  margin-right: 9px;
  margin-bottom: 0;
}
.cta.pred .ctaBox__timer-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cta.pred .ctaBox__timer-item {
  width: 64px;
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 3.67px;
  background: #fff;
  margin-right: 4px;
}
.cta.pred .ctaBox__timer-item:last-child {
  margin-right: 0;
}
.cta.pred .ctaBox__timer-item h6 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #100f2d;
}
.cta.pred .ctaBox__timer-item h6 span {
  font-weight: 800;
  font-size: 17px;
  line-height: 20px;
}
.cta.pred .ctaBox__content {
  margin-right: 0;
}
.cta.pred .ctaBox__content-btn {
  width: 100%;
  max-width: 181px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 40px;
  background: -webkit-gradient(linear, left top, left bottom, from(#f2aa3e), to(#d37f30));
  background: linear-gradient(180deg, #f2aa3e 0%, #d37f30 100%);
  border: 1px solid #fff;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}
.cta.pred .ctaBox__content-btn:hover {
  opacity: 0.8;
}
.cta.pred .ctaBox__content-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #fff;
  text-shadow: 0px 1px 2px rgba(23, 35, 140, 0.3);
  margin-bottom: 0;
}
.cta.pred .ctaBox__content-btn p span {
  font-weight: 900;
}
.cta.pred .ctaBox__ellipse {
  background: #401b12;
  z-index: 1;
}
.cta.pred .ctaBox__ellipse2 {
  background: #594920;
}
.cta.pred .ctaBox__bg {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(23.3%, rgba(255, 201, 93, 0)), color-stop(132.22%, #ffc95d));
  background: linear-gradient(180deg, rgba(255, 201, 93, 0) 23.3%, #ffc95d 132.22%);
  border-radius: 5px;
  z-index: 2;
}
.cta.pred .ctaBox__bg2 {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-29.72%, rgba(32, 19, 35, 0)), color-stop(132.22%, #c23700));
  background: linear-gradient(180deg, rgba(32, 19, 35, 0) -29.72%, #c23700 132.22%);
  border-radius: 5px;
  z-index: 1;
}
.cta.pred .ctaBox__bg3 {
  background: -webkit-gradient(linear, left top, right top, from(#201323), to(#c24700));
  background: linear-gradient(90deg, #201323 0%, #c24700 100%);
  mix-blend-mode: hard-light;
  border-radius: 5px;
  opacity: 1;
  z-index: 2;
}
.cta.pred .ctaBox__bg4 {
  position: absolute;
  opacity: 0.8;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#52290e), to(#9c591b));
  background: linear-gradient(90deg, #52290e 0%, #9c591b 100%);
  border-radius: 5px;
  z-index: 1;
}
.cta.pred .ctaBox__star {
  z-index: 3;
}
.cta.pred .ctaBox__star.one {
  bottom: 40px;
  right: 462px;
  left: unset;
  top: unset;
}
.cta.pred .ctaBox__star.two {
  bottom: 58px;
  right: 125px;
}
.cta.pred .ctaBox:hover .ctaBox__bg {
  opacity: 1;
}

.latest {
  overflow: hidden;
}
.latest__inner {
  padding: 24px 0 295px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.latest__inner h3 {
  margin-bottom: 24px;
}
.latest__inner h3 span {
  font-weight: 600;
}
.latest__slider-item {
  margin: 0 10px;
  border-radius: 5px;
  overflow: hidden;
  display: block;
  position: relative;
}
.latest__slider-item-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: none;
}
.latest__slider-item-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}
.latest__slider-item-image img {
  width: 100%;
}
.latest__slider .slick-list {
  overflow: unset;
}
.latest__slider .slick-slide {
  position: relative;
}
.latest__slider .slick-slide::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 15, 45, 0.7);
  content: "";
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 2;
}
.latest__slider .slick-slide.slick-active .latest__slider-item-link {
  display: block;
}
.latest__slider .slick-slide.slick-active::before {
  opacity: 0;
  z-index: -2;
}
.latest__slider .slick-prev {
  transform: translate(0, 0);
  -moz-ransform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  top: -49px;
  right: 44px;
  left: unset;
  width: 31px;
  height: 31px;
  background: -webkit-gradient(linear, left top, left bottom, from(#2d2d54), to(#313063));
  background: linear-gradient(180deg, #2d2d54 0%, #313063 100%);
  border-radius: 8px;
  overflow: hidden;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.latest__slider .slick-prev:hover {
  opacity: 0.8;
}
.latest__slider .slick-prev.slick-disabled {
  opacity: 0.5;
}
.latest__slider .slick-prev::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  opacity: 1;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: url("../assets/icons/chevronLeft.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.latest__slider .slick-next {
  transform: translate(0, 0);
  -moz-ransform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  top: -49px;
  right: 10px;
  width: 31px;
  height: 31px;
  background: -webkit-gradient(linear, left top, left bottom, from(#2d2d54), to(#313063));
  background: linear-gradient(180deg, #2d2d54 0%, #313063 100%);
  border-radius: 8px;
  overflow: hidden;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.latest__slider .slick-next:hover {
  opacity: 0.8;
}
.latest__slider .slick-next.slick-disabled {
  opacity: 0.5;
}
.latest__slider .slick-next::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  opacity: 1;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: url("../assets/icons/chevronRight.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.vipReg {
  background: #22214a;
}
.vipReg__inner {
  padding: 120px 0 165px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.vipReg__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 317px;
}
.vipReg__image img {
  width: 100%;
}
.vipReg__content {
  padding-top: 36px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: calc(100% - 370px);
}
.vipReg__content h3 {
  margin-bottom: 16px;
}
.vipReg__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.vipReg__form-input {
  width: calc(100% - 198px);
}
.vipReg__form-input input {
  width: 100%;
  background: #efefff;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #22214a;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 11px 20px;
}
.vipReg__form-input input::-webkit-input-placeholder {
  color: #9191b7;
  opacity: 1;
}
.vipReg__form-input input::-moz-placeholder {
  color: #9191b7;
  opacity: 1;
}
.vipReg__form-input input:-ms-input-placeholder {
  color: #9191b7;
  opacity: 1;
}
.vipReg__form-input input::-ms-input-placeholder {
  color: #9191b7;
  opacity: 1;
}
.vipReg__form-input input::placeholder {
  color: #9191b7;
  opacity: 1;
}
.vipReg__form-btn {
  max-width: 181px;
  width: 100%;
  background: #30ac72;
  border-radius: 8px;
  padding: 11px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.vipReg__form-btn:hover {
  opacity: 0.8;
}
.vipReg__form-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #fff;
}

.jackpot {
  position: relative;
}
.jackpot__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jackpot__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.jackpot__mount {
  position: absolute;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 652px;
}
.jackpot__mount img {
  width: 100%;
}
.jackpot__mount.right {
  width: 699px;
  left: unset;
  right: 0;
}
.jackpot__car {
  position: absolute;
  bottom: -45px;
  left: 50%;
  -webkit-transform: rotate(0.74deg) translateX(-50%);
          transform: rotate(0.74deg) translateX(-50%);
  width: 453px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jackpot__car img {
  width: 100%;
}
.jackpot__inner {
  padding: 126px 0 380px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.jackpot__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 24px;
}
.jackpot__head-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.jackpot__head-title h2 {
  font-weight: 500;
  line-height: 35px;
  color: #fff;
}
.jackpot__timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.jackpot__timer p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #fff;
  margin-right: 9px;
}
.jackpot__timer-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.jackpot__timer-item {
  width: 64px;
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 3.67px;
  background: #fff;
  margin-right: 4px;
}
.jackpot__timer-item:last-child {
  margin-right: 0;
}
.jackpot__timer-item h6 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #100f2d;
}
.jackpot__timer-item h6 span {
  font-weight: 800;
  font-size: 17px;
  line-height: 20px;
}
.jackpotContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}
.jackpotContent__inner {
  width: 56%;
  max-width: 600px;
}
.jackpotContent__side {
  width: 44%;
  max-width: 454px;
}
.jackpotContent__top {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 115px;
  height: 26px;
  border-radius: 0 5px 0 20px;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jackpotContent__top h5 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 18px;
  line-height: 100%;
  color: #fff;
}
.jackpotContent__top.first {
  background: #d739ff;
}
.jackpotContent__top.first h5 {
  text-shadow: 0px 1.80064px 3.60129px #7834cf;
}
.jackpotContent__top.second {
  background: #2489ff;
}
.jackpotContent__top.second h5 {
  text-shadow: 0px 1.80064px 3.60129px #364abb;
}
.jackpotContent__top.third {
  background: #6f9dd5;
}
.jackpotContent__top.third h5 {
  text-shadow: 0px 1.80064px 3.60129px #5b5dbc;
}
.jackpotContent__icon {
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 30px;
  z-index: 2;
  top: -1px;
  left: -1px;
  background: #1f78ff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 0 0 4px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jackpotContent__icon svg {
  width: 16px;
  height: 16px;
  color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.jackpotContent__icon:hover {
  width: 34px;
  height: 34px;
  background: #00a8ff;
}
.jackpotContent__icon:hover svg {
  width: 22px;
  height: 22px;
}
.jackpotContent__icon:focus {
  width: 30px;
  height: 30px;
  background: #00a8ff;
}
.jackpotContent__icon:focus svg {
  width: 16px;
  height: 16px;
}
.jackpotContent__banner {
  position: relative;
  height: 205.07px;
  border-radius: 5px;
  margin-bottom: 22px;
  padding: 33px 0 0 27px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.jackpotContent__banner::before {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: "";
  background: -webkit-gradient(linear, right top, left top, from(#d739ff), to(#4119b4));
  background: linear-gradient(-90deg, #d739ff 0%, #4119b4 100%);
  border-radius: 5px;
}
.jackpotContent__banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 5px;
  overflow: hidden;
}
.jackpotContent__banner-image img {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.jackpotContent__banner-info {
  position: relative;
  z-index: 2;
  max-width: 135px;
}
.jackpotContent__banner-info-gtr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 112px;
  margin: 0 auto 5px auto;
}
.jackpotContent__banner-info-gtr img {
  width: 100%;
}
.jackpotContent__banner-info p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 13.5048px;
  line-height: 100%;
  text-align: center;
  color: #d3aafc;
  margin-bottom: 10px;
}
.jackpotContent__banner-info-price {
  width: 135px;
  background: #d739ff;
  border-radius: 90px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jackpotContent__banner-info-price h6 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 23px;
  line-height: 1;
  color: #fff;
  text-shadow: 0px 1.80064px 3.60129px #7834cf;
}
.jackpotContent__banner:hover .jackpotContent__banner-image img {
  scale: 1.1;
}
.jackpotContent__rolex {
  width: 100%;
  height: 134px;
  position: relative;
  padding: 34px 0 0 28px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 22px;
}
.jackpotContent__rolex::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  position: absolute;
  content: "";
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background: #307bd3;
  border-radius: 5px;
}
.jackpotContent__rolex-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  overflow: hidden;
}
.jackpotContent__rolex-bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.jackpotContent__rolex-info {
  position: relative;
  z-index: 2;
  max-width: 135px;
}
.jackpotContent__rolex-info img {
  width: 94px;
  margin: 0 auto;
  display: block;
}
.jackpotContent__rolex-info h5 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 18.9674px;
  line-height: 23px;
  text-align: center;
  color: #a1c6ff;
}
.jackpotContent__rolex-info p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 13.5048px;
  line-height: 100%;
  color: #81d1f5;
  text-align: center;
  margin-bottom: 5px;
}
.jackpotContent__rolex-info-price {
  width: 135px;
  background: #2489ff;
  border-radius: 90px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jackpotContent__rolex-info-price h6 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 23px;
  line-height: 1;
  color: #fff;
  text-shadow: 0px 1.80064px 3.60129px #364abb;
}
.jackpotContent__rolex:hover .jackpotContent__rolex-bg img {
  scale: 1.1;
}
.jackpotContent__eth {
  width: 100%;
  height: 98px;
  position: relative;
  padding: 31px 0 0 29px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
}
.jackpotContent__eth::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  position: absolute;
  content: "";
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background: #6f9dd5;
  border-radius: 5px;
}
.jackpotContent__eth-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  overflow: hidden;
}
.jackpotContent__eth-bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.jackpotContent__eth-info {
  position: relative;
  z-index: 2;
  width: 135px;
  background: #6f9dd5;
  border-radius: 90px;
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jackpotContent__eth-info h6 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 23px;
  line-height: 1;
  color: #fff;
  text-shadow: 0px 1.80064px 3.60129px #4070ab;
}
.jackpotContent__eth:hover .jackpotContent__eth-bg img {
  scale: 1.1;
}
.jackpotContent__lottery {
  position: relative;
  height: 174px;
  border-radius: 5px;
  border: 1px solid #4e4db0;
  padding: 24px 0 0 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.jackpotContent__lottery-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(17, 16, 46, 0.5);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 5px;
}
.jackpotContent__lottery-bg2 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#11102e), to(#003cb4));
  background: linear-gradient(180deg, #11102e 0%, #003cb4 100%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 5px;
}
.jackpotContent__lottery-title h2 {
  color: #fff;
}
.jackpotContent__lottery-title h2 span {
  background: -webkit-gradient(linear, left top, right top, color-stop(36.24%, #44bfff), color-stop(64.96%, #307bd3));
  background: linear-gradient(90deg, #44bfff 36.24%, #307bd3 64.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.jackpotContent__lottery-title p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 11.1186px;
  line-height: 13px;
  color: #fff;
}
.jackpotContent__lottery-title p a {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 11.1186px;
  line-height: 13px;
  color: #fff;
  text-decoration: underline;
  text-underline-position: under;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.jackpotContent__lottery-title p a:hover {
  opacity: 0.8;
}
.jackpotContent__lottery-title p span {
  color: #9bd3ff;
}
.jackpotContent__lottery-items {
  position: absolute;
  bottom: 0;
  right: 39px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.jackpotContent__lottery-item {
  width: 112px;
  border-radius: 5px 5px 0 0;
  margin-right: 22px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.jackpotContent__lottery-item h5 {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.jackpotContent__lottery-item h6 {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.jackpotContent__lottery-item:last-child {
  margin-right: 0;
}
.jackpotContent__lottery-item.first {
  padding-top: 8px;
  height: 65px;
  background: -webkit-gradient(linear, left top, left bottom, from(#83b1dc), to(#719ac0));
  background: linear-gradient(180deg, #83b1dc 0%, #719ac0 100%);
}
.jackpotContent__lottery-item.first h5 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 23px;
  line-height: 25px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 2px 4px #598cbc;
}
.jackpotContent__lottery-item.first h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #02417b;
}
.jackpotContent__lottery-item.first h6 span {
  font-weight: 900;
  color: #092153;
}
.jackpotContent__lottery-item.second {
  padding-top: 14px;
  height: 73px;
  background: -webkit-gradient(linear, left top, left bottom, from(#57cdff), to(#389fff));
  background: linear-gradient(180deg, #57cdff 0%, #389fff 100%);
}
.jackpotContent__lottery-item.second h5 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 23px;
  line-height: 25px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 2px 4px rgba(35, 134, 216, 0.6);
}
.jackpotContent__lottery-item.second h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #02417b;
}
.jackpotContent__lottery-item.second h6 span {
  font-weight: 900;
  color: #092153;
}
.jackpotContent__lottery-item.third {
  padding-top: 24px;
  height: 90px;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffeb38), to(#ffd538));
  background: linear-gradient(180deg, #ffeb38 0%, #ffd538 100%);
}
.jackpotContent__lottery-item.third h5 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 23px;
  line-height: 25px;
  text-align: center;
  color: #5c0d06;
  text-shadow: 0px 2px 4px rgba(221, 96, 43, 0.3);
}
.jackpotContent__lottery-item.third h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #dd602b;
}
.jackpotContent__lottery-item.third h6 span {
  font-weight: 900;
  color: #5c0d06;
}
.jackpotContent__lottery .jackpotContent__icon {
  left: unset;
  right: 0;
  border-radius: 0 0 0 4px;
}
.jackpotContent__lottery:hover {
  border: 1px solid #307bd3;
}
.jackpotContent__lottery:hover .jackpotContent__lottery-bg2 {
  opacity: 1;
}
.jackpotContent__lottery:hover .jackpotContent__lottery-item.first {
  height: 75px;
}
.jackpotContent__lottery:hover .jackpotContent__lottery-item.first h5 {
  font-size: 22.585px;
  line-height: 27px;
  text-shadow: 0px 2.2585px 4.51701px #598cbc;
}
.jackpotContent__lottery:hover .jackpotContent__lottery-item.first h6 {
  font-size: 15.8095px;
  line-height: 19px;
}
.jackpotContent__lottery:hover .jackpotContent__lottery-item.first h6 span {
  font-size: 15.8095px;
  line-height: 19px;
}
.jackpotContent__lottery:hover .jackpotContent__lottery-item.second {
  height: 83px;
}
.jackpotContent__lottery:hover .jackpotContent__lottery-item.second h5 {
  font-size: 22.9932px;
  line-height: 28px;
  text-shadow: 0px 2.29932px 4.59864px rgba(35, 134, 216, 0.6);
}
.jackpotContent__lottery:hover .jackpotContent__lottery-item.second h6 {
  font-size: 16.0952px;
  line-height: 19px;
}
.jackpotContent__lottery:hover .jackpotContent__lottery-item.second h6 span {
  font-size: 16.0952px;
  line-height: 19px;
}
.jackpotContent__lottery:hover .jackpotContent__lottery-item.third {
  height: 102px;
}
.jackpotContent__lottery:hover .jackpotContent__lottery-item.third h5 {
  font-size: 25.1852px;
  line-height: 30px;
  text-shadow: 0px 2.51852px 5.03704px rgba(221, 96, 43, 0.3);
}
.jackpotContent__lottery:hover .jackpotContent__lottery-item.third h6 {
  font-size: 17.6296px;
  line-height: 21px;
}
.jackpotContent__lottery:hover .jackpotContent__lottery-item.third h6 span {
  font-size: 17.6296px;
  line-height: 21px;
}
.jackpotContent__tickets {
  width: 100%;
  border: 1px solid #4e4db0;
  position: relative;
  border-radius: 5px;
  padding: 32px 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-box-shadow: 0px 0px 10px #100f2d;
          box-shadow: 0px 0px 10px #100f2d;
  margin-bottom: 16px;
}
.jackpotContent__tickets .jackpotContent__icon {
  left: unset;
  right: 0;
  border-radius: 0 0 0 4px;
}
.jackpotContent__tickets-bg {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 7, 34, 0.5);
  border-radius: 5px;
}
.jackpotContent__tickets-bg2 {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: radial-gradient(106% 106% at 50% 50%, rgba(12, 7, 34, 0) 0%, rgba(0, 60, 180, 0.5) 100%), rgba(0, 0, 0, 0.2);
}
.jackpotContent__tickets-title {
  margin-bottom: 16px;
}
.jackpotContent__tickets-title h3 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}
.jackpotContent__tickets-title h3 span {
  color: #9bd3ff;
  text-decoration: underline;
  text-underline-position: under;
}
.jackpotContent__tickets-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 11px;
}
.jackpotContent__tickets-row-item {
  width: calc(50% - 5px);
  background: #100f2d;
  position: relative;
}
.jackpotContent__tickets-row-item::before {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: -webkit-gradient(linear, left top, left bottom, from(#44bfff), to(#ffffff));
  background: linear-gradient(180deg, #44bfff 0%, #ffffff 100%);
  border-radius: 5px;
}
.jackpotContent__tickets-row-item-head {
  background: #100f2d;
  position: relative;
  z-index: 2;
  border-radius: 5px 5px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 63px;
}
.jackpotContent__tickets-row-item-head h6 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 14.9016px;
  line-height: 18px;
  text-align: center;
  color: #45c0ff;
}
.jackpotContent__tickets-row-item-head-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jackpotContent__tickets-row-item-head-desc p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #fff;
}
.jackpotContent__tickets-row-item-head-desc p span {
  font-weight: 900;
  font-style: italic;
}
.jackpotContent__tickets-row-item-head-icon {
  width: 16px;
  height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #1f78ff;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin-left: 8px;
}
.jackpotContent__tickets-row-item-head-icon:hover {
  opacity: 0.8;
}
.jackpotContent__tickets-row-item-head-icon svg {
  width: 12px;
  height: 12px;
  color: #fff;
}
.jackpotContent__tickets-row-item-body {
  height: 30px;
  width: 100%;
  background: #fff;
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.jackpotContent__tickets-row-item-body img {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  -webkit-filter: drop-shadow(0px 0.74508px 1.49016px rgba(36, 35, 82, 0.3));
          filter: drop-shadow(0px 0.74508px 1.49016px rgba(36, 35, 82, 0.3));
}
.jackpotContent__tickets-row-item-body p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 15px;
  line-height: 18px;
  color: #100f2d;
}
.jackpotContent__tickets-row-item-body p span {
  font-weight: 900;
  color: #307bd3;
}
.jackpotContent__tickets-row-item-body p b {
  font-weight: 700;
}
.jackpotContent__tickets-row-item-body p i {
  font-style: normal;
  font-weight: 300;
}
.jackpotContent__tickets-box {
  position: relative;
}
.jackpotContent__tickets-box-inner {
  background: #100f2d;
  position: relative;
  z-index: 2;
  height: 84px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.jackpotContent__tickets-box::before {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: -webkit-gradient(linear, left top, left bottom, from(#44bfff), to(#307bd3));
  background: linear-gradient(180deg, #44bfff 0%, #307bd3 100%);
  border-radius: 5px;
}
.jackpotContent__tickets-box h6 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 14.9016px;
  line-height: 18px;
  text-align: center;
  color: #45c0ff;
}
.jackpotContent__tickets-box p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  max-width: 245px;
  margin: 0 auto;
}
.jackpotContent__tickets:hover {
  padding: 24px 24px 40px 24px;
  border: 1px solid #307bd3;
}
.jackpotContent__tickets:hover .jackpotContent__tickets-bg {
  opacity: 0;
}
.jackpotContent__tickets:hover .jackpotContent__tickets-bg2 {
  opacity: 1;
}
.jackpot .button.play {
  width: 100%;
  height: 48px;
}

.leaderboard__inner {
  padding: 85px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.leaderboard__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 50px;
}
.leaderboard__title h1 {
  line-height: 85px;
}
.leaderboard__title h2 {
  font-weight: 500;
  line-height: 28px;
  color: #fff;
}
.leaderboard__timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 72px;
}
.leaderboard__timer p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #fff;
  margin-right: 9px;
}
.leaderboard__timer-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.leaderboard__timer-item {
  width: 64px;
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 3.67px;
  background: #fff;
  margin-right: 4px;
}
.leaderboard__timer-item:last-child {
  margin-right: 0;
}
.leaderboard__timer-item h6 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #100f2d;
}
.leaderboard__timer-item h6 span {
  font-weight: 800;
  font-size: 17px;
  line-height: 20px;
}
.leaderboard .button.play {
  width: 100%;
  max-width: 374px;
  height: 48px;
  margin: 0 auto;
}
.leaderboard .button.play span.default::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#30ac72), to(#60c946));
  background: linear-gradient(180deg, #30ac72 0%, #60c946 100%);
}
.leaderboard .button.play svg {
  right: 16px;
}

.guess {
  position: relative;
}
.guess__bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 35px);
  background: -webkit-gradient(linear, left bottom, left top, from(#602300), color-stop(66.77%, #100f2d));
  background: linear-gradient(0deg, #602300 0%, #100f2d 66.77%);
}
.guess__coins {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-filter: blur(1px);
          filter: blur(1px);
}
.guess__coins img {
  width: 100%;
}
.guess__inner {
  padding: 126px 0 292px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.guessContent {
  width: 100%;
  max-width: 1031px;
  background: rgba(13, 8, 36, 0.5);
  border: 1px solid #1f1d47;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: 536px;
  margin: 0 -58px 0 auto;
  padding: 72px 42px 50px 60px;
  box-sizing: border-box;
  position: relative;
}
.guessContent__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 70px;
}
.guessContent__info {
  max-width: 285px;
}
.guessContent__info p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #adacde;
}
.guessContent__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 12px;
}
.guessContent__title h1 {
  font-size: 61px;
  line-height: 1;
}
.guessContent__title h2 {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-size: 34px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: #e8f4ff;
}
.guessContent__action {
  width: calc(100% - 300px);
  max-width: 431px;
}
.guessContent__action-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 40px;
}
.guessContent__action-info h4 {
  font-family: #22214a;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
}
.guessContent__action-info h5 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16.9006px;
  line-height: 20px;
  text-align: right;
  color: #fff;
}
.guessContent__action-info h5.error {
  color: #ed6363;
}
.guessContent__action-auth {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.guessContent__action-auth h6 {
  width: 56px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #fff;
}
.guessContent__action-auth .button {
  width: calc(50% - 28px);
  max-width: unset;
  padding: 9.5px 0;
}
.guessContent__action-auth .button p b {
  font-weight: 900;
}
.guessContent__action-auth .button.login p {
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.05em;
  color: #fff;
}
.guessContent__action-auth .button.register p {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #fff;
  text-shadow: 0px 1px 2px rgba(23, 35, 140, 0.3);
  letter-spacing: unset;
}
.guessContent__action-predict {
  width: 100%;
}
.guessContent__action-predict-input {
  margin-bottom: 32px;
}
.guessContent__action-predict-input input {
  width: 100%;
  background: #444376;
  border: 1px solid #1f93ff;
  border-radius: 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #fff;
  padding: 10px 20px;
}
.guessContent__action-predict-input input::-webkit-input-placeholder {
  opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}
.guessContent__action-predict-input input::-moz-placeholder {
  opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}
.guessContent__action-predict-input input:-ms-input-placeholder {
  opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}
.guessContent__action-predict-input input::-ms-input-placeholder {
  opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}
.guessContent__action-predict-input input::placeholder {
  opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}
.guessContent__action-predict-input.error input {
  border: 1px solid #ed6363;
}
.guessContent__action-predict .button.green {
  width: 100%;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.guessContent__action-predict .button.green svg {
  position: relative;
  z-index: 2;
  width: 23px;
  height: 16px;
  color: #fff;
  margin-left: 8px;
}
.guessContent__action-predict .button.green p {
  font-weight: 700;
  font-size: 22px;
  line-height: 1;
  color: #fff;
  text-shadow: 0px 0.884161px 1.76832px #187443;
}
.guessContent__action-predict .button.green.active span.hov {
  opacity: 0;
}
.guessContent__action-predict .button.green.active span.active {
  opacity: 1;
}
.guessContent__timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.guessContent__timer p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #fff;
  margin-right: 9px;
}
.guessContent__timer-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.guessContent__timer-item {
  width: 64px;
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 3.67px;
  background: #fff;
  margin-right: 4px;
}
.guessContent__timer-item:last-child {
  margin-right: 0;
}
.guessContent__timer-item h6 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #100f2d;
}
.guessContent__timer-item h6 span {
  font-weight: 800;
  font-size: 17px;
  line-height: 20px;
}
.guessContent__wheel {
  position: absolute;
  left: -171px;
  top: 72px;
  width: 217px;
  height: 217px;
}
.guessContent__wheel-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #ffd848;
  -webkit-filter: blur(2px);
          filter: blur(2px);
  border-radius: 50%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
}
.guessContent__wheel-bg2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #8e3813;
  -webkit-filter: blur(10px);
          filter: blur(10px);
  border-radius: 50%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
}
.guessContent__wheel-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.guessContent__wheel-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.guessContent__wheel-mid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 110px;
  height: 110px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.guessContent__wheel-mid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.guessContent__wheel-mid img.on {
  opacity: 0;
  z-index: 1;
}
.guessContent__wheel-selector {
  position: absolute;
  top: -8.24px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 47.57px;
  height: 48.67px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.guessContent__wheel-selector img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.guessContent__wheel-midBg {
  position: absolute;
  top: 27px;
  left: calc(50% - 1px);
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 169.02px;
  height: 168.6px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
}
.guessContent__wheel-midBg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.guessContent__wheel-rotated {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 22.6px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 175px;
  height: 176px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.guessContent__wheel-rotated img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.guessContent__wheel:hover .guessContent__wheel-bg {
  opacity: 1;
}
.guessContent__wheel:hover .guessContent__wheel-bg2 {
  opacity: 1;
}
.guessContent__wheel:hover .guessContent__wheel-rotated {
  -webkit-transform: translateX(-50%) rotate(-167.44deg);
          transform: translateX(-50%) rotate(-167.44deg);
}
.guessContent__wheel:hover .guessContent__wheel-midBg {
  opacity: 1;
}
.guessContent__wheel:hover .guessContent__wheel-mid img.on {
  opacity: 1;
}
.guessContent__prizes h5 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #fff;
  margin-bottom: 16px;
}
.guessContent__prizes-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.guessContent__prizes-car {
  cursor: pointer;
  position: relative;
  padding-right: 9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  background: #db5e1f;
  border: 1px solid #f0ca36;
  border-radius: 1.62177px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 126px;
  height: 28px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin: 6px 7px 6px 0;
}
.guessContent__prizes-car img {
  position: absolute;
  left: 5px;
  bottom: -2px;
  height: 26px;
}
.guessContent__prizes-car p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 10.6303px;
  line-height: 13px;
  text-align: right;
  color: #fff;
}
.guessContent__prizes-car:hover {
  background: #f7911b;
}
.guessContent__prizes-gaming {
  cursor: pointer;
  position: relative;
  padding-right: 9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0.44%, rgba(51, 105, 232, 0.5)), color-stop(99.56%, rgba(1, 42, 116, 0.5)));
  background: linear-gradient(0deg, rgba(51, 105, 232, 0.5) 0.44%, rgba(1, 42, 116, 0.5) 99.56%);
  border: 1px solid #ef95ff;
  border-radius: 1.62177px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 126px;
  height: 28px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin: 6px 7px 6px 0;
}
.guessContent__prizes-gaming img {
  position: absolute;
  left: 5px;
  bottom: 0;
  height: 26px;
}
.guessContent__prizes-gaming p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 10.6303px;
  line-height: 13px;
  text-align: right;
  color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.guessContent__prizes-gaming:hover {
  background: rgba(51, 105, 232, 0.5);
  border: 1px solid #fff;
}
.guessContent__prizes-gaming:hover p {
  color: #ffffff;
}
.guessContent__prizes-item {
  cursor: pointer;
  position: relative;
  padding: 0 9px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: 1px solid #dac069;
  border-radius: 1.62177px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 126px;
  height: 28px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  margin: 6px 7px 6px 0;
}
.guessContent__prizes-item:last-child {
  margin-right: 0;
}
.guessContent__prizes-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0.44%, rgba(51, 105, 232, 0.5)), color-stop(99.56%, rgba(1, 42, 116, 0.5)));
  background: linear-gradient(0deg, rgba(51, 105, 232, 0.5) 0.44%, rgba(1, 42, 116, 0.5) 99.56%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.guessContent__prizes-item::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(51, 105, 232, 0.5);
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.guessContent__prizes-item-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  z-index: 2;
}
.guessContent__prizes-item-title svg {
  width: 15px;
  height: 15px;
  margin-right: 4px;
  color: #fff;
}
.guessContent__prizes-item-title h6 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 7.55357px;
  line-height: 9px;
  color: #fff;
}
.guessContent__prizes-item p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 10.6303px;
  line-height: 13px;
  text-align: right;
  color: #dac069;
  position: relative;
  z-index: 2;
}
.guessContent__prizes-item.pack {
  border: 1px solid #ef95ff;
}
.guessContent__prizes-item.pack .guessContent__prizes-item-title svg {
  color: #ef95ff;
}
.guessContent__prizes-item.pack .guessContent__prizes-item-title svg path.otherpath {
  color: #731d82;
}
.guessContent__prizes-item.pack p {
  color: #ef95ff;
}
.guessContent__prizes-item:hover {
  border: 1px solid #fff;
}
.guessContent__prizes-item:hover.pack {
  border: 1px solid #fff;
}
.guessContent__prizes-item:hover::before {
  opacity: 0;
}
.guessContent__prizes-item:hover::after {
  opacity: 1;
}

.store {
  position: relative;
  overflow: hidden;
}
.store__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: -1;
}
.store__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.store__image2 {
  display: none;
}
.store__star {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.store__star img {
  width: 100%;
}
.store__star.one {
  top: 227px;
  left: calc(50% - 615px);
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 36px;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  -webkit-transform: rotate(25.05deg);
          transform: rotate(25.05deg);
}
.store__star.two {
  top: 410px;
  left: calc(50% + 621px);
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 102px;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  -webkit-transform: rotate(-29.42deg);
          transform: rotate(-29.42deg);
}
.store__star.three {
  top: 470px;
  left: calc(50% - 430px);
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 17px;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  -webkit-transform: rotate(-20.15deg);
          transform: rotate(-20.15deg);
}
.store__bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#5a3ffe), to(#100f2d));
  background: linear-gradient(180deg, #5a3ffe 0%, #100f2d 100%);
  z-index: -2;
}
.store__bg2 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#3989e7), to(rgba(32, 77, 129, 0)));
  background: linear-gradient(180deg, #3989e7 0%, rgba(32, 77, 129, 0) 100%);
  mix-blend-mode: hue;
  z-index: 0;
}
.store__inner {
  padding: 110px 0 170px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.store__inner h1 {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 37px;
  line-height: 1;
  color: #fff;
  margin-bottom: 44px;
}
.store__top-title {
  margin-bottom: 24px;
}
.store__top-title h3 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 1;
  color: #fff;
}
.store__top-title h3 span {
  font-weight: 600;
}
.store__top-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 70px;
}
.store__top-item {
  width: calc(50% - 12px);
  padding: 44px 16px 44px 200px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: 216px;
  background: #0c0722;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  overflow: hidden;
}
.store__top-item-image {
  width: 100%;
  position: relative;
  padding-bottom: 135%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.store__top-item-image-outer {
  width: 160px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.store__top-item-image-outer::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  content: "";
  background: linear-gradient(179.36deg, #0c0722 0.55%, rgba(12, 7, 34, 0) 16.38%, rgba(12, 7, 34, 0) 84.12%, #0c0722 99.45%);
}
.store__top-item-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.store__top-item-content-title {
  margin-bottom: 16px;
}
.store__top-item-content-title h2 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #fff;
}
.store__top-item-content-title h2 span {
  color: #87b8c3;
}
.store__top-item-content-title p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 11.7px;
  line-height: 14px;
  color: #fff;
}
.store__top-item-content-stock p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 11.7px;
  line-height: 14px;
  color: #adacde;
}
.store__top-item:nth-child(2) .store__top-item-content-title h2 span {
  color: #72d5ff;
}
.store__top-item .button.buy {
  width: 100%;
  max-width: 236px;
  margin-bottom: 5px;
}
.store__top-item:hover .store__top-item-image {
  scale: 1.1;
}
.store__earn {
  background: -webkit-gradient(linear, left top, left bottom, from(#3b8bea), to(#3157d5));
  background: linear-gradient(180deg, #3b8bea 0%, #3157d5 100%);
  position: relative;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}
.store__earn-shard {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.store__earn-shard img {
  width: 100%;
}
.store__earn-shard.one {
  width: 72px;
  left: 10px;
  bottom: -50px;
}
.store__earn-shard.one img {
  -webkit-transform: rotate(21.15deg);
          transform: rotate(21.15deg);
}
.store__earn-shard.two {
  width: 62px;
  left: 98px;
  bottom: -62px;
}
.store__earn-shard.two img {
  -webkit-transform: rotate(44.95deg);
          transform: rotate(44.95deg);
}
.store__earn-shard.three {
  display: none;
}
.store__earn-inner {
  padding: 30px 40px 30px 75px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.store__earn-info {
  width: 220px;
}
.store__earn-info h2 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #fff;
}
.store__earn-info h2 span {
  font-weight: 800;
}
.store__earn-info h2 img.shard {
  width: 20px;
  height: 33px;
  -webkit-transform: rotate(21.15deg);
          transform: rotate(21.15deg);
  margin-bottom: -7px;
}
.store__earn-info h2 img.kickIcon {
  width: 64px;
  height: 21px;
  margin-bottom: -1px;
}
.store__earn-row {
  width: calc(100% - 260px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.store__earn-item {
  border-radius: 5px;
  overflow: hidden;
  width: calc(33.33334% - 12px);
  background: #0c0722;
}
.store__earn-item-title {
  padding: 8px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #54afff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.store__earn-item-title svg {
  width: 15px;
  height: 15px;
  color: #fff;
  -webkit-filter: drop-shadow(0px 1.87019px 3.74038px #0057bd);
          filter: drop-shadow(0px 1.87019px 3.74038px #0057bd);
  margin-right: 6px;
}
.store__earn-item-title h4 {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 16.8317px;
  line-height: 20px;
  color: #fff;
  text-shadow: 0px 1.87019px 3.74038px #0057bd;
}
.store__earn-item-content {
  padding: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
.store__earn-item-content-image {
  position: absolute;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 67px;
}
.store__earn-item-content-image img {
  width: 100%;
}
.store__earn-item-content p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #adacde;
}
.store__earn-item.guess .store__earn-item-content {
  padding: 16px 16px 16px 70px;
}
.store__earn-item.disabled .store__earn-item-title {
  background: #313061;
}
.store__earn-item.disabled .store__earn-item-title h4 {
  color: #adacde;
  text-shadow: 0px 1.87019px 3.74038px rgba(12, 7, 34, 0.5);
}
.store__earn-item.disabled .store__earn-item-title svg {
  color: #adacde;
  -webkit-filter: drop-shadow(0px 1.87019px 3.74038px rgba(12, 7, 34, 0.5));
          filter: drop-shadow(0px 1.87019px 3.74038px rgba(12, 7, 34, 0.5));
}
.store__prize {
  background: #100f2d;
}
.store__prize-inner {
  padding: 50px 72px 122px 72px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.store__prize-title {
  margin-bottom: 22px;
}
.store__prize-title h3 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18.7px;
  line-height: 1;
  color: #fff;
}
.store__prize-title h3 span {
  font-weight: 800;
}
.store__prize-items {
  width: calc(100% + 24px);
  margin: 0 -12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.store__prize-item {
  width: calc(25% - 24px);
  margin: 22px 12px;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
}
.store__prize-item-inner {
  padding: 42px 0 20px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: radial-gradient(57.06% 57.06% at 50% 50%, #3e3cab 0%, #252457 100%);
}
.store__prize-item-title {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(12, 7, 34, 0.5);
  padding: 9px 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 2;
  width: 100%;
  max-width: 173px;
  border-radius: 0 0 20px 0;
}
.store__prize-item-title h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  color: #fff;
}
.store__prize-item-title svg {
  width: 12px;
  height: 12px;
  color: #fff;
  margin-top: -2px;
  margin-right: 4px;
}
.store__prize-item-image {
  width: 100%;
  position: relative;
  padding-bottom: 100%;
  z-index: 2;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.store__prize-item-image-outer {
  width: 65%;
  max-width: 130px;
  margin: 0 auto;
  position: relative;
}
.store__prize-item-image-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 0;
}
.store__prize-item-image-circle img {
  width: 100%;
  height: 100%;
}
.store__prize-item-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.store__prize-item .button.buy {
  width: calc(100% - 2px);
  border-radius: 0 0 5px 5px;
  margin-left: 1px;
  margin-bottom: 1px;
}
.store__prize-item .button.buy span {
  border-radius: 0 0 5px 5px;
}
.store__prize-item .button.buy span::before {
  border-radius: 0 0 5px 5px;
}
.store__prize-item .button.buy span::after {
  border-radius: 0 0 5px 5px;
}
.store__prize-item .button.buy p {
  font-size: 20px;
}
.store__prize-item .button.buy img {
  width: 19px;
  height: 32px;
  -webkit-transform: rotate(16.92deg);
          transform: rotate(16.92deg);
}
.store__prize-item.legend .store__prize-item-inner {
  background: none;
  position: relative;
}
.store__prize-item.legend .store__prize-item-bg {
  background: #313061;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.store__prize-item.legend .store__prize-item-bg2 {
  background: linear-gradient(227.26deg, #9c5231 0.5%, #c87243 30.24%, #ffc421 52.89%, #be6b3f 65.16%, #511b12 94.89%);
  mix-blend-mode: color-dodge;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.store__prize-item.legend .store__prize-item-bg3 {
  background: #313061;
  mix-blend-mode: difference;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.store__prize-item.legend .store__prize-item-bg4 {
  background: #3ed2ff;
  mix-blend-mode: color;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.store__prize-item.legend .store__prize-item-bg5 {
  background: #adecff;
  mix-blend-mode: overlay;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.store__prize-item.legend .store__prize-item-bg6 {
  background: #adecff;
  mix-blend-mode: overlay;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.store__prize-item.legend .store__prize-item-bg7 {
  background: #adecff;
  mix-blend-mode: overlay;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.store__prize-item.legend .store__prize-item-bg8 {
  background: radial-gradient(71.74% 71.74% at 50% 50%, rgba(62, 210, 255, 0) 0%, #0c0722 100%);
  mix-blend-mode: overlay;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.store__prize-item.epic .store__prize-item-inner {
  background: none;
  position: relative;
}
.store__prize-item.epic .store__prize-item-backg {
  background: radial-gradient(89.13% 89.13% at 50% 50%, #ab5d3c 0%, #252457 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.store__prize-item.epic .store__prize-item-backg2 {
  background: #783e17;
  mix-blend-mode: color;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.store__prize-item.epic .store__prize-item-backg3 {
  background: #c16c3e;
  mix-blend-mode: overlay;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.store__prize-item:hover .store__prize-item-image {
  scale: 1.1;
}

.profile {
  background: #0a0a17;
}
.profile__inner {
  padding: 100px 0 300px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.profile__title {
  margin-bottom: 40px;
}
.profile__title h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  color: #fff;
}
.profile__btns {
  border-radius: 10px;
  background: #1f1d47;
  padding: 6px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.profile__btn {
  padding: 9px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 61px;
  background: none;
  margin-right: 10px;
  cursor: pointer;
}
.profile__btn:last-child {
  margin-right: 0;
}
.profile__btn p {
  position: relative;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #bdbaef;
  text-shadow: 0px 2px 4px rgba(31, 29, 71, 0.5);
  z-index: 2;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profile__btn::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(19, 17, 45, 0.16)), to(rgba(31, 29, 71, 0.4)));
  background: linear-gradient(180deg, rgba(19, 17, 45, 0.16) 0%, rgba(31, 29, 71, 0.4) 100%);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profile__btn::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #3f3b80;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profile__btn.active p {
  color: #fff;
}
.profile__btn.active::after {
  opacity: 1;
}
.profile__btn.active::before {
  opacity: 0;
}
.profile__btn:hover p {
  color: #fff;
}
.profile__btn:hover::after {
  opacity: 1;
}
.profile__btn:hover::before {
  opacity: 0;
}
.profileAccount {
  margin-top: 20px;
}
.profileAccount__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.profileAccount__content {
  width: calc(55% - 9px);
  border-radius: 10px;
  border: 1px solid #363270;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 35px 35px 40px 45px;
}
.profileAccount__title {
  margin-bottom: 24px;
}
.profileAccount__title h3 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
}
.profileAccount__info {
  max-width: 484px;
  padding: 10px 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 10px;
  background: #24224c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 50px;
}
.profileAccount__info-content {
  width: calc(100% - 120px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.profileAccount__info-name h4 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  color: #fff;
}
.profileAccount__info-rank {
  min-width: 81px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 3px 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #1c1a3a;
  border-radius: 12px;
}
.profileAccount__info-rank-image {
  height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.profileAccount__info-rank-image img {
  height: 100%;
}
.profileAccount__info-rank h6 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #f3c24d;
  margin-left: 8px;
}
.profileAccount__avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 90px;
}
.profileAccount__avatar-image {
  width: 69px;
  height: 69px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  background: #3f3b80;
  overflow: hidden;
}
.profileAccount__avatar-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.profileAccount__avatar-icon {
  position: relative;
  width: 69px;
  height: 69px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  background: #3f3b80;
  overflow: hidden;
}
.profileAccount__avatar-icon input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  -webkit-appearance: none;
}
.profileAccount__avatar-icon svg {
  width: 47px;
  height: 47px;
  color: #24224c;
}
.profileAccount__avatar-btns {
  margin-left: 1px;
}
.profileAccount__avatar-btn {
  width: 20px;
  height: 20px;
  border-radius: 5.71px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #3f3b80;
  margin-bottom: 6px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
  cursor: pointer;
}
.profileAccount__avatar-btn input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  -webkit-appearance: none;
}
.profileAccount__avatar-btn:last-child {
  margin-bottom: 0;
}
.profileAccount__avatar-btn svg {
  width: 8px;
  height: 8px;
  color: #24224c;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profileAccount__avatar-btn.edit svg {
  width: 12px;
  height: 12px;
}
.profileAccount__avatar-btn:hover {
  background: #100f2d;
}
.profileAccount__avatar-btn:hover svg {
  color: #fff;
}
.profileAccount__links {
  max-width: 484px;
}
.profileAccount__link {
  margin-bottom: 20px;
}
.profileAccount__link:last-child {
  margin-bottom: 0;
}
.profileAccount__link-title {
  margin-bottom: 6px;
}
.profileAccount__link-title p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #fff;
}
.profileAccount__link-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.profileAccount__link-row .button.link {
  width: 74px;
  border-radius: 10px;
}
.profileAccount__link-input {
  width: calc(100% - 81px);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border: 1px solid #24224c;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.profileAccount__link-input.error {
  border: 1px solid #b03749;
}
.profileAccount__link-input-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 33px;
  height: 100%;
  background: #24224c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.profileAccount__link-input-icon svg {
  width: 14px;
  height: 14px;
  color: #6760d6;
}
.profileAccount__link-input-linked {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 12px;
  width: 11px;
  height: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.profileAccount__link-input-linked svg {
  width: 100%;
  height: 100%;
  color: #2eb48a;
}
.profileAccount__link-input input {
  width: 100%;
  background: #0a0a17;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 46px 11px 46px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #fff;
}
.profileAccount__vip {
  height: 462px;
  width: calc(45% - 9px);
  border: 1px solid #363270;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.profileAccount__vip::before {
  position: absolute;
  top: 112px;
  left: 0;
  width: 100%;
  height: calc(100% - 112px);
  z-index: -1;
  content: "";
  background: rgba(10, 10, 23, 0.5);
  opacity: 0;
}
.profileAccount__vip.locked::before {
  opacity: 1;
  z-index: 3;
}
.profileAccount__vip-progress {
  height: 90px;
  background: #0a0a17;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.profileAccount__vip-map {
  overflow: hidden;
  background: #002540;
  position: relative;
  height: calc(100% - 202px);
}
.profileAccount__vip-map::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  content: "";
  z-index: 1;
}
.profileAccount__vip-map-inner {
  height: 100%;
  overflow: hidden;
}
.profileAccount__vip-map .map {
  width: 100%;
  margin-left: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.profileAccount__vip-map .map__outer {
  padding-top: 0;
  margin-top: 0;
}
.profileAccount__vip-map .map__outer::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(#031627), color-stop(18.19%, #002641), color-stop(43%, #150152), color-stop(65%, #450041), color-stop(98.8%, #722800)), #0a0a17;
  background: linear-gradient(180deg, #031627 0%, #002641 18.19%, #150152 43%, #450041 65%, #722800 98.8%), #0a0a17;
}
.profileAccount__vip-map .map__outer.hidden::before {
  display: none;
}
.profileAccount__vip-map .map .vipp__roob {
  display: none;
}
.profileAccount__vip-map .map__black {
  display: none;
}
.profileAccount__vip-btn {
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 48px;
  width: 100%;
  background: #d37c30;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profileAccount__vip-btn:hover {
  opacity: 0.8;
}
.profileAccount__vip-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #fff;
}
.profileAccount__vip .vipp__roob {
  position: absolute;
  top: 168px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  padding: 22px 30px 25px 30px;
  border-radius: 14px;
  width: 100%;
  max-width: 372px;
}
.profileAccount__vip .vipp__roob h6 {
  font-size: 14.8889px;
  line-height: 18px;
  margin-bottom: 20px;
}
.profileAccount__vip .vipp__roob-lock {
  width: 12px;
  height: 16px;
  top: -9px;
}
.profileAccount__vip .vipp__roob-input {
  max-width: 222px;
  margin: 0 auto 14px auto;
}
.profileAccount__vip .vipp__roob-input input {
  font-size: 16px;
  line-height: 20px;
  padding: 8px 16px;
  border-radius: 7px;
}
.profileAccount__vip .vipp__roob-btn {
  width: 100%;
  max-width: 222px;
  height: 38px;
  border-radius: 7px;
  margin: 0 auto;
}
.profileAccount__vip .vipp__roob-btn p {
  font-weight: 600;
  font-size: 20.691px;
  line-height: 25px;
}
.profileAccount .vippProgress {
  margin: 0;
}
.profileAccount .vippRewards__head {
  border-radius: 20px 20px 0 0;
  z-index: 2;
  margin-bottom: 0;
}
.profileSec {
  margin-top: 70px;
}
.profileSec__btn {
  cursor: pointer;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
  background: #24224c;
  padding: 15px 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profileSec__btn:hover {
  opacity: 0.8;
}
.profileSec__btn h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
}
.profileSec__btn img {
  width: 20px;
  height: 20px;
  margin-right: 16px;
}
.profileSec__btn.active {
  background: #363270;
}
.profileSec__change {
  background: #3f3b80;
  border-radius: 6px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 18px 0 19px 0;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profileSec__change:hover {
  opacity: 0.8;
}
.profileSec__change p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 2px 4px rgba(31, 29, 71, 0.5);
}
.profileSec__password {
  width: 100%;
  max-width: 576px;
  margin-bottom: 22px;
}
.profileSec__password-content {
  background: #0a0a17;
  border: 1px solid #24224c;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 10px;
  max-width: 484px;
  width: 100%;
  margin: 12px auto 0 auto;
  padding: 20px 18px 28px 18px;
}
.profileSec__password-desc {
  margin-bottom: 24px;
}
.profileSec__password-desc p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
}
.profileSec__inputs {
  margin-bottom: 38px;
}
.profileSec__inputs .input__outer {
  margin-bottom: 10px;
}
.profileSec__inputs .input__outer:first-child {
  margin-bottom: 38px;
}
.profileSec__inputs .input__outer:last-child {
  margin-bottom: 0;
}
.profileSec__inputs .input__outer label {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  margin-bottom: 4px;
}
.profileSec__inputs .input input {
  background: #1f1d47;
  border: 1px solid #1f1d47;
  border-radius: 10px;
  padding: 8px 38px 8px 18px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
}
.profileSec__inputs .input button {
  width: 16px;
  height: 16px;
}
.profileSec__inputs .input.error input {
  border: 1px solid #b03749;
}
.profileSec__email {
  width: 100%;
  max-width: 576px;
  margin-bottom: 22px;
}
.profileSec__email-content {
  background: #0a0a17;
  border: 1px solid #24224c;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 10px;
  max-width: 484px;
  width: 100%;
  margin: 20px auto 0 auto;
  padding: 20px 18px 18px 18px;
}
.profileSec__confirm {
  width: 100%;
  max-width: 576px;
}
.profileSec__confirm-content {
  background: #0a0a17;
  border: 1px solid #24224c;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 10px;
  max-width: 484px;
  width: 100%;
  margin: 20px auto 0 auto;
  padding: 20px 18px 18px 18px;
}
.profileSec__confirm-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 4px;
}
.profileSec__confirm-title h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  margin-right: 6px;
}
.profileSec__confirm-title-verif {
  padding: 0 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 16px;
  border-radius: 5px;
  background: #37b06c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profileSec__confirm-title-verif p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 1;
  color: #0c0722;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profileSec__confirm-title-verif.error {
  background: #b03749;
}
.profileSec__confirm-title-verif.error p {
  color: #fff;
}
.profileSec__confirm .profileSec__inputs {
  margin-bottom: 0;
}
.profileSec__confirm .profileSec__inputs .input__outer {
  margin-bottom: 0;
}
.profileSec__confirm .profileSec__change {
  margin-top: 24px;
}
.profile__vip {
  margin-top: 65px;
}
.profile__vip-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.profile__vip-slider {
  max-width: 550px;
  padding-top: 72px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: static;
}
.profile__vip-slider-outer {
  padding: 32px 0 20px 35px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  background: #1f1d47;
  border-radius: 10px;
  min-height: 329px;
}
.profile__vip-slider-wrapper {
  overflow: hidden;
  position: relative;
}
.profile__vip-slider-wrapper-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, right top, left top, from(#0a0a17), color-stop(12.5%, rgba(10, 10, 23, 0)), color-stop(90.5%, rgba(10, 10, 23, 0)), to(#0a0a17));
  background: linear-gradient(270deg, #0a0a17 0%, rgba(10, 10, 23, 0) 12.5%, rgba(10, 10, 23, 0) 90.5%, #0a0a17 100%);
  z-index: 1;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profile__vip-slider-item {
  padding-top: 15px;
  height: 190px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #0a0a17;
  border-radius: 5px 5px 0 0;
  position: relative;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profile__vip-slider-item-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 95px;
  margin: 0 auto 12px auto;
}
.profile__vip-slider-item-image img {
  height: 100%;
}
.profile__vip-slider-item-icon {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 23px;
  height: 16px;
}
.profile__vip-slider-item-icon svg {
  width: 100%;
  height: 100%;
  color: #32ae70;
}
.profile__vip-slider-item-outer {
  margin: 0 5px;
  position: relative;
  z-index: 5;
}
.profile__vip-slider-item-claimed {
  background: #32ae70;
  height: 35px;
  width: 100%;
  cursor: pointer;
  border-radius: 0 0 5px 5px;
}
.profile__vip-slider-item-claimed p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #242352;
  text-align: center;
}
.profile__vip-slider-item-disabled {
  cursor: pointer;
  background: #8988bc;
  width: 100%;
  height: 35px;
  border-radius: 0 0 5px 5px;
}
.profile__vip-slider-item-disabled p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #424272;
}
.profile__vip-slider-item-disabled p b {
  font-weight: 900;
}
.profile__vip-slider-item-btn {
  height: 35px;
  width: 100%;
  background: none;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}
.profile__vip-slider-item-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #fff;
}
.profile__vip-slider-item-btn p b {
  font-weight: 900;
}
.profile__vip-slider-item-btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profile__vip-slider-item-btn span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  content: "";
  border-radius: 0 0 5px 5px;
}
.profile__vip-slider-item-btn span:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  content: "";
  border-radius: 0 0 5px 5px;
}
.profile__vip-slider-item-btn span.default::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#1f60ab), to(#1e5ca4));
  background: linear-gradient(180deg, #1f60ab 0%, #1e5ca4 100%);
}
.profile__vip-slider-item-btn span.default::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#3e91f2), to(#307bd3)), #307bd3;
  background: linear-gradient(180deg, #3e91f2 0%, #307bd3 100%), #307bd3;
}
.profile__vip-slider-item-btn span.hov {
  opacity: 0;
}
.profile__vip-slider-item-btn span.hov::before {
  -webkit-filter: blur(1px);
          filter: blur(1px);
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#b0f0ff));
  background: linear-gradient(180deg, #ffffff 0%, #b0f0ff 100%);
}
.profile__vip-slider-item-btn span.hov::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#3eb3f2), to(#3e91f2)), #307bd3;
  background: linear-gradient(180deg, #3eb3f2 0%, #3e91f2 100%), #307bd3;
}
.profile__vip-slider-item-btn span.active {
  opacity: 0;
}
.profile__vip-slider-item-btn span.active::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#b0f0ff));
  background: linear-gradient(180deg, #ffffff 0%, #b0f0ff 100%);
}
.profile__vip-slider-item-btn span.active::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#3eb3f2), to(#3e91f2)), #307bd3;
  background: linear-gradient(180deg, #3eb3f2 0%, #3e91f2 100%), #307bd3;
}
.profile__vip-slider-item-btn:hover span.hov {
  opacity: 1;
}
.profile__vip-slider-item-btn:focus span.hov {
  opacity: 0;
}
.profile__vip-slider-item-btn:focus span.active {
  opacity: 1;
}
.profile__vip-slider-item-arrow {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: -8px;
  width: 15px;
  height: 15px;
  background: #0a0a17;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profile__vip-slider-item-level {
  position: absolute;
  top: -64px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 26px;
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 2;
}
.profile__vip-slider-item-level::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 5px;
  background: #5e5c9e;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profile__vip-slider-item-level p {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  color: #c9c9f4;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profile__vip-slider-item-level.active::before {
  background: #3eaeff;
}
.profile__vip-slider-item-level.active p {
  color: #fff;
}
.profile__vip-slider-item-progress {
  width: calc(100% + 10px);
  position: absolute;
  left: -5px;
  top: -54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.profile__vip-slider-item-progress-line {
  width: 50%;
  height: 6px;
  background: #5e5c9e;
  position: relative;
}
.profile__vip-slider-item-progress-line span {
  position: absolute;
  left: 0;
  top: 0;
  height: 6px;
  background: #3eaeff;
}
.profile__vip-slider-item h5 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  margin-bottom: 8px;
}
.profile__vip-slider-item h6 {
  padding: 0 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #8988bc;
  text-align: center;
}
.profile__vip-slider-item h6 span {
  color: #c9c9f4;
}
.profile__vip-slider-item::before {
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: calc(100% + 2px);
  height: calc(100% + 1px);
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, color-stop(6.05%, #7977be), to(#313062));
  background: linear-gradient(180deg, #7977be 6.05%, #313062 100%);
  z-index: -1;
  border-radius: 5px 5px 0 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
}
.profile__vip-slider-item.claimed {
  background: #242352;
}
.profile__vip-slider-item.claimed h5 {
  opacity: 0.5;
}
.profile__vip-slider-item.claimed .profile__vip-slider-item-image {
  opacity: 0.5;
}
.profile__vip-slider-item.claimed .profile__vip-slider-item-arrow {
  background: #242352;
}
.profile__vip-slider-item.current {
  background: #2d2c61;
}
.profile__vip-slider-item.current::before {
  opacity: 1;
}
.profile__vip-slider-item.current .profile__vip-slider-item-arrow {
  background: #2d2c61;
  border-top: 1px solid #7977be;
  border-left: 1px solid #7977be;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.profile__vip-slider-tooltip {
  position: absolute;
  top: -48px;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  padding: 6px 8px;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.profile__vip-slider-tooltip-wrapper {
  width: calc(100% + 10px);
  position: absolute;
  left: -5px;
  top: -54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.profile__vip-slider-tooltip-outer {
  width: 50%;
  position: relative;
}
.profile__vip-slider-tooltip::before {
  position: absolute;
  bottom: -5px;
  left: 50%;
  content: "";
  width: 10px;
  height: 10px;
  background: #fff;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.profile__vip-slider-tooltip p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #3eaeff;
  text-shadow: 0px 1px 2px rgba(94, 92, 158, 0.3);
  white-space: nowrap;
}
.profile__vip-slider-tooltip p span {
  color: #19174f;
  text-shadow: 0px 1px 2px rgba(94, 92, 158, 0.3);
}
.profile__vip-slider .slick-list {
  overflow: unset;
}
.profile__vip-slider .slick-prev {
  transform: translate(0, 0);
  -moz-ransform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 16px;
  width: 31px;
  height: 95px;
  background: #545390;
  border-radius: 8px;
  overflow: hidden;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-box-shadow: 0px 2px 10px #0a0a17;
          box-shadow: 0px 2px 10px #0a0a17;
  z-index: 50;
}
.profile__vip-slider .slick-prev:hover {
  opacity: 0.8;
}
.profile__vip-slider .slick-prev.slick-disabled {
  opacity: 0.5;
}
.profile__vip-slider .slick-prev::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  opacity: 1;
  width: 9px;
  height: 16px;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: url("../assets/icons/chevronLeft3.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.profile__vip-slider .slick-next {
  transform: translate(0, 0);
  -moz-ransform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 16px;
  width: 31px;
  height: 95px;
  background: #545390;
  border-radius: 8px;
  overflow: hidden;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-box-shadow: 0px 2px 10px #0a0a17;
          box-shadow: 0px 2px 10px #0a0a17;
  z-index: 50;
}
.profile__vip-slider .slick-next:hover {
  opacity: 0.8;
}
.profile__vip-slider .slick-next.slick-disabled {
  opacity: 0.5;
}
.profile__vip-slider .slick-next::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  opacity: 1;
  width: 9px;
  height: 16px;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: url("../assets/icons/chevronRight3.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.profile__vip-inner {
  width: calc(100% - 300px);
  position: relative;
}
.profile__vip-desc {
  width: 276px;
  background: #0a0a17;
  border: 1px solid #24224c;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 27px 15px;
  border-radius: 10px;
}
.profile__vip-desc h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  margin-bottom: 32px;
}
.profile__vip-desc p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
}
.profile__vip-desc p span {
  font-weight: 700;
}
.profile__vip-desc p b {
  font-weight: 900;
}
.profile__vip .profileAccount__link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  max-width: 576px;
  z-index: 52;
  background: #0a0a17;
  border: 1px solid #24224c;
  padding: 16px 24px 24px 24px;
  margin-bottom: 0;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.profile__vip-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(12, 7, 34, 0.8);
  border-radius: 0 4px 4px 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 51;
}
.profile__vip-overlay-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  max-width: 196px;
  height: 28px;
  background: #307bd3;
  border-radius: 5px;
  border: 1px solid #1f60ab;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.profile__vip-overlay-btn:hover {
  opacity: 0.8;
}
.profile__vip-overlay-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.05em;
  color: #fff;
}
.profile__vip-rewards {
  margin-top: 24px;
  background: #0a0a17;
  border: 1px solid #24224c;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding: 40px 17px 40px 25px;
}
.profile__vip-rewards-title {
  margin-bottom: 58px;
}
.profile__vip-rewards-title h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #fff;
}
.profile__vip-rewards-items {
  padding-left: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.profile__vip-rewards-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  -webkit-box-shadow: 0px 3px 1px rgba(44, 40, 85, 0.5);
          box-shadow: 0px 3px 1px rgba(44, 40, 85, 0.5);
  border-radius: 20px;
  margin-bottom: 50px;
}
.profile__vip-rewards-item:last-child {
  margin-bottom: 0;
}
.profile__vip-rewards-item-rank {
  position: absolute;
  left: 0;
  top: 0;
}
.profile__vip-rewards-item-icon {
  position: absolute;
  top: 50%;
  left: calc(50% + 3px);
  width: 27px;
  height: 25px;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.profile__vip-rewards-item-icon img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.profile__vip-rewards-item-info {
  width: calc(50% - 1px);
  background: #1f1d47;
  border-radius: 20px 0 0 20px;
  padding: 5px 0 5px 78px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.profile__vip-rewards-item-info p {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #bdbaef;
}
.profile__vip-rewards-item-price {
  width: calc(50% - 1px);
  background: #307bd3;
  border-radius: 0 20px 20px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 7px 30px 7px 0;
}
.profile__vip-rewards-item-price p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  text-align: right;
  color: #fff;
  text-shadow: 0px 1px 4px #3e337e;
}
.profile__vip-rewards-item-rank {
  position: absolute;
  left: -31px;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 83px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.profile__vip-rewards-item-rank img {
  max-width: 100%;
}

.map {
  width: 2096px;
  height: 5413.85px;
  margin-right: -396px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.map__outer {
  overflow: hidden;
  height: 4126.85px;
  padding-top: 191px;
  position: relative;
  margin-top: calc(-100vh + 140px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.map__outer::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(10, 10, 23, 0.5);
  z-index: -10;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.map__outer.hidden {
  height: calc(100vh - 66px);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.map__outer.hidden::before {
  opacity: 1;
  z-index: 9;
}
.map__black {
  position: absolute;
  top: -191px;
  right: -50%;
  height: calc(100% + 191px);
  width: 100%;
  background: rgba(10, 10, 23, 0.5);
  z-index: -1;
}
.map__inner {
  position: relative;
}
.mapItem {
  position: absolute;
  cursor: pointer;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.mapItem__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  -webkit-animation: fadeIn 0.3s linear;
          animation: fadeIn 0.3s linear;
  pointer-events: none;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
.mapItem__image img {
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.mapItem__reward {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-animation: fadeIn 0.3s linear;
          animation: fadeIn 0.3s linear;
}
.mapItem__reward h5 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: #fff;
  text-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.5);
}
.mapItem__reward img {
  width: 16.06px;
  height: 27.53px;
  -webkit-transform: rotate(28.04deg);
          transform: rotate(28.04deg);
  margin-left: 6px;
}
.mapItem__lock {
  display: none;
  -webkit-animation: fadeIn 0.3s linear;
          animation: fadeIn 0.3s linear;
  position: absolute;
  right: 0;
  top: -10px;
  width: 20px;
  height: 27px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.mapItem__lock svg {
  width: 100%;
  height: 100%;
  color: #fff;
}
.mapItem__content {
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-animation: fadeIn 0.3s linear;
          animation: fadeIn 0.3s linear;
}
.mapItem__content-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 20px;
}
.mapItem__content-image img {
  width: 100%;
  height: 100%;
}
.mapItem__content-body {
  display: none;
  -webkit-animation: fadeIn 0.3s linear;
          animation: fadeIn 0.3s linear;
}
.mapItem__content-body h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #fff;
  text-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.5);
  text-align: center;
}
.mapItem__content-body p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #96c6e5;
  text-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.5);
  text-align: center;
}
.mapItem__claim {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.mapItem__claim svg {
  width: 30px;
  height: 23px;
  color: #fff;
}
.mapItem__claim p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.01em;
  color: #d9ff42;
  text-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.5);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.mapItem.step-0 {
  padding-top: 45px;
  min-width: 120px;
  height: 90px;
  left: calc(50% - 50px);
  top: 98px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(74, 183, 255, 0.25)), to(rgba(74, 183, 255, 0.5)));
  background: linear-gradient(180deg, rgba(74, 183, 255, 0.25) 0%, rgba(74, 183, 255, 0.5) 100%);
  border: 1.49075px solid rgba(155, 215, 255, 0.5);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 29.82px;
}
.mapItem.step-0 .mapItem__image {
  top: -40px;
  width: 73px;
  height: 76px;
}
.mapItem.step-1 {
  padding-top: 45px;
  min-width: 120px;
  height: 90px;
  left: calc(50% - 180px);
  top: 349px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(74, 183, 255, 0.25)), to(rgba(74, 183, 255, 0.5)));
  background: linear-gradient(180deg, rgba(74, 183, 255, 0.25) 0%, rgba(74, 183, 255, 0.5) 100%);
  border: 1.49075px solid rgba(155, 215, 255, 0.5);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 29.82px;
}
.mapItem.step-1 .mapItem__image {
  top: -40px;
  width: 73px;
  height: 76px;
}
.mapItem.step-2 {
  padding-top: 45px;
  min-width: 120px;
  height: 90px;
  left: calc(50% - 80px);
  top: 513px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(74, 183, 255, 0.25)), to(rgba(74, 183, 255, 0.5)));
  background: linear-gradient(180deg, rgba(74, 183, 255, 0.25) 0%, rgba(74, 183, 255, 0.5) 100%);
  border: 1.49075px solid rgba(155, 215, 255, 0.5);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 29.82px;
}
.mapItem.step-2 .mapItem__image {
  top: -40px;
  width: 62px;
  height: 80px;
}
.mapItem.step-3 {
  padding-top: 50px;
  min-width: 120px;
  height: 90px;
  left: calc(50% - 45px);
  top: 745px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 85, 255, 0.25)), to(rgba(0, 85, 255, 0.5)));
  background: linear-gradient(180deg, rgba(0, 85, 255, 0.25) 0%, rgba(0, 85, 255, 0.5) 100%);
  border: 1.49075px solid rgba(157, 189, 255, 0.5);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 29.8151px;
}
.mapItem.step-3 .mapItem__image {
  top: -42px;
  width: 59px;
  height: 87px;
}
.mapItem.step-4 {
  padding-top: 50px;
  min-width: 144px;
  height: 91px;
  left: calc(50% + 8px);
  top: 1012px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(89, 0, 255, 0.25)), to(rgba(89, 0, 255, 0.5)));
  background: linear-gradient(180deg, rgba(89, 0, 255, 0.25) 0%, rgba(89, 0, 255, 0.5) 100%);
  border: 1.49075px solid rgba(176, 133, 255, 0.5);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 29.8151px;
}
.mapItem.step-4 .mapItem__image {
  top: -53px;
  width: 52px;
  height: 92px;
}
.mapItem.step-5 {
  padding-top: 58px;
  min-width: 144px;
  height: 109px;
  right: calc(50% - 270px);
  top: 1292.5px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(157, 0, 255, 0.25)), to(rgba(157, 0, 255, 0.5)));
  background: linear-gradient(180deg, rgba(157, 0, 255, 0.25) 0%, rgba(157, 0, 255, 0.5) 100%);
  border: 1.49075px solid rgba(224, 173, 255, 0.5);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 29.8151px;
}
.mapItem.step-5 .mapItem__image {
  top: -26px;
  width: 68px;
  height: 85px;
}
.mapItem.step-6 {
  padding-top: 58px;
  min-width: 144px;
  height: 109px;
  left: calc(50% - 278px);
  top: 1425px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(157, 0, 255, 0.25)), to(rgba(157, 0, 255, 0.5)));
  background: linear-gradient(180deg, rgba(157, 0, 255, 0.25) 0%, rgba(157, 0, 255, 0.5) 100%);
  border: 1.49075px solid rgba(224, 173, 255, 0.5);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 29.8151px;
}
.mapItem.step-6 .mapItem__image {
  top: -48px;
  width: 67px;
  height: 102px;
}
.mapItem.step-7 {
  padding-top: 60px;
  min-width: 144px;
  height: 109px;
  right: calc(50% - 250px);
  top: 1588px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(157, 0, 255, 0.25)), to(rgba(157, 0, 255, 0.5)));
  background: linear-gradient(180deg, rgba(157, 0, 255, 0.25) 0%, rgba(157, 0, 255, 0.5) 100%);
  border: 1.49075px solid rgba(224, 173, 255, 0.5);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 29.8151px;
}
.mapItem.step-7 .mapItem__image {
  top: -42px;
  width: 81px;
  height: 104px;
}
.mapItem.step-8 {
  padding-top: 60px;
  min-width: 144px;
  height: 109px;
  left: calc(50% - 256px);
  top: 1816px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(157, 0, 255, 0.25)), to(rgba(157, 0, 255, 0.5)));
  background: linear-gradient(180deg, rgba(157, 0, 255, 0.25) 0%, rgba(157, 0, 255, 0.5) 100%);
  border: 1.49075px solid rgba(224, 173, 255, 0.5);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 29.8151px;
}
.mapItem.step-8 .mapItem__image {
  top: -42px;
  width: 80px;
  height: 103px;
}
.mapItem.step-9 {
  padding-top: 63px;
  min-width: 144px;
  height: 109px;
  left: calc(50% - 108px);
  top: 2173.5px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 0, 208, 0.25)), to(rgba(255, 0, 208, 0.5)));
  background: linear-gradient(180deg, rgba(255, 0, 208, 0.25) 0%, rgba(255, 0, 208, 0.5) 100%);
  border: 1.49075px solid rgba(255, 205, 246, 0.5);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 29.8151px;
}
.mapItem.step-9 .mapItem__image {
  top: -37px;
  width: 92px;
  height: 105px;
}
.mapItem.step-10 {
  padding-top: 63px;
  min-width: 144px;
  height: 109px;
  left: calc(50% - 172px);
  top: 2542px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 0, 208, 0.25)), to(rgba(255, 0, 208, 0.5)));
  background: linear-gradient(180deg, rgba(255, 0, 208, 0.25) 0%, rgba(255, 0, 208, 0.5) 100%);
  border: 1.49075px solid rgba(255, 205, 246, 0.5);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 29.8151px;
}
.mapItem.step-10 .mapItem__image {
  top: -37px;
  width: 93px;
  height: 99px;
}
.mapItem.step-11 {
  padding-top: 72px;
  min-width: 167px;
  height: 109px;
  right: calc(50% - 300px);
  top: 2652px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 0, 153, 0.25)), to(rgba(255, 0, 153, 0.5)));
  background: linear-gradient(180deg, rgba(255, 0, 153, 0.25) 0%, rgba(255, 0, 153, 0.5) 100%);
  border: 1.49075px solid rgba(255, 146, 148, 0.7);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 29.8151px;
}
.mapItem.step-11 .mapItem__image {
  top: -50px;
  width: 115px;
  height: 117px;
}
.mapItem.step-12 {
  padding-top: 90px;
  min-width: 216px;
  height: 140px;
  right: calc(50% - 154px);
  top: 3295px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 98, 0, 0.25)), to(rgba(255, 98, 0, 0.5)));
  background: linear-gradient(180deg, rgba(255, 98, 0, 0.25) 0%, rgba(255, 98, 0, 0.5) 100%);
  border: 1.49075px solid #ffffff;
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(247, 210, 90, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(247, 210, 90, 0.25);
  border-radius: 29.8151px;
}
.mapItem.step-12 .mapItem__image {
  top: -70px;
  width: 150px;
  height: 159px;
}
.mapItem.step-12 .mapItem__reward h5 {
  font-size: 29.8151px;
}
.mapItem:hover {
  z-index: 2;
  height: unset;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
}
.mapItem:hover .mapItem__lock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mapItem:hover .mapItem__reward {
  display: none;
}
.mapItem:hover .mapItem__content-body {
  display: block;
}
.mapItem:hover.step-0 .mapItem__content-image {
  width: 73px;
  height: 76px;
}
.mapItem:hover.step-1 .mapItem__content-image {
  width: 73px;
  height: 76px;
}
.mapItem:hover.step-2 .mapItem__content-image {
  width: 62px;
  height: 80px;
}
.mapItem:hover.step-3 .mapItem__content-image {
  width: 59px;
  height: 87px;
}
.mapItem:hover.step-4 .mapItem__content-image {
  width: 52px;
  height: 92px;
}
.mapItem:hover.step-5 .mapItem__content-image {
  width: 68px;
  height: 85px;
}
.mapItem:hover.step-6 .mapItem__content-image {
  width: 67px;
  height: 102px;
}
.mapItem:hover.step-7 .mapItem__content-image {
  width: 81px;
  height: 104px;
}
.mapItem:hover.step-8 .mapItem__content-image {
  width: 80px;
  height: 103px;
}
.mapItem:hover.step-9 .mapItem__content-image {
  width: 92px;
  height: 105px;
}
.mapItem:hover.step-10 .mapItem__content-image {
  width: 93px;
  height: 99px;
}
.mapItem:hover.step-11 .mapItem__content-image {
  width: 115px;
  height: 117px;
}
.mapItem:hover.step-12 .mapItem__content-image {
  width: 150px;
  height: 159px;
}
.mapItem.claim {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(41, 193, 41, 0.25)), to(rgba(41, 193, 41, 0.5)));
  background: linear-gradient(180deg, rgba(41, 193, 41, 0.25) 0%, rgba(41, 193, 41, 0.5) 100%);
  border: 1.49075px solid rgba(41, 193, 41, 0.5);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 20px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.mapItem.claim:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(41, 193, 41, 0.25)), to(rgba(132, 255, 61, 0.5)));
  background: linear-gradient(180deg, rgba(41, 193, 41, 0.25) 0%, rgba(132, 255, 61, 0.5) 100%);
  border: 1.49075px solid #7bff00;
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 20px;
}
.mapItem.claim:hover .mapItem__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mapItem.claim:hover .mapItem__image img {
  scale: 1.05;
}
.mapItem.claim:hover .mapItem__claim p {
  font-size: 30px;
  color: #fff;
}
.mapItem.claim:hover.step-0 {
  padding: 45px 0 0 0;
  min-width: 136px;
  height: 98px;
}
.mapItem.claim:hover.step-1 {
  padding: 45px 0 0 0;
  min-width: 136px;
  height: 98px;
}
.mapItem.claim:hover.step-2 {
  padding: 45px 0 0 0;
  min-width: 136px;
  height: 98px;
}
.mapItem.claim:hover.step-3 {
  padding: 50px 0 0 0;
  min-width: 136px;
  height: 98px;
}
.mapItem.claim:hover.step-4 {
  padding: 50px 0 0 0;
  min-width: 160px;
  height: 99px;
}
.mapItem.claim:hover.step-5 {
  padding: 58px 0 0 0;
  min-width: 160px;
  height: 117px;
}
.mapItem.claim:hover.step-6 {
  padding: 58px 0 0 0;
  min-width: 160px;
  height: 117px;
}
.mapItem.claim:hover.step-7 {
  padding: 60px 0 0 0;
  min-width: 160px;
  height: 117px;
}
.mapItem.claim:hover.step-8 {
  padding: 60px 0 0 0;
  min-width: 160px;
  height: 117px;
}
.mapItem.claim:hover.step-9 {
  padding: 63px 0 0 0;
  min-width: 160px;
  height: 117px;
}
.mapItem.claim:hover.step-10 {
  padding: 63px 0 0 0;
  min-width: 160px;
  height: 117px;
}
.mapItem.claim:hover.step-11 {
  padding: 72px 0 0 0;
  min-width: 183px;
  height: 117px;
}
.mapItem.claim:hover.step-12 {
  padding: 90px 0 0 0;
  min-width: 232px;
  height: 148px;
}
.mapShard {
  position: absolute;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.mapShard__lock {
  display: none;
  -webkit-animation: fadeIn 0.3s linear;
          animation: fadeIn 0.3s linear;
  position: absolute;
  right: 0;
  top: -10px;
  width: 20px;
  height: 27px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.mapShard__lock svg {
  width: 100%;
  height: 100%;
  color: #fff;
}
.mapShard__image {
  width: 38.71px;
  height: 66.35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-animation: fadeIn 0.3s linear;
          animation: fadeIn 0.3s linear;
}
.mapShard__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.mapShard__image2 {
  width: 38.71px;
  height: 66.35px;
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-animation: fadeIn 0.3s linear;
          animation: fadeIn 0.3s linear;
}
.mapShard__image2 img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.mapShard__claimed {
  width: 38.71px;
  height: 66.35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.mapShard__claimed img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.mapShard__content {
  display: none;
  -webkit-animation: fadeIn 0.3s linear;
          animation: fadeIn 0.3s linear;
  margin-left: 14px;
}
.mapShard__content-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px;
}
.mapShard__content-top h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: #fff;
  text-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.5);
}
.mapShard__content-shards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 33px;
  padding: 0 28px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 auto;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(74, 183, 255, 0.25)), to(rgba(74, 183, 255, 0.5)));
  background: linear-gradient(180deg, rgba(74, 183, 255, 0.25) 0%, rgba(74, 183, 255, 0.5) 100%);
  border-radius: 29.8151px;
}
.mapShard__content-shards p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: #fff;
  text-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.5);
}
.mapShard__content-shards img {
  width: 16.89px;
  height: 28.95px;
  -webkit-transform: rotate(25.87deg);
          transform: rotate(25.87deg);
  margin-left: 5px;
}
.mapShard__content-wager p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.01em;
  color: #fff;
  text-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.5);
}
.mapShard__content-wager p span {
  color: #96c6e5;
}
.mapShard__content-claim p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.5);
}
.mapShard.step-1a {
  top: 399px;
  left: calc(50% - 10px);
}
.mapShard.step-2a {
  top: 662px;
  left: calc(50% - 112px);
}
.mapShard.step-3a {
  top: 873px;
  left: calc(50% - 142px);
}
.mapShard.step-3b {
  top: 945px;
  right: calc(50% - 182px);
}
.mapShard.step-4a {
  top: 1140px;
  left: calc(50% - 226px);
}
.mapShard.step-4b {
  top: 1235px;
  left: calc(50% - 80px);
}
.mapShard.step-5a {
  top: 1427px;
  left: calc(50% - 47px);
}
.mapShard.step-6a {
  top: 1483px;
  right: calc(50% - 196px);
}
.mapShard.step-7a {
  top: 1749px;
  right: calc(50% - 126px);
}
.mapShard.step-8a {
  top: 1982px;
  right: calc(50% - 33px);
}
.mapShard.step-8b {
  top: 2076px;
  left: calc(50% - 165px);
}
.mapShard.step-9a {
  top: 2353px;
  left: calc(50% - 160px);
}
.mapShard.step-9b {
  top: 2460px;
  right: calc(50% - 40px);
}
.mapShard.step-10a {
  top: 2833px;
  right: calc(50% - 188px);
}
.mapShard.step-10b {
  top: 3019px;
  right: calc(50% - 56px);
}
.mapShard.step-10c {
  top: 3123px;
  left: calc(50% - 165px);
}
.mapShard:hover {
  z-index: 2;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 12px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(74, 183, 255, 0.25)), to(rgba(74, 183, 255, 0.5)));
  background: linear-gradient(180deg, rgba(74, 183, 255, 0.25) 0%, rgba(74, 183, 255, 0.5) 100%);
  border: 1.49075px solid rgba(155, 215, 255, 0.5);
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 20px;
}
.mapShard:hover .mapShard__lock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mapShard:hover .mapShard__image {
  display: none;
}
.mapShard:hover .mapShard__image2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mapShard:hover .mapShard__content {
  display: block;
}
.mapShard.claim:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(41, 193, 41, 0.25)), to(rgba(132, 255, 61, 0.5)));
  background: linear-gradient(180deg, rgba(41, 193, 41, 0.25) 0%, rgba(132, 255, 61, 0.5) 100%);
  border: 1.49075px solid #7bff00;
  -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
          box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  border-radius: 20px;
}
.mapShard.claim:hover .mapShard__content-shards {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(41, 193, 41, 0.25)), to(rgba(132, 255, 61, 0.5)));
  background: linear-gradient(180deg, rgba(41, 193, 41, 0.25) 0%, rgba(132, 255, 61, 0.5) 100%);
  border-radius: 29.8151px;
}
.mapShard.claimed:hover {
  padding: 0;
  background: none;
  border: unset;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  border-radius: unset;
}

.vipp {
  position: relative;
}
.vipp.hidden .vipp__bg {
  background: -webkit-gradient(linear, left top, left bottom, from(#031627), to(#002641)), #0a0a17;
  background: linear-gradient(180deg, #031627 0%, #002641 100%), #0a0a17;
}
.vipp.hidden .vipp__rew {
  height: 100%;
}
.vipp.hidden .vippMap::after {
  opacity: 1;
  z-index: 10;
}
.vipp__bg {
  background: -webkit-gradient(linear, left top, left bottom, from(#031627), color-stop(18.19%, #002641), color-stop(30.69%, #000349), color-stop(43%, #150152), color-stop(60%, #450041), color-stop(81.6%, #722800), color-stop(86.87%, #722800)), #0a0a17;
  background: linear-gradient(180deg, #031627 0%, #002641 18.19%, #000349 30.69%, #150152 43%, #450041 60%, #722800 81.6%, #722800 86.87%), #0a0a17;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.vipp__bg::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: -webkit-gradient(linear, left top, right top, from(#0a0a17), color-stop(30.36%, rgba(10, 10, 23, 0.499468)), color-stop(53.34%, rgba(10, 10, 23, 0)), color-stop(78.13%, rgba(10, 10, 23, 0)), color-stop(99.79%, #0a0a17));
  background: linear-gradient(90deg, #0a0a17 0%, rgba(10, 10, 23, 0.499468) 30.36%, rgba(10, 10, 23, 0) 53.34%, rgba(10, 10, 23, 0) 78.13%, #0a0a17 99.79%);
}
.vipp__black {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 574px;
  background: rgba(10, 10, 23, 0.5);
  z-index: -1;
}
.vipp__timer {
  position: absolute;
  top: 35px;
  right: 100px;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  z-index: 1;
}
.vipp__timer p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16.8932px;
  line-height: 16px;
  color: #fff;
  white-space: nowrap;
  margin-right: 9px;
}
.vipp__timer-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.vipp__timer-item {
  width: 52px;
  height: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 3.67px;
  background: #fff;
  margin-right: 4px;
}
.vipp__timer-item:last-child {
  margin-right: 0;
}
.vipp__timer-item h6 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 13.40861px;
  line-height: 10px;
  color: #100f2d;
}
.vipp__timer-item h6 span {
  font-weight: 800;
  font-size: 16.8932px;
  line-height: 16px;
}
.vippRewards {
  width: 432px;
  position: sticky;
  height: calc(100vh - 140px);
  top: 101px;
  left: calc(50% - 328.5px);
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  z-index: 10;
}
.vippRewards__head {
  width: 100%;
  height: 112px;
  padding: 15px 45px 15px 20px;
  margin-bottom: 17px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.vippRewards__head::before {
  position: absolute;
  top: -22px;
  left: -29px;
  width: 185px;
  height: 168px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(60, 0, 0, 0.5)), to(#3c0000));
  background: linear-gradient(180deg, rgba(60, 0, 0, 0.5) 0%, #3c0000 100%);
  mix-blend-mode: overlay;
  border: 0.786207px solid #7c353d;
  -webkit-box-shadow: 0px 1.57241px 3.14483px rgba(247, 210, 90, 0.25);
          box-shadow: 0px 1.57241px 3.14483px rgba(247, 210, 90, 0.25);
  border-radius: 15.7241px;
  content: "";
}
.vippRewards__head-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: -1;
}
.vippRewards__head-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.vippRewards__head-rank {
  position: absolute;
  bottom: -22px;
  right: -27px;
  width: 116.18px;
  height: 122.93px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 2;
}
.vippRewards__head-rank img {
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(19.36deg);
          transform: rotate(19.36deg);
}
.vippRewards__head-content {
  max-width: 126px;
}
.vippRewards__head-content-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 126px;
}
.vippRewards__head-content-image img {
  width: 100%;
}
.vippRewards__head-content h6 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.15em;
  color: #fff;
  text-shadow: 0px 2.75148px 4.81509px rgba(49, 0, 0, 0.25);
  text-align: center;
  margin-top: -10px;
}
.vippRewards__head-prize {
  max-width: 194px;
  width: 100%;
  padding: 5px 56px 5px 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 98, 0, 0.125)), to(rgba(255, 98, 0, 0.25)));
  background: linear-gradient(180deg, rgba(255, 98, 0, 0.125) 0%, rgba(255, 98, 0, 0.25) 100%);
  mix-blend-mode: plus-lighter;
  border: 0.786207px solid #7c353d;
  -webkit-box-shadow: 0px 1.57241px 3.14483px rgba(247, 210, 90, 0.25);
          box-shadow: 0px 1.57241px 3.14483px rgba(247, 210, 90, 0.25);
  border-radius: 15.7241px;
}
.vippRewards__head-prize h6 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #fff;
  text-align: center;
}
.vippRewards__head-prize h5 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #fced83;
  text-align: center;
  text-shadow: 0px 1.57241px 4.71724px rgba(97, 15, 0, 0.5);
}
.vippRewards__body {
  height: calc(100% - 129px);
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background: rgba(1, 20, 32, 0.5);
  border: 1px solid #2e2d57;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 10px;
}
.vippRewards__body::-webkit-scrollbar {
  display: none;
}
.vippRewards__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #1f1d47;
  border-radius: 10px 10px 0 0;
  padding: 6px 18px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.vippRewards__btn {
  cursor: pointer;
  padding: 9px 0;
  width: 61px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(19, 17, 45, 0.16)), to(rgba(31, 29, 71, 0.4)));
  background: linear-gradient(180deg, rgba(19, 17, 45, 0.16) 0%, rgba(31, 29, 71, 0.4) 100%);
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
}
.vippRewards__btn:last-child {
  margin-right: 0;
}
.vippRewards__btn::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  border-radius: 6px;
  background: #3f3b80;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.vippRewards__btn p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #bdbaef;
  text-shadow: 0px 2px 4px rgba(31, 29, 71, 0.5);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.vippRewards__btn:hover p {
  color: #fff;
}
.vippRewards__btn:hover::before {
  opacity: 1;
}
.vippRewards__btn.active p {
  color: #fff;
}
.vippRewards__btn.active::before {
  opacity: 1;
}
.vipp__rew {
  height: calc(100% - 43px);
  padding: 47px 22px 25px 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.vipp__rew-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 29px;
}
.vipp__rew-title h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
}
.vipp__rew-title-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 29px;
  height: 29px;
  background: #307bd3;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.vipp__rew-title-icon:hover {
  opacity: 0.8;
}
.vipp__rew-title-icon svg {
  width: 16px;
  height: 16px;
  color: #fff;
}
.vipp__rew-content {
  height: calc(100% - 58px);
}
.vipp__rew-head {
  padding-left: 50px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.vipp__rew-head-info {
  padding: 0 0 0 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 60%;
}
.vipp__rew-head-info h6 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
}
.vipp__rew-head-price {
  width: 40%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 14px 0 0;
}
.vipp__rew-head-price h6 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  text-align: right;
}
.vipp__rew-items {
  padding-left: 50px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: calc(100% - 25px);
  overflow-y: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.vipp__rew-items::-webkit-scrollbar {
  display: none;
}
.vipp__rew-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  border-radius: 20px;
  margin-bottom: 20px;
}
.vipp__rew-item:last-child {
  margin-bottom: 0;
}
.vipp__rew-item-rank {
  position: absolute;
  left: 0;
  top: 0;
}
.vipp__rew-item-icon {
  position: absolute;
  top: 50%;
  left: 60%;
  width: 17.4px;
  height: 20.02px;
  transform: translate(-50%, -50%);
  -moz-ransform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.vipp__rew-item-icon svg {
  width: 100%;
  height: 100%;
}
.vipp__rew-item-info {
  width: 60%;
  background: #1f1d47;
  border-radius: 20px 0 0 20px;
  padding: 0 0 0 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.vipp__rew-item-info p {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14.9665px;
  line-height: 18px;
  color: #bdbaef;
}
.vipp__rew-item-price {
  width: 40%;
  background: #307bd3;
  border-radius: 0 20px 20px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 14px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: 33px;
}
.vipp__rew-item-price img {
  width: 14.62px;
  height: 25.06px;
  -webkit-transform: rotate(28.04deg);
          transform: rotate(28.04deg);
  margin-right: 5px;
}
.vipp__rew-item-price p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #fff;
  text-shadow: 0px 0.831472px 3.32589px #3e337e;
}
.vipp__rew-item-rank {
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.vipp__rew-item-rank img {
  max-width: 100%;
}
.vipp__rew-item.step-0 .vipp__rew-item-rank img {
  max-width: 29.1px;
}
.vipp__rew-item.step-0 .vipp__rew-item-icon svg {
  color: #457a94;
}
.vipp__rew-item.step-0 .vipp__rew-item-price {
  background: #457a94;
}
.vipp__rew-item.step-1 .vipp__rew-item-rank img {
  max-width: 29.1px;
}
.vipp__rew-item.step-1 .vipp__rew-item-icon svg {
  color: #4088bb;
}
.vipp__rew-item.step-1 .vipp__rew-item-price {
  background: #4088bb;
}
.vipp__rew-item.step-2 .vipp__rew-item-rank img {
  max-width: 29.1px;
}
.vipp__rew-item.step-2 .vipp__rew-item-icon svg {
  color: #338ac8;
}
.vipp__rew-item.step-2 .vipp__rew-item-price {
  background: #338ac8;
}
.vipp__rew-item.step-3 .vipp__rew-item-rank img {
  max-width: 24.94px;
}
.vipp__rew-item.step-3 .vipp__rew-item-icon svg {
  color: #2a71e3;
}
.vipp__rew-item.step-3 .vipp__rew-item-price {
  background: #2a71e3;
}
.vipp__rew-item.step-4 .vipp__rew-item-rank img {
  max-width: 24.94px;
}
.vipp__rew-item.step-4 .vipp__rew-item-icon svg {
  color: #6a5bdc;
}
.vipp__rew-item.step-4 .vipp__rew-item-price {
  background: #6a5bdc;
}
.vipp__rew-item.step-5 .vipp__rew-item-rank img {
  max-width: 34.09px;
}
.vipp__rew-item.step-5 .vipp__rew-item-icon svg {
  color: #7a50f8;
}
.vipp__rew-item.step-5 .vipp__rew-item-price {
  background: #7a50f8;
}
.vipp__rew-item.step-6 .vipp__rew-item-rank img {
  max-width: 32.43px;
}
.vipp__rew-item.step-6 .vipp__rew-item-icon svg {
  color: #9244ff;
}
.vipp__rew-item.step-6 .vipp__rew-item-price {
  background: #9244ff;
}
.vipp__rew-item.step-7 .vipp__rew-item-rank img {
  max-width: 37.42px;
}
.vipp__rew-item.step-7 .vipp__rew-item-icon svg {
  color: #ae44ff;
}
.vipp__rew-item.step-7 .vipp__rew-item-price {
  background: #ae44ff;
}
.vipp__rew-item.step-8 .vipp__rew-item-rank img {
  max-width: 38.25px;
}
.vipp__rew-item.step-8 .vipp__rew-item-icon svg {
  color: #be4eb9;
}
.vipp__rew-item.step-8 .vipp__rew-item-price {
  background: #be4eb9;
}
.vipp__rew-item.step-9 .vipp__rew-item-rank img {
  max-width: 41.57px;
}
.vipp__rew-item.step-9 .vipp__rew-item-icon svg {
  color: #c2448b;
}
.vipp__rew-item.step-9 .vipp__rew-item-price {
  background: #c2448b;
}
.vipp__rew-item.step-10 .vipp__rew-item-rank img {
  max-width: 44.9px;
}
.vipp__rew-item.step-10 .vipp__rew-item-icon svg {
  color: #c2448b;
}
.vipp__rew-item.step-10 .vipp__rew-item-price {
  background: #c2448b;
}
.vipp__rew-item.step-11 .vipp__rew-item-rank img {
  max-width: 47.39px;
}
.vipp__rew-item.step-11 .vipp__rew-item-icon svg {
  color: #cb6948;
}
.vipp__rew-item.step-11 .vipp__rew-item-price {
  background: #cb6948;
}
.vipp__rew-item.step-12 .vipp__rew-item-rank img {
  max-width: 50px;
}
.vipp__rew-item.step-12 .vipp__rew-item-icon svg {
  color: #ed8e12;
}
.vipp__rew-item.step-12 .vipp__rew-item-price {
  background: #ed8e12;
}
.vipp__rew-rows {
  height: calc(100% - 58px);
  overflow-y: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.vipp__rew-rows::-webkit-scrollbar {
  display: none;
}
.vipp__rew-row {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 15px;
}
.vipp__rew-row:last-child {
  margin-bottom: 0;
}
.vipp__rew-row-content {
  width: calc(100% - 131px);
  position: relative;
  background: #1f1d47;
  border-radius: 20px 0 0 20px;
  height: 31px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 17px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.vipp__rew-row-content.claim {
  background: #29c129;
}
.vipp__rew-row-content svg {
  width: 15px;
  height: 11px;
  color: #fff;
  margin-left: 24px;
}
.vipp__rew-row-content p {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  color: #ecebff;
}
.vipp__rew-row-content p span {
  font-weight: 600;
  color: #bdbaef;
}
.vipp__rew-row-icon {
  position: absolute;
  width: 35px;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.vipp__rew-row-icon.shard {
  width: 20px;
  right: -12px;
}
.vipp__rew-row-icon img {
  width: 100%;
}
.vipp__rew-row-btn {
  cursor: pointer;
  width: 100px;
  background: #3a3870;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #3a3870;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 31px;
}
.vipp__rew-row-btn.claim {
  border: 1px solid #29c129;
}
.vipp__rew-row-btn.claimed {
  border: 1px solid #29c129;
}
.vipp__rew-row-btn.claimed p {
  color: #fff;
}
.vipp__rew-row-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #bdbaef;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.vipp__rew-row-btn img {
  width: 10.77px;
  height: 18.47px;
  -webkit-transform: rotate(28.04deg);
          transform: rotate(28.04deg);
  margin-left: 4px;
}
.vipp__rew-row-btn:hover p {
  color: #fff;
}
.vipp__register {
  z-index: 15;
  padding: 28px 30px 26px 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: rgba(1, 20, 32, 0.5);
  border: 1px solid #307bd3;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: calc(50% + 30px);
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
.vipp__register h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  margin-bottom: 6px;
}
.vipp__register-lock {
  width: 16px;
  height: 21px;
  position: absolute;
  left: 50%;
  top: -12px;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.vipp__register-lock svg {
  width: 100%;
  height: 100%;
  color: #fff;
}
.vipp__register-btn {
  width: 300px;
  height: 52px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #307bd3;
  border-radius: 9.26462px;
  border: 1px solid #1f60ab;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.vipp__register-btn:hover {
  opacity: 0.8;
}
.vipp__register-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 27.7939px;
  line-height: 34px;
  text-align: center;
  color: #fff;
}
.vipp__roob {
  z-index: 15;
  padding: 20px 30px 17px 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: rgba(1, 20, 32, 0.5);
  border: 1px solid #d37c30;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: calc(50% + 30px);
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
.vipp__roob h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  margin-bottom: 24px;
}
.vipp__roob-lock {
  width: 16px;
  height: 21px;
  position: absolute;
  left: 50%;
  top: -12px;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.vipp__roob-lock svg {
  width: 100%;
  height: 100%;
  color: #fff;
}
.vipp__roob-input {
  width: 100%;
  margin-bottom: 20px;
}
.vipp__roob-input input {
  max-width: 300px;
  width: 100%;
  background: #0a0a17;
  border: 1.85292px solid #6e4323;
  border-radius: 9.26462px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  padding: 13px 16px;
}
.vipp__roob-btn {
  width: 300px;
  height: 52px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #d37c30;
  border-radius: 9.26462px;
  border: 1px solid #b26019;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.vipp__roob-btn:hover {
  opacity: 0.8;
}
.vipp__roob-btn p {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 27.7939px;
  line-height: 34px;
  text-align: center;
  color: #fff;
}
.vippMap {
  position: relative;
}
.vippMap::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(10, 10, 23, 0.5);
  z-index: -10;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.vippMap .map__outer::before {
  display: none;
}
.vippMap__popup {
  position: fixed;
  bottom: 81px;
  left: 0;
  width: 100%;
  height: 203px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 50;
}
.vippMap__popup-image {
  height: 125px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 20px;
  position: relative;
  z-index: 2;
}
.vippMap__popup-image img {
  height: 100%;
}
.vippMap__popup-image.shard {
  width: 64px;
  height: 109px;
}
.vippMap__popup-image.shard img {
  width: 100%;
}
.vippMap__popup-content h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 42.3529px;
  line-height: 51px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #fff;
  text-shadow: 0px 4.20919px 8.41838px rgba(0, 37, 64, 0.5);
}
.vippMap__popup-content p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 21.1765px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #96c6e5;
  text-shadow: 0px 4.20919px 8.41838px rgba(0, 37, 64, 0.5);
}
.vippMap__popup-claim {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(41, 193, 41, 0.7)), to(#29c129));
  background: linear-gradient(180deg, rgba(41, 193, 41, 0.7) 0%, #29c129 100%);
  border: 1px solid rgba(251, 255, 138, 0.5);
  border-radius: 20px;
  width: 256px;
  height: 74px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-right: 60px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-left: -66px;
}
.vippMap__popup-claim p {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 42.3529px;
  line-height: 51px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #fff;
  text-shadow: 0px 4.20919px 8.41838px rgba(16, 98, 0, 0.5);
}
.vippMap__popup-close {
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 20px;
  height: 20px;
}
.vippMap__popup-close svg {
  width: 100%;
  height: 100%;
  color: #00233c;
}
.vippMap__popup-lock {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 20px;
  height: 27px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.vippMap__popup-lock svg {
  width: 100%;
  height: 100%;
  color: #fff;
}
.vippMap__popup.step-0 {
  background: rgba(74, 131, 255, 0.7);
  border: 1px solid rgba(138, 175, 255, 0.7);
}
.vippMap__popup.step-1 {
  background: rgba(74, 131, 255, 0.7);
  border: 1px solid rgba(138, 175, 255, 0.7);
}
.vippMap__popup.step-2 {
  background: rgba(74, 131, 255, 0.7);
  border: 1px solid rgba(138, 175, 255, 0.7);
}
.vippMap__popup.step-3 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 85, 255, 0.7)), to(rgba(0, 85, 255, 0.7)));
  background: linear-gradient(180deg, rgba(0, 85, 255, 0.7) 0%, rgba(0, 85, 255, 0.7) 100%);
  border: 1px solid rgba(157, 189, 255, 0.7);
}
.vippMap__popup.step-4 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(89, 0, 255, 0.7)), to(rgba(89, 0, 255, 0.7)));
  background: linear-gradient(180deg, rgba(89, 0, 255, 0.7) 0%, rgba(89, 0, 255, 0.7) 100%);
  border: 1px solid rgba(176, 133, 255, 0.7);
}
.vippMap__popup.step-5 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(157, 0, 255, 0.7)), to(rgba(157, 0, 255, 0.7)));
  background: linear-gradient(180deg, rgba(157, 0, 255, 0.7) 0%, rgba(157, 0, 255, 0.7) 100%);
  border: 1px solid rgba(224, 173, 255, 0.7);
}
.vippMap__popup.step-6 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(157, 0, 255, 0.7)), to(rgba(157, 0, 255, 0.7)));
  background: linear-gradient(180deg, rgba(157, 0, 255, 0.7) 0%, rgba(157, 0, 255, 0.7) 100%);
  border: 1px solid rgba(224, 173, 255, 0.7);
}
.vippMap__popup.step-7 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(157, 0, 255, 0.7)), to(rgba(157, 0, 255, 0.7)));
  background: linear-gradient(180deg, rgba(157, 0, 255, 0.7) 0%, rgba(157, 0, 255, 0.7) 100%);
  border: 1px solid rgba(224, 173, 255, 0.7);
}
.vippMap__popup.step-8 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(157, 0, 255, 0.7)), to(rgba(157, 0, 255, 0.7)));
  background: linear-gradient(180deg, rgba(157, 0, 255, 0.7) 0%, rgba(157, 0, 255, 0.7) 100%);
  border: 1px solid rgba(224, 173, 255, 0.7);
}
.vippMap__popup.step-9 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 0, 208, 0.7)), to(rgba(255, 0, 208, 0.7)));
  background: linear-gradient(180deg, rgba(255, 0, 208, 0.7) 0%, rgba(255, 0, 208, 0.7) 100%);
  border: 1px solid rgba(255, 205, 246, 0.7);
}
.vippMap__popup.step-10 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 0, 208, 0.7)), to(rgba(255, 0, 208, 0.7)));
  background: linear-gradient(180deg, rgba(255, 0, 208, 0.7) 0%, rgba(255, 0, 208, 0.7) 100%);
  border: 1px solid rgba(255, 205, 246, 0.7);
}
.vippMap__popup.step-11 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 0, 153, 0.7)), to(rgba(255, 0, 153, 0.7)));
  background: linear-gradient(180deg, rgba(255, 0, 153, 0.7) 0%, rgba(255, 0, 153, 0.7) 100%);
  border: 1px solid rgba(255, 146, 148, 0.7);
}
.vippMap__popup.step-12 {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 98, 0, 0.7)), to(rgba(255, 98, 0, 0.7)));
  background: linear-gradient(180deg, rgba(255, 98, 0, 0.7) 0%, rgba(255, 98, 0, 0.7) 100%);
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.vippMap__popup.shard {
  background: rgba(74, 131, 255, 0.7);
  border: 1px solid rgba(138, 175, 255, 0.7);
}
.vippMap__popup.claim {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(41, 193, 41, 0.49)), to(rgba(41, 193, 41, 0.7)));
  background: linear-gradient(180deg, rgba(41, 193, 41, 0.49) 0%, rgba(41, 193, 41, 0.7) 100%);
  border: 1px solid rgba(138, 175, 255, 0.5);
}
.vippProgress {
  max-width: 266px;
  width: 100%;
  margin: 0 auto 22px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
.vippProgress__info {
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: -50px;
  background: #307bd3;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.vippProgress__info:hover {
  opacity: 0.8;
}
.vippProgress__info svg {
  width: 16px;
  height: 16px;
  color: #fff;
}
.vippProgress__rank {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 35px;
  position: relative;
}
.vippProgress__rank img {
  width: 100%;
}
.vippProgress__rank-tooltip {
  position: absolute;
  bottom: calc(100% + 11px);
  border-radius: 20px;
  background: #3f3b80;
  width: 128px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 23px;
  left: 50%;
  transform: translateX(-50%);
  -moz-ransform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
.vippProgress__rank-tooltip h6 {
  width: 124px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vippProgress__rank-tooltip h6 span {
  font-weight: 500;
  color: #b4b2dc;
}
.vippProgress__line {
  width: 168px;
  margin: 0 14px;
  height: 16px;
  background: #3a3870;
  border-radius: 20px;
  border: 1px solid #3f3b80;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
.vippProgress__line-elems {
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.vippProgress__line-elem {
  width: 8px;
  height: 20px;
  background: #333162;
  -webkit-transform: skew(-30deg);
          transform: skew(-30deg);
}
.vippProgress__line-inner {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  background: -webkit-gradient(linear, left top, left bottom, from(#27cf27), to(#00a75f));
  background: linear-gradient(180deg, #27cf27 0%, #00a75f 100%);
  border-radius: 20px;
  overflow: hidden;
}
.vippProgress__line-inner-elems {
  width: 168px;
  position: absolute;
  top: 50%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.vippProgress__line-inner-elem {
  width: 8px;
  height: 20px;
  background: linear-gradient(6.9deg, #00aa5a 10.65%, #39e639 89.69%);
  -webkit-transform: skew(-30deg);
          transform: skew(-30deg);
}
.vippProgress__other {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-ransform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 5;
}
.vippProgress__other img {
  width: 100%;
}

@media (max-width: 1480px) {
  .map {
    margin-right: 0;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .map__outer {
    display: block;
  }
}
@media (max-width: 1460px) {
  .vipReg__inner {
    padding: 80px 0 120px 0;
  }

  .latest__inner {
    padding: 80px 0 160px 0;
  }

  .offers__inner {
    padding: 60px 0 140px 0;
  }

  .offr__inner {
    padding: 70px 0 100px 0;
  }

  .store__image {
    height: 100%;
  }
  .store__star.one {
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    left: 270px;
    top: 80px;
    -webkit-transform: rotate(25.05deg);
            transform: rotate(25.05deg);
  }
  .store__star.two {
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    top: 100px;
    right: 30px;
    left: unset;
    -webkit-transform: rotate(-29.42deg);
            transform: rotate(-29.42deg);
  }
}
@media (max-width: 1340px) {
  .guessContent {
    margin: unset;
    max-width: unset;
    padding: 120px 42px 50px 42px;
  }
  .guessContent__wheel {
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    top: -120px;
  }
  .guess__inner {
    padding: 180px 0 292px 0;
  }

  .map {
    margin-left: -100px;
  }

  .vippRewards {
    left: calc(50% - 354.5px);
    width: 380px;
  }
  .vippRewards__head {
    padding: 15px 15px 15px 20px;
  }
  .vippRewards__head-rank {
    width: 80px;
    height: unset;
  }
}
@media (max-width: 1180px) {
  .auto__container {
    padding: 0 30px;
  }

  .header__inner-logo {
    margin-right: 24px;
  }

  .nav__inner-link {
    margin-right: 16px;
  }
  .nav__inner-link:last-child {
    margin-right: 0;
  }

  .footer__top-inner {
    padding: 40px 0;
  }
  .footer__note-inner p {
    max-width: unset;
    width: calc(50% - 20px);
  }

  .vipReg__inner {
    padding: 60px 0 80px 0;
  }

  .latest__inner {
    padding: 80px 60px 120px 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }

  .offersItem__btn {
    width: 200px;
  }
  .offersItem__content {
    width: calc(100% - 275px);
  }

  .ctaBox__content {
    margin-right: 30px;
  }

  .switchprRoob {
    padding: 0;
  }
  .switchprPack {
    padding: 0;
  }
  .switchprPack__free-image img {
    height: 108%;
  }

  .modalOffer__content {
    padding: 32px;
  }
  .modalOffer__info {
    padding: 52px 32px 32px 32px;
  }
  .modalOffer__info-btn {
    bottom: 32px;
    width: calc(100% - 64px);
    left: 32px;
  }
  .modalShop__content {
    padding: 40px 30px 57px 30px;
  }
  .modalShop__info {
    padding: 40px 30px 57px 30px;
  }

  .jackpotContent__inner {
    width: calc(56% - 10px);
  }
  .jackpotContent__side {
    width: calc(44% - 10px);
  }
  .jackpotContent__lottery-items {
    right: 16px;
  }

  .store__top-item {
    padding: 24px 16px 24px 160px;
  }
  .store__top-item-image-outer {
    width: 120px;
  }
  .store__top-item-image-outer::before {
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .store__top-item:hover .store__top-item-image-outer::before {
    scale: 1.1;
  }
  .store__earn-inner {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 24px 24px 24px 45px;
  }
  .store__earn-row {
    width: calc(100% - 240px);
  }
  .store__prize-inner {
    padding: 50px 32px 72px 32px;
  }

  .notify {
    margin-right: 10px;
  }

  .vippRewards {
    left: 30px;
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }

  .map {
    margin-left: -240px;
  }
}
@media (max-width: 1024px) {
  .header__inner-logo {
    margin-right: 16px;
  }
  .header__btns {
    max-width: 260px;
  }
  .header__btns .button {
    width: calc(50% - 5px);
  }
  .header__btns .button p {
    font-size: 13px;
  }
  .header__more-level {
    margin-right: 12px;
  }
  .header__more-level-progress {
    width: 70px;
  }
  .header__more-account {
    width: unset;
  }
  .header__more-account p {
    display: none;
  }

  .nav__inner-link {
    margin-right: 16px;
  }
  .nav__inner-link-content {
    width: 70px;
  }
  .nav__inner-link p {
    font-size: 13px;
  }
  .nav__inner-link.vip p {
    font-size: 13px;
  }
  .nav__inner-link.vip .nav__inner-link-content {
    width: 131px;
  }

  .vipReg__inner {
    padding: 60px 0 60px 0;
  }
  .vipReg__image {
    width: calc(30% - 16px);
  }
  .vipReg__content {
    width: calc(70% - 16px);
  }

  .offersItem__icon {
    width: 44px;
  }
  .offersItem__btn {
    width: 160px;
  }
  .offersItem__btn p {
    font-size: 15px;
  }
  .offersItem__content {
    width: calc(100% - 224px);
    padding-left: 140px;
  }
  .offersItem__content-image {
    width: 140px;
  }
  .offersItem__content-col h6 {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .offersItem__content-col p {
    font-size: 12px;
  }

  .offr__pop {
    width: calc(33.3334% - 10px);
  }
  .offr__prof {
    width: calc(33.3334% - 10px);
  }
  .offr__rolex-image {
    left: -70px;
  }
  .offr__rolex:hover .offr__rolex-image {
    left: -100px;
  }

  .ctaBox {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-bottom: 89px;
  }
  .ctaBox__star.one {
    left: unset;
    right: 260px;
  }
  .ctaBox__content {
    margin-right: 0;
    position: static;
    margin-bottom: 24px;
  }
  .ctaBox__content p {
    margin-bottom: 0;
  }
  .ctaBox__content-btn {
    z-index: 2;
    position: absolute;
    bottom: 24px;
  }
  .ctaBox .button.register {
    z-index: 2;
    position: absolute;
    bottom: 24px;
  }
  .cta.pred .ctaBox__guess {
    top: -61px;
    right: 110px;
    width: 330px;
    height: 326px;
  }

  .switchpr__inner {
    padding-bottom: 120px;
  }
  .switchprRoob__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 24px;
  }
  .switchprRoob__reward {
    width: 100%;
    margin-bottom: 24px;
  }
  .switchprRoob__register {
    width: 100%;
    height: 182px;
  }
  .switchprRoob__register-image img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .switchprRoob__register-btn {
    left: 38px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .switchprRoob__jack-content {
    margin-bottom: 13px;
  }
  .switchprRoob__jack-info {
    position: relative;
    top: unset;
    right: unset;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .switchprRoob__jack-coins {
    right: 0;
  }
  .switchprRoob__jack-image {
    left: unset;
    right: 0;
  }
  .switchprRoob__jack .button.green {
    left: 53px;
    right: unset;
  }
  .switchprRoob__jack:hover .button.green {
    bottom: 14px;
  }
  .switchprRoob__jack:hover .switchprRoob__jack-coins {
    right: 10px;
  }
  .switchprRoob__jack:hover .switchprRoob__jack-image {
    left: unset;
    right: 10px;
  }
  .switchprPack__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 24px;
  }
  .switchprPack__board {
    width: 100%;
    margin-bottom: 24px;
  }
  .switchprPack__register {
    width: 100%;
    height: 182px;
  }
  .switchprPack__register-btn {
    left: 38px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .switchprPack__free-content p {
    color: #fff;
  }
  .switchprPack__free-image img {
    height: 100%;
  }
  .switchprPack__deposit {
    width: calc(33.3334% - 10px);
  }
  .switchprPack__deposit-content h2 {
    font-size: 40px;
  }
  .switchprPack__deposit-content h3 {
    font-size: 16px;
  }
  .switchprPack__iphone {
    width: calc(33.3334% - 10px);
  }
  .switchprPack__iphone-image {
    left: -110px;
  }
  .switchprPack__iphone:hover .switchprPack__iphone-image {
    left: -120px;
  }
  .switchprPack__rolex {
    width: calc(33.3334% - 10px);
  }
  .switchprPack__rolex-image {
    left: -70px;
  }
  .switchprPack__rolex:hover .switchprPack__rolex-image {
    left: -100px;
  }

  .modalOffer__content-head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-bottom: 24px;
  }
  .modalOffer__content-head-image {
    margin-bottom: 24px;
  }
  .modalOffer__content-head-info {
    width: 100%;
  }
  .modalShop {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .modalShop__content {
    width: 100%;
    max-width: unset;
    padding: 32px 24px;
  }
  .modalShop__content-info {
    width: calc(100% - 224px);
  }
  .modalShop__info {
    padding: 24px;
    width: 100%;
    max-width: unset;
  }

  .jackpot__inner {
    padding: 100px 0 530px 0;
  }
  .jackpot__mount {
    width: 365px;
  }
  .jackpot__mount.right {
    width: 392px;
  }
  .jackpot__bg {
    top: unset;
    height: 1139px;
    bottom: 0;
  }
  .jackpot__head {
    margin-bottom: 100px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .jackpot__head-title {
    margin-bottom: 24px;
  }
  .jackpot__head-title h2 {
    text-align: center;
  }
  .jackpotContent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .jackpotContent__inner {
    width: 100%;
    max-width: 600px;
    margin: 0 auto 32px;
  }
  .jackpotContent__side {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  .jackpotContent__lottery-items {
    right: 39px;
  }

  .guessContent {
    padding: 120px 24px 50px 24px;
  }

  .store__top-item {
    width: calc(50% - 8px);
    padding: 24px 16px 24px 140px;
  }
  .store__top-item-content-title h2 {
    font-size: 24px;
  }
  .store__earn-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .store__earn-info {
    width: 100%;
    margin-bottom: 24px;
  }
  .store__earn-row {
    width: 100%;
    position: relative;
    z-index: 2;
  }
  .store__earn-item.guess .store__earn-item-content {
    min-height: 75px;
  }
  .store__prize-inner {
    padding: 50px 24px 72px 24px;
  }
  .store__prize-item {
    width: calc(25% - 16px);
    margin: 22px 8px;
  }
  .store__prize-items {
    width: calc(100% + 16px);
    margin: 0 -8px;
  }

  .profile__vip-desc {
    width: 220px;
  }
  .profile__vip-inner {
    width: calc(100% - 240px);
  }
  .profileAccount__content {
    width: calc(45% - 9px);
  }
  .profileAccount__vip {
    width: calc(55% - 9px);
  }

  .map {
    width: 100%;
    margin-left: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .map__outer {
    margin-top: 0;
    padding-top: 112px;
  }
  .map__black {
    display: none;
  }

  .vippBtns {
    padding: 6px 0 11px 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 50;
    background: -webkit-gradient(linear, left top, left bottom, from(#181835), color-stop(8.99%, #1f1d47)), #1f1d47;
    background: linear-gradient(180deg, #181835 0%, #1f1d47 8.99%), #1f1d47;
  }
  .vippBtn {
    cursor: pointer;
    background: #3f3b80;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    border: 1px solid #3f3b80;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .vippBtn svg {
    color: #6864b6;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .vippBtn svg path {
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .vippBtn svg path.otherpath {
    color: #bdbaef;
  }
  .vippBtn.rw svg {
    width: 19px;
    height: 16px;
  }
  .vippBtn.prog svg {
    width: 21px;
    height: 19px;
  }
  .vippBtn.mp svg {
    width: 19px;
    height: 18px;
  }
  .vippBtn:hover {
    width: 52px;
    height: 52px;
    background: rgba(31, 120, 255, 0.2);
    border: 1px solid #1f78ff;
  }
  .vippBtn:hover svg {
    color: #1f78ff;
  }
  .vippBtn:hover svg path.otherpath {
    color: #44b4ff;
  }
  .vippBtn:hover.rw svg {
    width: 27px;
    height: 23px;
  }
  .vippBtn:hover.prog svg {
    width: 26px;
    height: 24px;
  }
  .vippBtn:hover.mp svg {
    width: 25px;
    height: 24px;
  }
  .vippBtn.active {
    width: 52px;
    height: 52px;
    background: rgba(31, 120, 255, 0.2);
    border: 1px solid #1f78ff;
  }
  .vippBtn.active svg {
    color: #1f78ff;
  }
  .vippBtn.active svg path.otherpath {
    color: #44b4ff;
  }
  .vippBtn.active.rw svg {
    width: 27px;
    height: 23px;
  }
  .vippBtn.active.prog svg {
    width: 26px;
    height: 24px;
  }
  .vippBtn.active.mp svg {
    width: 25px;
    height: 24px;
  }
  .vippBtn__outer {
    width: 64px;
    height: 64px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-right: 60px;
  }
  .vippBtn__outer:last-child {
    margin-right: 0;
  }
  .vipp__timer {
    position: static;
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }
  .vippMap {
    position: relative;
    padding-top: 11px;
  }
  .vippMap::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 115px;
    content: "";
    background: -webkit-gradient(linear, left top, left bottom, from(#0a0a17), to(rgba(10, 10, 23, 0)));
    background: linear-gradient(180deg, #0a0a17 0%, rgba(10, 10, 23, 0) 100%);
    z-index: -1;
  }
  .vippMap__title {
    margin-bottom: 12px;
  }
  .vippMap__title h1 {
    font-family: "Inter", sans-serif;
    font-weight: 900;
    font-size: 54.9321px;
    line-height: 66px;
    text-align: center;
    background: -webkit-gradient(linear, left top, right top, from(#ffed71), to(#ffbe00));
    background: linear-gradient(90deg, #ffed71 0%, #ffbe00 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .vippMap__title h2 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 30.8993px;
    line-height: 46px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #fff;
    margin-top: -22px;
  }
  .vipp__register {
    left: 50%;
    width: 100%;
    max-width: 362px;
  }
  .vipp__roob {
    left: 50%;
    width: 100%;
    max-width: 362px;
  }
  .vippRew {
    position: relative;
    padding-top: 11px;
    background: #1f1d47;
  }
  .vippRew::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 115px;
    content: "";
    background: -webkit-gradient(linear, left top, left bottom, from(#0a0a17), to(rgba(10, 10, 23, 0)));
    background: linear-gradient(180deg, #0a0a17 0%, rgba(10, 10, 23, 0) 100%);
  }
  .vippRew .vipp__register {
    width: calc(100% - 60px);
    max-width: unset;
    position: static;
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    margin-bottom: 32px;
    margin-top: 9px;
    margin-left: 30px;
  }
  .vippRew .vipp__register-btn {
    margin: 0 auto;
  }
  .vippRew .vipp__roob {
    width: calc(100% - 60px);
    max-width: unset;
    position: static;
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    margin-bottom: 32px;
    margin-top: 9px;
    margin-left: 30px;
  }
  .vippRew .vipp__roob-input {
    max-width: 300px;
    margin: 0 auto 20px auto;
  }
  .vippRew .vipp__roob-btn {
    margin: 0 auto;
  }
  .vippRew .vipp__rew {
    padding: 0 30px 60px 30px;
    height: unset;
  }
  .vippRew .vipp__rew-content {
    height: unset;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background: -webkit-gradient(linear, left top, left bottom, from(#0a0a17), to(#292657));
    background: linear-gradient(180deg, #0a0a17 0%, #292657 100%);
    border: 1px solid #3f3b80;
    border-radius: 20px;
    padding: 20px 18px;
  }
  .vippRew .vipp__rew-items {
    height: unset;
    overflow: unset;
  }
  .vippRew .vipp__rew-item-icon {
    left: 50%;
  }
  .vippRew .vipp__rew-item-info {
    width: 50%;
  }
  .vippRew .vipp__rew-item-price {
    width: 50%;
  }
  .vippRew .vippProgress {
    padding-top: 51px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    z-index: 2;
    margin: 0 auto 60px auto;
  }
  .vippRew .vippProgress__info {
    display: none;
  }
  .vippProg {
    width: 100%;
    z-index: 20;
    background: #1f1d47;
    padding: 0 0 81px 0;
    top: 66px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .vippProg::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 93px;
    content: "";
    background: -webkit-gradient(linear, left top, left bottom, from(#0a0a17), to(rgba(10, 10, 23, 0)));
    background: linear-gradient(180deg, #0a0a17 0%, rgba(10, 10, 23, 0) 100%);
    z-index: 1;
  }
  .vippProg__inner {
    position: relative;
  }
  .vippProg__map {
    overflow: hidden;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-bottom: 0;
    position: relative;
  }
  .vippProg__map::before {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    content: "";
    z-index: 3;
    height: 150px;
    background: -webkit-gradient(linear, left top, left bottom, from(0), color-stop(0%, #002440), to(rgba(0, 36, 64, 0)));
    background: linear-gradient(0, #002440 0%, rgba(0, 36, 64, 0) 100%);
  }
  .vippProg__map::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    content: "";
    z-index: 1;
    height: 100%;
    opacity: 0;
  }
  .vippProg__map-inner {
    height: 190px;
    overflow: hidden;
  }
  .vippProg__map-date {
    height: 25px;
    padding: 0 15px 0 27px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    background: #3f3b80;
    border-radius: 0 0 20px 0;
    z-index: 3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .vippProg__map-date p {
    font-family: "Kanit", sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 1;
    margin-top: -3px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #fff;
  }
  .vippProg__map-icon {
    position: absolute;
    top: 35px;
    border-radius: 50%;
    right: 16px;
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: pointer;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    background: #307bd3;
    z-index: 3;
  }
  .vippProg__map-icon:hover {
    opacity: 0.8;
  }
  .vippProg__map-icon svg {
    width: 16px;
    height: 16px;
    color: #fff;
  }
  .vippProg .map__outer {
    padding-top: 0;
  }
  .vippProg .map__outer::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: -webkit-gradient(linear, left top, left bottom, from(#031627), color-stop(18.19%, #002641), color-stop(30.69%, #000349), color-stop(43%, #150152), color-stop(60%, #450041), color-stop(81.6%, #722800), color-stop(86.87%, #722800)), #0a0a17;
    background: linear-gradient(180deg, #031627 0%, #002641 18.19%, #000349 30.69%, #150152 43%, #450041 60%, #722800 81.6%, #722800 86.87%), #0a0a17;
  }
  .vippProg__box {
    padding: 42px 30px 70px 30px;
    background: -webkit-gradient(linear, left top, left bottom, from(#0a0a17), to(#292657));
    background: linear-gradient(180deg, #0a0a17 0%, #292657 100%);
    border-top: 1px solid #3f3b80;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .vippProg .vipp__rew {
    padding: 0;
  }
  .vippProg .vipp__rew-title {
    margin-bottom: 14px;
  }
  .vippProg .vipp__rew-title-icon {
    display: none;
  }
  .vippProg .vipp__rew-rows {
    overflow: unset;
  }
  .vippProg .vippProgress {
    z-index: 10;
    margin: 0 auto 34px auto;
  }
  .vippProg .vippProgress__info {
    display: none;
  }
}
@media (max-width: 840px) {
  main {
    padding-top: 89px;
  }

  .auto__container {
    padding: 0 24px;
  }

  .bgOverlay {
    display: none;
  }

  .header {
    background: -webkit-gradient(linear, left top, left bottom, from(0), color-stop(0%, #181835), color-stop(8.99%, #1f1d47)), #1f1d47;
    background: linear-gradient(0, #181835 0%, #1f1d47 8.99%), #1f1d47;
  }
  .header__inner {
    padding: 20.5px 0;
    display: block;
  }
  .header__inner::before {
    display: none;
  }
  .header__inner-logo {
    width: 41px;
    height: 43px;
    margin-right: 0;
  }
  .header__inner-logo-ellipse {
    top: 1.4px;
    left: 1.94px;
    width: 37.95px;
    height: 38.25px;
  }
  .header__inner-logo-ellipse2 {
    top: 2.51px;
    left: 1.1px;
    width: 38.23px;
    height: 39.23px;
  }
  .header__inner-logo svg {
    width: 30.47px;
    height: 30.71px;
    top: 5.31px;
    right: 4.99px;
  }
  .header__inner-logo:hover svg {
    top: 4.75px;
    right: 2.77px;
    width: 29.09px;
    height: 31.55px;
  }
  .header__inner-logo:hover .header__inner-logo-ellipse {
    color: #1fa2ff;
    width: 35.46px;
    height: 39.37px;
    left: 4.99px;
    top: 0.84px;
  }
  .header__inner-logo:hover .header__inner-logo-ellipse2 {
    top: 0.84px;
    left: 2.49px;
    width: 37.95px;
    height: 39.93px;
    color: #2843db;
  }
  .header__inner-content {
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .header__btns {
    display: none;
  }
  .header .languageSelect {
    display: none;
  }
  .header__more {
    right: 84px;
  }
  .header__more-account {
    display: none;
  }
  .header__more-btns {
    display: none;
  }
  .header__more-balance {
    width: 175px;
    margin-right: 13px;
    background: #0a0a17;
    height: 45px;
  }

  .nav {
    position: fixed;
    top: 89px;
    left: 0;
    width: 100%;
    height: calc(100% - 89px);
    z-index: 100;
    background: none;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    -moz-transition: all 0.2s ease-in-out 0s;
  }
  .nav.active {
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }
  .nav__inner {
    position: absolute;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
    width: 100%;
    padding: 0;
    z-index: 1;
    padding: 24px;
    height: 100%;
    overflow-y: auto;
    background: #1f1d47;
    border: unset;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  .nav__inner::-webkit-scrollbar {
    display: none;
  }
  .nav__inner::before {
    position: absolute;
    top: -6px;
    left: 0;
    width: 100%;
    height: 85px;
    content: "";
    background: -webkit-gradient(linear, left top, left bottom, from(#181835), color-stop(17.65%, #1f1d47));
    background: linear-gradient(180deg, #181835 0%, #1f1d47 17.65%);
    z-index: -1;
  }
  .nav__inner-link {
    margin-right: 0;
    padding: 0;
    width: unset;
    margin-bottom: 20px;
  }
  .nav__inner-link:last-child {
    margin-bottom: 0;
  }
  .nav__inner-link-content {
    display: none;
  }
  .nav__inner-link.roobet .nav__dropdown-col:last-child {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin-bottom: 18px;
  }
  .nav__inner-link.roobet .nav__dropdown-col:nth-child(2) {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    margin-bottom: 0;
  }
  .nav__inner-link.roobet .nav__dropdown-col:nth-child(2) .nav__dropdown-item:nth-child(1) {
    display: none;
  }
  .nav__inner-link.vip .nav__inner-link-content {
    display: none;
  }
  .nav__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 23px 9px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-bottom: 5px;
  }
  .nav__head-logo {
    width: 41px;
    height: 43px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .nav__head-logo img {
    width: 100%;
    height: 100%;
  }
  .nav__head-close {
    cursor: pointer;
    background: #201f45;
    border: 1px solid #181835;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 42px;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .nav__head-close svg {
    width: 17px;
    height: 17px;
    color: #8988bc;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .nav__head-close:hover {
    background: #4c4a92;
  }
  .nav__head-close:hover svg {
    color: #ffffff;
  }
  .nav__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: 363px;
    width: 100%;
    margin: 0 auto 38px auto;
  }
  .nav__btn {
    width: calc(50% - 4px);
    height: 42px;
    border-radius: 5px;
    background: #307bd3;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
  }
  .nav__btn::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-ransform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: -webkit-gradient(linear, left top, left bottom, from(#1f60ab), to(#1e5ca4));
    background: linear-gradient(180deg, #1f60ab 0%, #1e5ca4 100%);
    border-radius: 20px;
    z-index: -1;
  }
  .nav__btn:last-child {
    background: -webkit-gradient(linear, left top, right top, from(#5d5ba6), to(#484690));
    background: linear-gradient(90deg, #5d5ba6 0%, #484690 100%);
  }
  .nav__btn:last-child::before {
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    background: -webkit-gradient(linear, left top, left bottom, from(#49488d), to(#212047));
    background: linear-gradient(180deg, #49488d 0%, #212047 100%);
  }
  .nav__dropdown {
    margin: 0 auto;
    max-width: 363px;
    position: static;
    opacity: 1 !important;
    -webkit-transform: translate(0, 0) !important;
            transform: translate(0, 0) !important;
    max-height: unset !important;
    -webkit-transition: unset;
    transition: unset;
  }
  .nav__dropdown-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 20px;
    background: #3f3b80;
    padding: 16px 26px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: relative;
    height: 57px;
  }
  .nav__dropdown-title p {
    font-family: "Inter", sans-serif;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    color: #fff !important;
    text-shadow: 0px 2.51961px 5.03922px rgba(31, 29, 71, 0.5);
  }
  .nav__dropdown-title svg {
    width: 24px !important;
    height: 24px !important;
    margin-right: 6px !important;
    color: #fff !important;
  }
  .nav__dropdown-title svg path.otherpath {
    color: #6863c7 !important;
  }
  .nav__dropdown-title img {
    width: 16px;
    height: 27px;
    margin-right: 10px;
    -webkit-transform: rotate(28.04deg);
            transform: rotate(28.04deg);
  }
  .nav__dropdown-title-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .nav__dropdown-title-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    margin-right: 0;
  }
  .nav__dropdown-title.roobet svg {
    width: 24px !important;
    height: 24px !important;
    margin-right: 6px !important;
    color: #68689f !important;
  }
  .nav__dropdown-title.roobet svg path.otherpath {
    color: #fff !important;
  }
  .nav__dropdown-title.roobet .nav__dropdown-title-chevron svg {
    width: 100% !important;
    height: 100% !important;
    color: #fff !important;
    margin-right: 0 !important;
  }
  .nav__dropdown-title.shop {
    background: #3c8eee;
    overflow: hidden;
  }
  .nav__dropdown-title-chevron {
    position: absolute;
    right: 26px;
    top: 50%;
    transform: translateY(-50%);
    -moz-ransform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    display: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 16px;
    height: 16px;
  }
  .nav__dropdown-title-chevron svg {
    width: 100% !important;
    height: 100% !important;
    color: #fff !important;
    margin-right: 0 !important;
  }
  .nav__dropdown-title.vip {
    background: -webkit-gradient(linear, left top, right top, from(#b16615), to(#ffc026));
    background: linear-gradient(90deg, #b16615 0%, #ffc026 100%);
    padding: 16px 15px;
  }
  .nav__dropdown-title.vip .nav__dropdown-title p {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    text-shadow: 0px 2.51961px 5.03922px rgba(31, 29, 71, 0.5);
  }
  .nav__dropdown-title.vip .nav__dropdown-title-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 30px;
    height: 32px;
    margin-right: 10px;
  }
  .nav__dropdown-title.vip .nav__dropdown-title-icon img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    margin-right: 0;
  }
  .nav__dropdown-title.vip .nav__dropdown-title-star {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 1;
  }
  .nav__dropdown-title.vip .nav__dropdown-title-star img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    margin-right: 0;
    -webkit-transform: rotate(17.01deg);
            transform: rotate(17.01deg);
  }
  .nav__dropdown-title.vip .nav__dropdown-title-star.one {
    top: 5px;
    right: 138px;
    width: 17px;
    height: 16px;
  }
  .nav__dropdown-title.vip .nav__dropdown-title-star.two {
    bottom: 5px;
    right: 106px;
    width: 18px;
    height: 15px;
  }
  .nav__dropdown-title.vip .nav__dropdown-title-star.three {
    bottom: 11px;
    right: 6px;
    width: 27px;
    height: 23px;
  }
  .nav__dropdown-title.vip .nav__dropdown-title-bg {
    position: absolute;
    top: 0;
    right: 31px;
    width: 87px;
    height: 57px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .nav__dropdown-title.vip .nav__dropdown-title-bg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    margin-right: 0;
  }
  .nav__dropdown-inner {
    padding: 8px 26px 30px 26px;
    background: unset;
    border: unset;
    border-radius: unset;
    display: none;
    min-height: unset;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .nav__dropdown-inner.mobActive {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .nav__dropdown-play {
    width: 100%;
    margin-bottom: 18px;
    height: 111px;
    border-radius: 20px;
  }
  .nav__dropdown-col {
    width: 100%;
    margin-bottom: 8px;
  }
  .nav__dropdown-col-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .nav__dropdown-col:last-child {
    margin-bottom: 0;
  }
  .nav__dropdown-item {
    margin-bottom: 8px;
    height: 52px;
    min-height: unset;
    border-radius: 20px;
  }
  .nav__dropdown-item-ranks {
    width: 120px;
  }
  .nav__dropdown-item:last-child {
    margin-bottom: 0;
  }
  .nav__dropdown-item-title {
    padding: 4px 20px;
  }
  .nav__dropdown-jackpot {
    min-height: unset;
    height: 52px;
    border-radius: 20px;
  }
  .nav__dropdown-jackpot-image {
    width: 175px;
    height: 47px;
  }
  .nav__dropdown-jackpot-content {
    right: 30px;
  }
  .nav__dropdown-jackpot-content h4 {
    font-size: 13px;
  }
  .nav__dropdown-jackpot-content h5 {
    font-size: 18px;
  }
  .nav__dropdown.pack .nav__dropdown-col:nth-child(2) {
    margin-bottom: 0;
  }
  .nav__dropdown-content {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: unset;
    margin-bottom: 18px;
  }
  .nav__dropdown-content .nav__dropdown-item {
    display: none;
    width: 100%;
    margin-bottom: 8px;
  }
  .nav__dropdown-row {
    height: unset;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    margin-bottom: 0;
  }
  .nav__dropdown-row-image {
    width: 100%;
    height: 96px;
    margin-bottom: 18px;
    border-radius: 20px;
  }
  .nav__dropdown-row-image:last-child {
    margin-bottom: 0;
  }
  .nav__dropdown-leaderboard {
    width: 100%;
    height: 80px;
    border-radius: 20px;
  }
  .nav__dropdown.mobActive {
    background: #0a0a17;
    border: 1px solid #363270;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 20px;
  }
  .nav__dropdown.mobActive .nav__dropdown-title {
    background: none;
  }
  .nav__dropdown.mobActive .nav__dropdown-title-chevron {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .nav__more {
    margin: 0 auto 25px auto;
    max-width: 363px;
    display: block;
    width: 100%;
  }
  .nav__more-info {
    cursor: pointer;
    background: #3f3b80;
    border-radius: 20px;
    padding: 14px 47px 14px 14px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    overflow: hidden;
  }
  .nav__more-avatar {
    width: 70px;
    height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    background: #2f2e5f;
    margin-right: 10px;
  }
  .nav__more-avatar img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .nav__more-avatar svg {
    width: 50px;
    height: 50px;
    color: #adacde;
  }
  .nav__more-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .nav__more-content-name {
    margin-bottom: 2px;
  }
  .nav__more-content-name h4 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #fff;
    max-width: 150px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nav__more-content-rank {
    min-width: 96px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 4px 14px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background: #1c1a3a;
    border-radius: 12px;
  }
  .nav__more-content-rank-image {
    height: 19px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .nav__more-content-rank-image img {
    height: 100%;
  }
  .nav__more-content-rank h6 {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #f3c24d;
    margin-left: 8px;
  }
  .nav__more-btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 47px;
    background: #2f2e5f;
    cursor: pointer;
  }
  .nav__more-btn svg {
    width: 17px;
    height: 16px;
    color: #adacde;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .nav__more-btn:hover svg {
    color: #fff;
  }
  .nav__more .button.continue {
    padding: 10px 0;
    height: 40px;
    border-radius: 20px;
  }
  .nav__more .button.continue span {
    border-radius: 20px;
  }
  .nav__more .button.continue span::before {
    border-radius: 20px;
  }
  .nav__more .button.continue span::after {
    border-radius: 20px;
  }
  .nav__more .button.continue p {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 1;
    color: #fff;
    text-shadow: 0px 1px 2px rgba(23, 35, 140, 0.3);
  }

  .burger {
    cursor: pointer;
    display: block;
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 5px;
    background: #2f2e5f;
    border: 1px solid #181835;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .burger::before {
    top: 15px;
    width: 20px;
    height: 3px;
    background-color: #fff;
    border-radius: 100px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -webkit-box-shadow: 0px 4px 4px rgba(24, 24, 53, 0.5);
            box-shadow: 0px 4px 4px rgba(24, 24, 53, 0.5);
  }
  .burger span {
    top: 50%;
    width: 20px;
    height: 3px;
    background-color: #fff;
    border-radius: 100px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-ransform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -webkit-box-shadow: 0px 4px 4px rgba(24, 24, 53, 0.5);
            box-shadow: 0px 4px 4px rgba(24, 24, 53, 0.5);
  }
  .burger::after {
    bottom: 15px;
    width: 20px;
    height: 3px;
    background-color: #fff;
    border-radius: 100px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -webkit-box-shadow: 0px 4px 4px rgba(24, 24, 53, 0.5);
            box-shadow: 0px 4px 4px rgba(24, 24, 53, 0.5);
  }
  .burger:hover {
    background: #4c4a92;
    border: 1px solid #181835;
  }
  .burger svg {
    width: 13px;
    height: 13px;
    color: #d9d9d9;
    display: none;
  }
  .burger.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .burger.active::before {
    display: none;
  }
  .burger.active::after {
    display: none;
  }
  .burger.active span {
    display: none;
  }
  .burger.active svg {
    display: block;
  }

  .footer__top-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 40px 0 60px 0;
  }
  .footer__top-row {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 60px;
  }
  .footer__top-logo {
    margin-right: 0;
    margin-bottom: 48px;
  }
  .footer__note-inner {
    display: block;
    padding: 30px 0;
  }
  .footer__note-inner p {
    width: 100%;
    margin-bottom: 24px;
  }
  .footer__note-inner p:last-child {
    margin-bottom: 0;
  }
  .footer__note-inner p br {
    display: none;
  }
  .footer__links-inner {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 24px 0;
  }
  .footer__copy-inner p {
    text-align: center;
  }

  .vipReg__inner {
    padding: 40px 0 60px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .vipReg__image {
    width: 100%;
    max-width: 250px;
    margin-bottom: 24px;
  }
  .vipReg__content {
    width: 100%;
  }
  .vipReg__form-btn {
    border-radius: 5px;
  }
  .vipReg__form-input input {
    border-radius: 5px;
  }
  .vipReg__content {
    padding-top: 0;
  }

  .latest__inner {
    padding: 60px 0 100px 0;
  }

  .offers::before {
    display: none;
  }
  .offers::after {
    display: none;
  }
  .offers__inner {
    padding: 60px 0;
  }
  .offers__inner h3 {
    margin-bottom: 24px;
  }
  .offersItem {
    position: relative;
    padding: 98px 12px 10px 12px;
    height: unset;
  }
  .offersItem__content {
    width: 100%;
    padding-left: 0;
    position: static;
  }
  .offersItem__content-image {
    width: 274px;
    height: 74px;
    top: 10px;
    left: 12px;
  }
  .offersItem__content-col h6 {
    font-size: 15px;
  }
  .offersItem__content-col p {
    font-size: 13px;
  }
  .offersItem__btn {
    position: absolute;
    top: 10px;
    right: 12px;
    width: calc(100% - 383px);
  }
  .offersItem__btn p {
    font-size: 17px;
  }
  .offersItem__icon {
    position: absolute;
    top: 10px;
    left: 300px;
    width: 55px;
  }

  .offr::before {
    display: none;
  }
  .offr__inner {
    padding: 60px 0;
  }
  .offr__inner h3 {
    margin-bottom: 32px;
  }
  .offr__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .offr__pop {
    opacity: 1;
    width: 100%;
    margin-bottom: 32px;
  }
  .offr__pop:last-child {
    margin-bottom: 0;
  }
  .offr__pop.animate .offr__pop-inner-ellipse.one {
    width: 185px;
    height: 185px;
    border: 12px solid #6321ad;
    -webkit-filter: blur(25px);
            filter: blur(25px);
    -webkit-transform: translateX(-50%) translateY(0);
            transform: translateX(-50%) translateY(0);
    top: -4px;
  }
  .offr__pop.animate .offr__pop-inner-ellipse.two {
    top: 48px;
    -webkit-transform: translateX(-50%) translateY(0);
            transform: translateX(-50%) translateY(0);
    width: 77px;
    height: 77px;
    border: 10px solid #6321ad;
  }
  .offr__pop.animate .offr__pop-inner-ellipse.three {
    top: -88px;
    -webkit-transform: translateX(-50%) translateY(0);
            transform: translateX(-50%) translateY(0);
    width: 149px;
    height: 149px;
    border: 100px solid #6321ad;
  }
  .offr__pop.animate .offr__pop-inner-image {
    top: 39px;
    width: 225px;
  }
  .offr__pop.animate .offr__pop-inner-overlay {
    opacity: 1;
    z-index: -1;
  }
  .offr__pop.animate .offr__pop-inner-text {
    width: 175px;
  }
  .offr__pop.animate .offr__pop-inner-roobet {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .offr__prof {
    opacity: 1;
    width: 100%;
    margin-bottom: 32px;
  }
  .offr__prof:last-child {
    margin-bottom: 0;
  }
  .offr__prof.animate .offr__prof-inner-image {
    width: 184px;
    top: 48px;
  }
  .offr__prof.animate .offr__prof-inner-logo {
    width: 70px;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .offr__prof.animate .offr__prof-inner-roobet {
    opacity: 1;
  }
  .offr__prof.animate .offr__prof-inner-candy {
    opacity: 0.8;
    bottom: 20px;
    width: 72px;
  }
  .offr__prof.animate .offr__prof-inner-bomb {
    opacity: 0.5;
    width: 72px;
  }
  .offr__rolex {
    opacity: 1;
    width: 100%;
  }
  .offr__rolex-image {
    left: -40px;
  }
  .offr__rolex-content {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .offr__rolex-btn {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .offr__rolex-btn span::before {
    width: calc(100% + 4px);
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .offr__rolex-btn span::after {
    width: 100%;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .offr__rolex:hover .offr__rolex-image {
    left: -50px;
  }
  .offr__rolex:hover .offr__rolex-btn {
    width: 228px;
  }
  .offr__rolex:hover .offr__rolex-btn span::before {
    width: calc(100% + 4px);
  }
  .offr__rolex:hover .offr__rolex-btn span::after {
    width: 100%;
  }
  .offr__rolex.animate .offr__rolex-image {
    top: -37px;
    left: -50px;
    width: 181px;
  }
  .offr__rolex.animate .offr__rolex-btn {
    width: 228px;
  }
  .offr__rolex.animate .offr__rolex-btn span::before {
    width: calc(100% + 4px);
  }
  .offr__rolex.animate .offr__rolex-btn span::after {
    width: 100%;
  }
  .offr__rolex.animate .offr__rolex-content {
    top: 14px;
  }

  .cta {
    background: none;
  }
  .cta .auto__container {
    padding: 0;
  }
  .cta__inner {
    padding: 0;
  }
  .ctaBox {
    padding: 110px 24px 108px 24px;
    border-radius: 0;
  }
  .ctaBox__bg {
    opacity: 1 !important;
    z-index: -3;
  }
  .ctaBox__bg2 {
    z-index: -2;
  }
  .ctaBox__bg3 {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(-2.42%, #302f81), color-stop(87.9%, #537cdb));
    background: linear-gradient(180deg, #302f81 -2.42%, #537cdb 87.9%);
    mix-blend-mode: unset;
    opacity: 1;
    z-index: -1;
  }
  .ctaBox__ellipse {
    width: 550px;
    height: 542px;
    top: -225px;
    left: 0;
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }
  .ctaBox__ellipse2 {
    width: 615px;
    height: 643px;
    top: -167px;
    left: -24px;
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }
  .ctaBox__star.two {
    top: 120px;
  }
  .ctaBox__star.three {
    bottom: 50px;
    top: unset;
  }
  .ctaBox .button.register {
    bottom: 50px;
  }
  .ctaBox__jack.animate .ctaBox__jack-ticket.one {
    top: 7px;
    right: 33px;
  }
  .ctaBox__jack.animate .ctaBox__jack-ticket.two {
    -webkit-transform: rotate(-54deg);
            transform: rotate(-54deg);
    top: 28px;
    right: 1px;
  }
  .ctaBox__jack.animate .ctaBox__jack-ticket.three {
    top: 25px;
    left: 0;
    -webkit-transform: rotate(78deg);
            transform: rotate(78deg);
  }
  .ctaBox__jack.animate .ctaBox__jack-bg::before {
    opacity: 0;
  }
  .ctaBox__jack.animate .ctaBox__jack-bg::after {
    opacity: 1;
  }
  .ctaBox__jack.animate .ctaBox__jack-car {
    width: 97px;
  }
  .ctaBox__jack.animate .ctaBox__jack-content {
    height: 28px;
  }
  .ctaBox__jack.animate .ctaBox__jack-content p {
    font-size: 9px;
    line-height: 11px;
  }
  .ctaBox__jack.animate .ctaBox__jack-content p span {
    color: #ffe572;
  }
  .ctaBox__bonus.animate .ctaBox__bonus-cube {
    -webkit-transform: translateX(-50%) rotate(6.48deg);
            transform: translateX(-50%) rotate(6.48deg);
  }
  .ctaBox__bonus.animate .ctaBox__bonus-content {
    height: 28px;
  }
  .ctaBox__bonus.animate .ctaBox__bonus-content p {
    font-size: 9px;
    line-height: 11px;
  }
  .ctaBox__bonus.animate .ctaBox__bonus-element {
    opacity: 0;
  }
  .ctaBox__bonus.animate .ctaBox__bonus-element2 {
    opacity: 1;
  }
  .ctaBox__bonus.animate .ctaBox__bonus-star.one {
    width: 12px;
    top: 10px;
    left: 8px;
  }
  .ctaBox__bonus.animate .ctaBox__bonus-star.two {
    width: 20px;
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg);
    top: 45px;
    right: 5px;
  }
  .ctaBox__bonus.animate .ctaBox__bonus-star.three {
    opacity: 1;
    top: 12px;
    right: 21px;
  }
  .ctaBox__vip.animate .ctaBox__vip-content {
    height: 28px;
  }
  .ctaBox__vip.animate .ctaBox__vip-content p {
    font-size: 9px;
    line-height: 11px;
  }
  .ctaBox__vip.animate .ctaBox__vip-rank {
    -webkit-transform: translateY(120%) translateX(-50%);
            transform: translateY(120%) translateX(-50%);
    opacity: 0;
  }
  .ctaBox__vip.animate .ctaBox__vip-rank.two {
    -webkit-transform: translateY(0) translateX(-50%);
            transform: translateY(0) translateX(-50%);
    opacity: 1;
  }
  .ctaBox__vip.animate .ctaBox__vip-element {
    opacity: 0;
  }
  .ctaBox__vip.animate .ctaBox__vip-element.two {
    opacity: 0.5;
  }
  .cta.pred .ctaBox {
    padding: 210px 24px 150px 24px;
  }
  .cta.pred .ctaBox__guess {
    width: 256px;
    height: 253px;
    top: unset;
    bottom: -40px;
    right: 0;
  }
  .cta.pred .ctaBox__timer {
    right: unset;
    left: 24px;
    top: 155px;
  }
  .cta.pred .ctaBox__star.two {
    display: none;
  }
  .cta.pred .ctaBox__star.three {
    width: 21px;
    bottom: 109px;
    left: 17px;
  }
  .cta.pred .ctaBox__star.one {
    bottom: 20px;
    top: unset;
    left: 87px;
    width: 17px;
  }
  .cta.pred .ctaBox__ellipse {
    width: 590px;
    height: 582px;
    top: -287px;
  }
  .cta.pred .ctaBox__ellipse2 {
    width: 675px;
    height: 759px;
    top: -337px;
    left: -24px;
    background: #561c05;
    z-index: 1;
  }
  .cta.pred .ctaBox__content-btn {
    bottom: 50px;
  }

  .switchpr::before {
    display: none;
  }
  .switchpr::after {
    display: none;
  }
  .switchpr__wrapper {
    background: none;
  }
  .switchpr__inner {
    padding-bottom: 100px;
  }
  .switchprRoob__jack {
    height: 319px;
  }
  .switchprRoob__jack-image {
    right: -10px;
    width: 483px;
  }
  .switchprRoob__jack .button.green {
    bottom: 110px;
  }
  .switchprRoob__jack:hover .button.green {
    bottom: 110px;
  }
  .switchprRoob__jack:hover .switchprRoob__jack-tickets {
    -webkit-transform: translateY(150%);
            transform: translateY(150%);
  }
  .switchprRoob__jack:hover .switchprRoob__jack-image {
    width: 523px;
    right: -10px;
  }
  .switchprRoob__jack.animate .button.green {
    bottom: 110px;
  }
  .switchprRoob__jack.animate .switchprRoob__jack-tickets {
    -webkit-transform: translateY(150%);
            transform: translateY(150%);
  }
  .switchprRoob__jack.animate .switchprRoob__jack-coins {
    right: 10px;
  }
  .switchprRoob__jack.animate .switchprRoob__jack-image {
    width: 523px;
    bottom: -13px;
  }
  .switchprRoob__jack.animate .switchprRoob__jack-slot-overlay {
    background: #fff;
  }
  .switchprRoob__jack.animate .switchprRoob__jack-slot-name {
    background: #ee99ff;
  }
  .switchprRoob__jack.animate .switchprRoob__jack-slot-ticket-bg::after {
    opacity: 1;
  }
  .switchprRoob__reward.animate .switchprRoob__reward-bg2 {
    opacity: 1;
  }
  .switchprRoob__reward.animate .switchprRoob__reward-btn {
    bottom: 27px;
  }
  .switchprRoob__reward.animate .switchprRoob__reward-rank.first {
    top: 15px;
    right: 76px;
    width: 89px;
  }
  .switchprRoob__reward.animate .switchprRoob__reward-rank.second {
    -webkit-transform: rotate(-5.44deg);
            transform: rotate(-5.44deg);
    width: 68px;
    top: 39px;
    right: 161px;
    opacity: 0.5;
  }
  .switchprRoob__reward.animate .switchprRoob__reward-rank.third {
    -webkit-transform: rotate(-20.37deg);
            transform: rotate(-20.37deg);
    width: 46px;
    top: 64px;
    right: 227px;
    opacity: 0.5;
  }
  .switchprRoob__reward.animate .switchprRoob__reward-rank.fourth {
    -webkit-transform: rotate(-38.26deg);
            transform: rotate(-38.26deg);
    width: 22px;
    top: 96px;
    right: 272px;
    opacity: 0.5;
  }
  .switchprRoob__register.animate .switchprRoob__register-bg {
    opacity: 0;
  }
  .switchprRoob__register.animate .switchprRoob__register-bg2 {
    opacity: 1;
    z-index: 1;
  }
  .switchprRoob__register.animate .switchprRoob__register-image {
    height: 100%;
  }
  .switchprRoob__register.animate .switchprRoob__register-btn {
    bottom: 25px;
  }
  .switchprPack__board {
    padding: 16px 0 18px 38px;
  }
  .switchprPack__board-icon {
    left: 8px;
  }
  .switchprPack__board-content {
    position: relative;
    z-index: 2;
  }
  .switchprPack__board-content p span {
    display: none;
  }
  .switchprPack__board.animate .button.green {
    bottom: 22px;
  }
  .switchprPack__board.animate::before {
    opacity: 0;
  }
  .switchprPack__board.animate .switchprPack__board-bg3 {
    opacity: 1;
  }
  .switchprPack__board.animate .switchprPack__board-podium-first {
    width: 120px;
    height: 130px;
    opacity: 0.7;
  }
  .switchprPack__board.animate .switchprPack__board-podium-second {
    width: 120px;
    height: 88px;
    opacity: 0.7;
  }
  .switchprPack__board.animate .switchprPack__board-podium-third {
    width: 57px;
    height: 50px;
    opacity: 0.7;
  }
  .switchprPack__board.animate .switchprPack__board-star.one {
    width: 31px;
    top: 82px;
    right: 248px;
  }
  .switchprPack__board.animate .switchprPack__board-star.two {
    width: 33px;
    bottom: 30px;
    right: 15px;
  }
  .switchprPack__board.animate .switchprPack__board-star.three {
    width: 25px;
    top: 16px;
    right: 16px;
  }
  .switchprPack__register.animate .switchprPack__register-bg {
    opacity: 0;
  }
  .switchprPack__register.animate .switchprPack__register-bg2 {
    opacity: 1;
    z-index: 1;
  }
  .switchprPack__register.animate .switchprPack__register-image img {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .switchprPack__register.animate .switchprPack__register-btn {
    bottom: 25px;
  }
  .switchprPack__free .button.green {
    left: 53px;
    right: unset;
    bottom: 85px;
  }
  .switchprPack__free-cars {
    display: none;
  }
  .switchprPack__free-image img {
    margin-right: -200px;
  }
  .switchprPack__free-buy {
    right: 260px;
  }
  .switchprPack__free-gift {
    right: 32px;
  }
  .switchprPack__free:hover .switchprPack__free-buy {
    right: 300px;
  }
  .switchprPack__free:hover .switchprPack__free-gift {
    width: 144px;
  }
  .switchprPack__free.animate .switchprPack__free-image {
    width: 110%;
    height: 105%;
  }
  .switchprPack__free.animate .switchprPack__free-cars {
    right: 20px;
    width: 241px;
  }
  .switchprPack__free.animate .switchprPack__free-cars-item.first {
    width: 231px;
  }
  .switchprPack__free.animate .switchprPack__free-cars-item.second {
    width: 215px;
  }
  .switchprPack__free.animate .switchprPack__free-cars-item.third {
    width: 200px;
  }
  .switchprPack__free.animate .switchprPack__free-buy {
    right: 300px;
  }
  .switchprPack__free.animate .switchprPack__free-gift {
    width: 144px;
  }
  .switchprPack__cols {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .switchprPack__deposit {
    width: 100%;
    margin-bottom: 24px;
  }
  .switchprPack__deposit.animate .switchprPack__deposit-bg {
    top: 0;
    width: 100%;
    height: 100%;
  }
  .switchprPack__iphone {
    width: 100%;
    margin-bottom: 24px;
  }
  .switchprPack__iphone-image {
    left: -66px;
  }
  .switchprPack__iphone-content {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .switchprPack__iphone-btn {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .switchprPack__iphone-btn span::before {
    width: calc(100% + 4px);
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .switchprPack__iphone-btn span::after {
    width: 100%;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .switchprPack__iphone:hover .switchprPack__iphone-image {
    left: -73px;
  }
  .switchprPack__iphone:hover .switchprPack__iphone-btn {
    width: 228px;
  }
  .switchprPack__iphone:hover .switchprPack__iphone-btn span::before {
    width: calc(100% + 4px);
  }
  .switchprPack__iphone:hover .switchprPack__iphone-btn span::after {
    width: 100%;
  }
  .switchprPack__iphone.animate .switchprPack__iphone-image {
    top: -36px;
    left: -73px;
    width: 182px;
    height: 319px;
  }
  .switchprPack__iphone.animate .switchprPack__iphone-btn {
    width: 228px;
  }
  .switchprPack__iphone.animate .switchprPack__iphone-btn span::before {
    width: calc(100% + 4px);
  }
  .switchprPack__iphone.animate .switchprPack__iphone-btn span::after {
    width: 100%;
  }
  .switchprPack__rolex {
    width: 100%;
  }
  .switchprPack__rolex-image {
    left: -40px;
  }
  .switchprPack__rolex-content {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .switchprPack__rolex-btn {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .switchprPack__rolex-btn span::before {
    width: calc(100% + 4px);
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .switchprPack__rolex-btn span::after {
    width: 100%;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .switchprPack__rolex:hover .offr__rolex-image {
    left: -50px;
  }
  .switchprPack__rolex:hover .offr__rolex-btn {
    width: 228px;
  }
  .switchprPack__rolex:hover .offr__rolex-btn span::before {
    width: calc(100% + 4px);
  }
  .switchprPack__rolex:hover .offr__rolex-btn span::after {
    width: 100%;
  }
  .switchprPack__rolex.animate .switchprPack__rolex-image {
    top: -37px;
    left: -50px;
    width: 181px;
  }
  .switchprPack__rolex.animate .switchprPack__rolex-btn {
    width: 228px;
  }
  .switchprPack__rolex.animate .switchprPack__rolex-btn span::before {
    width: calc(100% + 4px);
  }
  .switchprPack__rolex.animate .switchprPack__rolex-btn span::after {
    width: 100%;
  }
  .switchprPack__rolex.animate .switchprPack__rolex-content {
    top: 14px;
  }

  .modalOffer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .modalOffer__close {
    top: 16px;
    right: 16px;
  }
  .modalOffer__content {
    width: 100%;
    max-width: unset;
    border-radius: 5px 5px 0 0;
    padding: 32px 24px 24px 24px;
  }
  .modalOffer__content-head {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .modalOffer__content-head-image {
    margin-bottom: 0;
    margin-right: 10px;
  }
  .modalOffer__info {
    width: 100%;
    max-width: unset;
    border-radius: 0 0 5px 5px;
    padding: 24px;
  }
  .modalOffer__info-title {
    margin-bottom: 24px;
  }
  .modalOffer__info-desc {
    margin-bottom: 24px;
  }
  .modalOffer__info-btn {
    position: relative;
    bottom: unset;
    left: unset;
    width: 100%;
  }
  .modal__inner {
    width: 100%;
    height: 100%;
    max-width: unset;
    padding: 24px;
    background: #1f1d47;
    border: 0;
    margin: 0;
  }
  .modal__inner::before {
    position: absolute;
    top: -6px;
    left: 0;
    width: 100%;
    height: 85px;
    content: "";
    background: -webkit-gradient(linear, left top, left bottom, from(#181835), color-stop(17.65%, #1f1d47));
    background: linear-gradient(180deg, #181835 0%, #1f1d47 17.65%);
    z-index: -1;
  }
  .modal__inner-title {
    max-width: 428px;
    margin: 0 auto 40px auto;
  }
  .modal__inner-title.mb {
    margin-bottom: 40px;
  }
  .modal__inner .button.continue {
    width: 100%;
    position: relative;
    bottom: unset;
    left: unset;
    margin-top: 32px;
  }
  .modal__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 23px 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    margin-bottom: 5px;
  }
  .modal__head-logo {
    width: 41px;
    height: 43px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .modal__head-logo img {
    width: 100%;
    height: 100%;
  }
  .modal__head-close {
    cursor: pointer;
    background: #201f45;
    border: 1px solid #181835;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 42px;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .modal__head-close svg {
    width: 17px;
    height: 17px;
    color: #8988bc;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .modal__head-close:hover {
    background: #4c4a92;
  }
  .modal__head-close:hover svg {
    color: #ffffff;
  }
  .modalProgress {
    max-width: 428px;
    margin: 0 auto 26px auto;
  }
  .modalProgress__step {
    width: 32px;
    height: 32px;
  }
  .modalProgress__steps {
    width: calc(100% - 130px);
  }
  .modalSteps {
    max-width: 428px;
    margin: 0 auto;
  }
  .modal.addClass {
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .modal.newHead {
    top: 89px;
    height: calc(100% - 89px);
  }
  .modalStep .button {
    height: 44px;
  }
  .modalProfile__btn {
    width: 150px;
    height: 40px;
  }
  .modalProfile__btn p {
    font-size: 18px;
    line-height: 22px;
  }
  .modalProfile__inner {
    padding: 32px 24px;
  }
  .modalProfile__vip-desc p {
    font-size: 16px;
    line-height: 20px;
  }
  .modalProfile__prof-row {
    margin-bottom: 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .modalProfile__prof-avatar {
    margin: 0 auto 24px auto;
    position: relative;
    width: 205px;
  }
  .modalProfile__prof-avatar-btns {
    margin-left: 0;
    position: absolute;
    right: -49px;
    top: 0;
  }
  .modalProfile__prof-info {
    margin: 0 auto;
  }
  .modalProfile__prof-content-item-row-inner {
    width: calc(100% - 160px);
  }
  .modalProfile__prof-content-item-row .button.link {
    width: 80px;
  }
  .modalProfile__prof-content-item-btn {
    width: 150px;
  }
  .modalProfile__prof-content-item-input {
    width: calc(100% - 90px);
  }
  .modalJackpot {
    padding: 60px 24px 40px 24px;
  }
  .modalJackpot__icon {
    width: 42px;
    height: 42px;
  }
  .modalJackpot__icon svg {
    width: 24px;
    height: 24px;
  }
  .modalJackpot__head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 32px;
  }
  .modalJackpot__head h3 {
    margin-bottom: 16px;
  }
  .modalJackpot__head-seperator {
    display: none;
  }
  .modalJackpot__row {
    padding: 10px 24px;
  }
  .modalJackpot__row p {
    font-size: 14px;
    max-width: unset;
  }
  .modalJackpot__desc {
    padding: 16px 24px;
  }
  .modalJackpot__desc P {
    font-size: 14px;
  }
  .modalJackpot__desc ul li {
    font-size: 14px;
  }
  .modalJackpot__disclaimer {
    padding: 16px 24px;
  }
  .modalJackpot__disclaimer P {
    font-size: 14px;
  }
  .modalJackpot__disclaimer ul li {
    font-size: 14px;
  }
  .modalShop__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .modalShop__content-image-outer {
    width: 204px;
    margin: 0 auto 32px auto;
  }
  .modalShop__content-info {
    width: 100%;
  }
  .modalBonus__title {
    padding: 20px 24px;
  }
  .modalBonus__close {
    top: 22px;
  }
  .modalBonus__action {
    padding: 24px;
  }
  .modalBonus__footer {
    padding: 16px 24px;
  }
  .modalBonus__footer-item {
    margin: 10px 16px 10px 0;
  }
  .modalBonus__footer-desc {
    border-radius: 10px;
    padding: 16px 24px;
  }
  .modalBonus__footer-desc p {
    width: calc(100% - 40px);
    font-size: 14px;
  }
  .modalBonus__footer .button.play {
    height: 64px;
  }
  .modalRoob {
    padding: 40px 24px;
  }
  .modalRoob__close {
    top: 16px;
    right: 16px;
  }
  .modalRoob__inner-title img {
    width: 125px;
  }
  .modalRoob__inner-title p {
    font-size: 18px;
  }
  .modalRoob__step1-content {
    margin-left: 24px;
  }
  .modalRoob__step1-content h5 {
    text-align: left;
  }
  .modalRoob__step2-bg2 {
    opacity: 0;
  }
  .modalRoob__step3-bg {
    opacity: 0;
  }
  .modalRoob__step3-content {
    margin-left: 24px;
  }
  .modalPack {
    padding: 40px 24px;
  }
  .modalPack__close {
    top: 16px;
    right: 16px;
  }
  .modalPack__inner-title img {
    width: 125px;
    height: 50px;
  }
  .modalPack__inner-title p {
    font-size: 18px;
  }
  .modalPack__step1-content {
    margin-left: 24px;
  }
  .modalPack__step1-content h5 {
    text-align: left;
  }
  .modalPack__step2-bg2 {
    opacity: 0;
  }
  .modalPack__step3-bg {
    opacity: 0;
  }
  .modalPack__step3-content {
    margin-left: 24px;
  }
  .modalLogin {
    max-width: 428px;
    margin: 0 auto;
  }

  .ticketh__inner {
    padding: 130px 0 130px 0;
  }
  .ticketh__table-row:first-child .ticketh__table-number {
    width: 52px;
    height: 52px;
  }
  .ticketh__table-head {
    padding: 0 18px 0 70px;
  }
  .ticketh__table-head-item p {
    font-size: 14px;
  }
  .ticketh__table-number {
    width: 52px;
    height: 52px;
  }
  .ticketh__table-items {
    width: calc(100% - 52px);
    padding: 0 18px;
  }
  .ticketh__table-item p {
    font-size: 13px !important;
  }
  .ticketh__table-item-prize h6 {
    font-size: 15.4123px;
  }
  .ticketh__table-item-prize-car {
    width: 60px;
    margin-left: 5px;
  }
  .ticketh__table-item-tickets {
    margin-right: 5px;
  }
  .ticketh__table-item-tickets h6 {
    font-size: 15.4123px;
  }
  .ticketh__table-item-tickets img {
    width: 22px;
    height: 22px;
  }
  .ticketh.pack .ticketh__inner {
    padding: 45px 0 130px 0;
  }
  .ticketh.pack .ticketh__table-items {
    padding: 0 18px;
  }
  .ticketh.pack .ticketh__table-head {
    padding: 0 18px 0 70px;
  }

  .ticketj__inner {
    padding: 130px 0 130px 0;
  }
  .ticketj__table-row:first-child .ticketj__table-number {
    width: 52px;
  }
  .ticketj__table-head {
    padding: 0 18px 0 70px;
  }
  .ticketj__table-head-item {
    width: 40%;
  }
  .ticketj__table-head-item:first-child {
    width: 20%;
  }
  .ticketj__table-head-item p {
    font-size: 14px;
  }
  .ticketj__table-number {
    width: 52px;
    height: 52px;
  }
  .ticketj__table-items {
    width: calc(100% - 52px);
    padding: 0 18px;
  }
  .ticketj__table-item {
    width: 40%;
  }
  .ticketj__table-item:first-child {
    width: 20%;
  }
  .ticketj__table-item p {
    font-size: 13px !important;
  }
  .ticketj__table-item-prize {
    width: 100px;
  }
  .ticketj__table-item-prize-car {
    width: 210px;
    height: unset;
  }
  .ticketj__table-item-rolex {
    width: 100px;
  }
  .ticketj__table-item-rolex-image {
    width: 80px;
  }
  .ticketj__table-item-eth {
    width: 100px;
  }
  .ticketj__table-item-eth-box {
    width: 80px;
    padding-left: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .ticketj__table-item-eth-box h6 {
    font-size: 16px;
  }
  .ticketj__table-item-eth-box img {
    width: 30px;
  }
  .ticketj__table-item-shard {
    width: 100px;
  }
  .ticketj__table-item-shard-box {
    width: 80px;
    padding-left: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .ticketj__table-item-shard-box h6 {
    font-size: 16px;
  }
  .ticketj__table-item-shard-box img {
    width: 28px;
  }
  .ticketj__table-item-tickets h6 {
    font-size: 15.4123px;
  }
  .ticketj__table-item-tickets img {
    width: 22px;
    height: 22px;
  }
  .ticketj.pack .ticketj__inner {
    padding: 45px 0 130px 0;
  }
  .ticketj.pack .ticketj__table-items {
    padding: 0 18px;
  }
  .ticketj.pack .ticketj__table-head {
    padding: 0 18px 0 70px;
  }

  .leaderboard__inner {
    padding: 180px 0 85px 0;
  }

  .guessContent {
    padding: 0;
    background: none;
    border: unset;
  }
  .guessContent__wheel {
    display: none;
  }
  .guessContent__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .guessContent__title {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 50px;
  }
  .guessContent__title h1 {
    text-align: center;
  }
  .guessContent__title h2 {
    text-align: center;
  }
  .guessContent__info {
    margin-bottom: 140px;
  }
  .guessContent__info p {
    text-align: center;
  }
  .guessContent__action {
    width: 100%;
  }
  .guessContent__action-auth {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .guessContent__action-auth h6 {
    margin: 16px 0;
  }
  .guess__inner {
    padding: 130px 0 470px 0;
  }
  .guess__coins {
    height: 305px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    overflow: hidden;
  }
  .guess__coins img {
    width: unset;
    height: 100%;
  }

  .store__image {
    display: none;
  }
  .store__image2 {
    position: absolute;
    top: 300px;
    left: 0;
    width: 100%;
    height: 489px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .store__image2 img {
    height: 100%;
  }
  .store__star.one {
    top: 220px;
    width: 18px;
    -webkit-filter: blur(0.501157px);
            filter: blur(0.501157px);
    left: calc(50% + 150px);
    -webkit-transform: rotate(25.05deg) translateX(-50%);
            transform: rotate(25.05deg) translateX(-50%);
  }
  .store__star.two {
    display: none;
  }
  .store__star.three {
    width: 37px;
    top: 600px;
    -webkit-filter: blur(0.501157px);
            filter: blur(0.501157px);
    left: calc(50% - 150px);
    -webkit-transform: rotate(-20.15deg) translateX(-50%);
            transform: rotate(-20.15deg) translateX(-50%);
  }
  .store__inner {
    padding: 170px 0 110px 0;
  }
  .store__inner h1 {
    text-align: center;
  }
  .store__top-title {
    display: none;
  }
  .store__top-items {
    margin-bottom: 124px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .store__top-item {
    width: 100%;
    max-width: 355px;
    min-height: unset;
    margin-bottom: 24px;
  }
  .store__top-item:last-child {
    margin-bottom: 0;
  }
  .store__top-item-image-outer {
    width: 110px;
  }
  .store__top-item-content-title h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .store__top-item-content-title p {
    font-size: 7.5px;
    line-height: 9px;
  }
  .store__top-item-content-stock p {
    font-size: 7.5px;
    line-height: 9px;
  }
  .store__top-item .button.buy {
    min-height: 28px;
  }
  .store__top-item .button.buy p {
    font-size: 15px;
  }
  .store__top-item .button.buy img {
    width: 14px;
    height: 24px;
  }
  .store__earn {
    width: calc(100% + 48px);
    margin: 0 -24px;
    border-radius: unset;
  }
  .store__earn-inner {
    padding: 80px 0 130px 0;
  }
  .store__earn-info {
    width: 220px;
    margin: 0 auto 70px auto;
  }
  .store__earn-info h2 {
    text-align: center;
  }
  .store__earn-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .store__earn-item {
    width: 204px;
    min-height: 112px;
    margin-bottom: 24px;
  }
  .store__earn-item:last-child {
    margin-bottom: 0;
  }
  .store__earn-shard.one {
    width: 57px;
    bottom: -35px;
    left: calc(50% - 10px);
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .store__earn-shard.two {
    width: 48px;
    bottom: -55px;
    left: calc(50% + 50px);
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .store__earn-shard.three {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 57px;
    bottom: -62px;
    left: calc(50% - 60px);
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .store__earn-shard.three img {
    -webkit-transform: rotate(-9deg);
            transform: rotate(-9deg);
  }
  .store__prize {
    width: calc(100% + 48px);
    margin: 0 -24px;
  }
  .store__prize-item {
    width: calc(33.3334% - 16px);
  }

  .notify {
    margin-right: 0;
    position: static;
  }
  .notify__btn {
    width: 45px;
    height: 45px;
    border-radius: 20px;
  }
  .notify__btn svg {
    width: 16px;
    height: 18px;
  }
  .notify__menu {
    display: none;
    position: fixed;
    z-index: 105;
    width: 100%;
    right: unset;
    left: 0;
    top: 89px;
    height: calc(100% - 89px);
    border-radius: 0;
    padding: 0;
    background: #1f1d47;
  }
  .notify__menu-head {
    display: none;
  }
  .notify__menu-inner {
    max-height: unset;
    height: 100%;
    padding: 30px 24px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .notify__menu::before {
    position: absolute;
    top: -6px;
    left: 0;
    width: 100%;
    height: 85px;
    content: "";
    background: -webkit-gradient(linear, left top, left bottom, from(#181835), color-stop(17.65%, #1f1d47));
    background: linear-gradient(180deg, #181835 0%, #1f1d47 17.65%);
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    z-index: unset;
    right: unset;
  }
  .notify__menu.mod {
    display: block;
  }

  .profile .auto__container {
    padding: 0 24px;
  }
  .profile__title {
    display: none;
  }
  .profile__inner {
    padding: 0 0 150px 0;
  }
  .profile__btns {
    width: calc(100% + 48px);
    margin: 0 -24px;
    border-radius: 0;
    padding: 25px 24px 15px 24px;
    position: relative;
  }
  .profile__btns::before {
    position: absolute;
    top: -6px;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(#181835), color-stop(17.65%, #1f1d47));
    background: linear-gradient(180deg, #181835 0%, #1f1d47 17.65%);
    content: "";
  }
  .profile__btn {
    border-radius: 20px;
    max-width: 77px;
    padding: 10px 0;
  }
  .profile__btn p {
    font-size: 15px;
    line-height: 18px;
  }
  .profile__vip {
    margin-top: 79px;
  }
  .profile__vip-content {
    display: block;
  }
  .profile__vip-inner {
    width: calc(100% + 48px);
    margin: 0 -24px;
    margin-bottom: 34px;
  }
  .profile__vip-desc {
    width: 100%;
    padding: 40px 40px 64px 40px;
  }
  .profile__vip-desc h6 {
    margin-bottom: 30px;
    font-size: 16px;
  }
  .profile__vip-desc p {
    font-size: 15px;
    line-height: 18px;
  }
  .profile__vip-slider-outer {
    background: none;
    border-top: 1px solid #313062;
    border-bottom: 1px solid #313062;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 0;
  }
  .profile__vip-slider-wrapper-bg {
    display: none;
  }
  .profile__vip-rewards {
    max-width: unset;
  }
  .profile__vip-rewards-item-info {
    width: calc(60% - 1px);
    padding: 5px 0 5px 60px;
  }
  .profile__vip-rewards-item-info p {
    font-size: 16px;
  }
  .profile__vip-rewards-item-icon {
    left: calc(60% + 3px);
  }
  .profile__vip-rewards-item-price {
    width: calc(40% - 1px);
  }
  .profile__vip-rewards-item-price p {
    font-size: 18px;
  }
  .profileSec__password {
    max-width: unset;
    margin-bottom: 30px;
  }
  .profileSec__password-content {
    margin: 20px auto 0 auto;
    padding: 32px 32px 40px 32px;
  }
  .profileSec__password-desc {
    margin-bottom: 56px;
  }
  .profileSec__password-desc p {
    font-size: 14px;
    line-height: 17px;
  }
  .profileSec__email {
    max-width: unset;
    margin-bottom: 30px;
  }
  .profileSec__email-content {
    padding: 32px 32px 40px 32px;
  }
  .profileSec__confirm {
    max-width: unset;
  }
  .profileSec__confirm-title {
    margin-bottom: 8px;
  }
  .profileSec__confirm-title h6 {
    font-size: 15px;
    line-height: 18px;
  }
  .profileSec__confirm-content {
    padding: 32px 32px 40px 32px;
  }
  .profileSec__btn {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-radius: 20px;
    padding: 24px 38px;
  }
  .profileSec__btn img {
    width: 28px;
    height: 28px;
    margin-right: 0;
  }
  .profileSec__btn h6 {
    font-size: 20px;
    line-height: 24px;
  }
  .profileSec__inputs {
    margin-bottom: 80px;
  }
  .profileSec__inputs .input__outer {
    margin-bottom: 24px;
  }
  .profileSec__inputs .input__outer label {
    font-size: 15px;
    line-height: 18px;
  }
  .profileSec__inputs .input__outer:first-child {
    margin-bottom: 57px;
  }
  .profileSec__inputs .input input {
    padding: 16px 38px 16px 18px;
    border-radius: 20px;
    font-size: 14px;
  }
  .profileSec__change {
    border-radius: 20px;
    padding: 27px 0 28px 0;
  }
  .profileSec__change p {
    font-size: 15px;
    line-height: 18px;
  }
  .profileAccount {
    margin-top: 70px;
    border: 0;
    padding: 0;
  }
  .profileAccount__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .profileAccount__content {
    width: 100%;
    border: 0;
    padding: 0;
    margin-bottom: 118px;
  }
  .profileAccount__vip {
    width: 100%;
  }
  .profileAccount__title {
    display: none;
  }
  .profileAccount__info {
    padding: 22px 0 28px 0;
    max-width: unset;
    margin-bottom: 72px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
  }
  .profileAccount__info-content {
    width: unset;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .profileAccount__info-name h4 {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
  }
  .profileAccount__info-rank {
    padding: 4px 14px;
    min-width: 96px;
  }
  .profileAccount__info-rank-image {
    height: 19px;
  }
  .profileAccount__info-rank h6 {
    font-size: 14px;
    line-height: 17px;
  }
  .profileAccount__avatar {
    width: unset;
    margin-bottom: 4px;
  }
  .profileAccount__avatar-btns {
    margin-left: 0;
    position: absolute;
    top: 10px;
    right: 18px;
  }
  .profileAccount__avatar-btn {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  .profileAccount__avatar-btn.edit svg {
    width: 21px;
    height: 21px;
  }
  .profileAccount__avatar-btn svg {
    width: 16px;
    height: 16px;
  }
  .profileAccount__links {
    max-width: unset;
  }
  .profileAccount__link {
    margin-bottom: 25px;
  }
  .profileAccount__link-title {
    margin-bottom: 7px;
  }
  .profileAccount__link-title p {
    font-size: 15px;
    line-height: 18px;
  }
  .profileAccount__link-input {
    border-radius: 20px;
  }
  .profileAccount__link-input input {
    font-size: 15px;
    line-height: 18px;
    padding: 17px 67px;
  }
  .profileAccount__link-input-icon {
    width: 53px;
  }
  .profileAccount__link-input-icon svg {
    width: 28px;
    height: 28px;
  }
  .profileAccount__link-input-linked {
    width: 14px;
    height: 14px;
    right: 22px;
  }
  .profileAccount__link-row .button.link p {
    font-size: 15px;
    line-height: 18px;
  }

  .vippRew .vipp__register {
    width: calc(100% - 48px);
    margin-left: 24px;
  }
  .vippRew .vipp__roob {
    width: calc(100% - 48px);
    margin-left: 24px;
  }
  .vippRew .vipp__rew {
    padding: 0 24px 60px 24px;
  }
  .vippProg__box {
    padding: 42px 24px 70px 24px;
  }
}
@media (max-width: 600px) {
  .offers__inner {
    padding: 45px 0;
  }
  .offersItem {
    padding: 108px 12px 15px 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .offersItem__btn {
    width: 100%;
    position: relative;
    top: unset;
    right: unset;
  }
  .offersItem__content {
    margin-bottom: 21px;
    height: unset;
    background: none;
    border-radius: unset;
  }
  .offersItem__content-cols {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .offersItem__content-col {
    padding: 14px 13px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 100%;
    background: rgba(150, 149, 206, 0.3);
    border-radius: 8px;
    margin-bottom: 11px;
    min-height: 43px;
  }
  .offersItem__content-col:last-child {
    margin-bottom: 0;
  }
  .offersItem__content-col h6 {
    margin-bottom: 0;
    margin-right: 8px;
  }
  .offersItem__content-image {
    top: 15px;
  }
  .offersItem__icon {
    top: 15px;
    left: 298px;
  }

  .modalOffer__content-item {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: unset;
    min-height: 70px;
    padding: 10px 12px;
  }
  .modalOffer__content-item-icon {
    margin-right: 12px;
  }
  .modalOffer__content-head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .modalOffer__content-head-image {
    margin-bottom: 24px;
    margin-right: 0;
  }
  .modalProfile__vip-slider {
    max-width: 380px;
  }
  .modalProfile__sec-email-title {
    margin-bottom: 24px;
  }
  .modalProfile__sec-email .input__outer {
    max-width: unset;
  }
  .modalProfile__sec-email .button.link {
    bottom: unset;
    top: 10px;
    right: 18px;
  }
  .modalProfile__sec-pass .input__outer {
    max-width: unset;
  }
  .modalProfile__sec-pass .button.link {
    bottom: unset;
    top: 10px;
    right: 18px;
  }
  .modalBonus__action-btns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .modalBonus__action-btns h6 {
    margin-bottom: 16px;
  }
  .modalBonus__action-btn {
    width: 100% !important;
    max-width: unset;
    margin-bottom: 16px;
  }
  .modalBonus__action-btn:last-child {
    margin-bottom: 0;
  }

  .map {
    height: unset;
    margin-left: -34px;
  }
  .map__outer {
    height: 3200px;
  }
  .mapItem {
    border-radius: 20px !important;
  }
  .mapItem__reward h5 {
    font-size: 15px;
  }
  .mapItem__claim p {
    font-size: 15px;
  }
  .mapItem__claim svg {
    width: 24px;
    height: 17px;
  }
  .mapItem__content-body h6 {
    font-size: 16px;
    line-height: 20px;
  }
  .mapItem__content-body p {
    font-size: 12px;
    line-height: 14px;
  }
  .mapItem__lock {
    width: 16px;
    height: 23px;
  }
  .mapItem.step-0 {
    padding-top: 27px;
    min-width: 80px;
    height: 60px;
    left: calc(50% - 20px);
    top: 66px;
  }
  .mapItem.step-0 .mapItem__image {
    top: -27px;
    width: 49px;
    height: unset;
  }
  .mapItem.step-1 {
    padding-top: 27px;
    min-width: 80px;
    height: 60px;
    left: calc(50% - 94px);
    top: 234px;
  }
  .mapItem.step-1 .mapItem__image {
    top: -27px;
    width: 49px;
    height: unset;
  }
  .mapItem.step-2 {
    padding-top: 30px;
    min-width: 80px;
    height: 60px;
    left: calc(50% + 56px);
    top: 339px;
  }
  .mapItem.step-2 .mapItem__image {
    top: -28px;
    width: 39px;
    height: unset;
  }
  .mapItem.step-3 {
    padding-top: 30px;
    min-width: 80px;
    height: 60px;
    left: calc(50% - 12px);
    top: 500px;
  }
  .mapItem.step-3 .mapItem__image {
    top: -28px;
    width: 39px;
    height: unset;
  }
  .mapItem.step-4 {
    padding-top: 30px;
    min-width: 96px;
    height: 61px;
    left: calc(50% + 29px);
    top: 679px;
  }
  .mapItem.step-4 .mapItem__image {
    top: -36px;
    width: 39px;
    height: unset;
  }
  .mapItem.step-5 {
    padding-top: 39px;
    min-width: 96px;
    height: 73px;
    right: calc(50% - 203px);
    top: 878px;
  }
  .mapItem.step-5 .mapItem__image {
    top: -26px;
    width: 53px;
    height: unset;
  }
  .mapItem.step-6 {
    padding-top: 39px;
    min-width: 96px;
    height: 73px;
    left: calc(50% - 158px);
    top: 967px;
  }
  .mapItem.step-6 .mapItem__image {
    top: -40px;
    width: 53px;
    height: unset;
  }
  .mapItem.step-7 {
    padding-top: 35px;
    min-width: 96px;
    height: 73px;
    right: calc(50% - 184px);
    top: 1065px;
  }
  .mapItem.step-7 .mapItem__image {
    top: -32px;
    width: 53px;
    height: unset;
  }
  .mapItem.step-8 {
    padding-top: 35px;
    min-width: 96px;
    height: 73px;
    left: calc(50% - 140px);
    top: 1218px;
  }
  .mapItem.step-8 .mapItem__image {
    top: -32px;
    width: 53px;
    height: unset;
  }
  .mapItem.step-9 {
    padding-top: 45px;
    min-width: 96px;
    height: 73px;
    left: calc(50% - 53px);
    top: 1458px;
  }
  .mapItem.step-9 .mapItem__image {
    top: -27px;
    width: 61px;
    height: unset;
  }
  .mapItem.step-10 {
    padding-top: 45px;
    min-width: 96px;
    height: 73px;
    left: calc(50% - 90px);
    top: 1705px;
  }
  .mapItem.step-10 .mapItem__image {
    top: -25px;
    width: 62px;
    height: unset;
  }
  .mapItem.step-11 {
    padding-top: 48px;
    min-width: 112px;
    height: 73px;
    right: calc(50% - 220px);
    top: 1779px;
  }
  .mapItem.step-11 .mapItem__image {
    top: -34px;
    width: 77px;
    height: unset;
  }
  .mapItem.step-12 {
    padding-top: 62px;
    min-width: 145px;
    height: 94px;
    right: calc(50% - 123px);
    top: 2210px;
  }
  .mapItem.step-12 .mapItem__image {
    top: -47px;
    width: 101px;
    height: unset;
  }
  .mapItem.step-12 .mapItem__reward h5 {
    font-size: 20px;
  }
  .mapItem:hover {
    height: unset;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .mapItem:hover .mapItem__lock {
    display: none;
  }
  .mapItem:hover .mapItem__reward {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .mapItem:hover .mapItem__content-body {
    display: none;
  }
  .mapItem:hover.step-0 {
    height: 60px;
  }
  .mapItem:hover.step-1 {
    height: 60px;
  }
  .mapItem:hover.step-2 {
    height: 60px;
  }
  .mapItem:hover.step-3 {
    height: 60px;
  }
  .mapItem:hover.step-4 {
    height: 61px;
  }
  .mapItem:hover.step-5 {
    height: 73px;
  }
  .mapItem:hover.step-6 {
    height: 73px;
  }
  .mapItem:hover.step-7 {
    height: 73px;
  }
  .mapItem:hover.step-8 {
    height: 73px;
  }
  .mapItem:hover.step-9 {
    height: 73px;
  }
  .mapItem:hover.step-10 {
    height: 73px;
  }
  .mapItem:hover.step-11 {
    height: 73px;
  }
  .mapItem:hover.step-12 {
    height: 94px;
  }
  .mapItem.claim:hover {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(41, 193, 41, 0.25)), to(rgba(41, 193, 41, 0.5)));
    background: linear-gradient(180deg, rgba(41, 193, 41, 0.25) 0%, rgba(41, 193, 41, 0.5) 100%);
    border: 1.49075px solid rgba(41, 193, 41, 0.5);
    -webkit-box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
            box-shadow: 0px 2.98151px 5.96302px rgba(0, 37, 64, 0.25);
  }
  .mapItem.claim:hover .mapItem__image img {
    scale: 1;
  }
  .mapItem.claim:hover .mapItem__claim p {
    font-size: 15px;
    color: #d9ff42;
  }
  .mapItem.claim:hover.step-0 {
    padding-top: 27px;
    min-width: 80px;
    height: 60px;
  }
  .mapItem.claim:hover.step-1 {
    padding-top: 27px;
    min-width: 80px;
    height: 60px;
  }
  .mapItem.claim:hover.step-2 {
    padding-top: 30px;
    min-width: 80px;
    height: 60px;
  }
  .mapItem.claim:hover.step-3 {
    padding-top: 30px;
    min-width: 80px;
    height: 60px;
  }
  .mapItem.claim:hover.step-4 {
    padding-top: 30px;
    min-width: 96px;
    height: 61px;
  }
  .mapItem.claim:hover.step-5 {
    padding-top: 39px;
    min-width: 96px;
    height: 73px;
  }
  .mapItem.claim:hover.step-6 {
    padding-top: 39px;
    min-width: 96px;
    height: 73px;
  }
  .mapItem.claim:hover.step-7 {
    padding-top: 35px;
    min-width: 96px;
    height: 73px;
  }
  .mapItem.claim:hover.step-8 {
    padding-top: 35px;
    min-width: 96px;
    height: 73px;
  }
  .mapItem.claim:hover.step-9 {
    padding-top: 45px;
    min-width: 96px;
    height: 73px;
  }
  .mapItem.claim:hover.step-10 {
    padding-top: 45px;
    min-width: 96px;
    height: 73px;
  }
  .mapItem.claim:hover.step-11 {
    padding-top: 48px;
    min-width: 112px;
    height: 73px;
  }
  .mapItem.claim:hover.step-12 {
    padding-top: 62px;
    min-width: 145px;
    height: 94px;
  }
  .mapShard__lock {
    width: 16px;
    height: 23px;
  }
  .mapShard__image {
    height: unset;
    width: 19px;
  }
  .mapShard__image2 {
    height: unset;
    width: 19px;
  }
  .mapShard__claimed {
    height: unset;
    width: 19px;
  }
  .mapShard__content-top {
    margin-bottom: 8px;
  }
  .mapShard__content-shards p {
    font-size: 14px;
  }
  .mapShard__content-wager p {
    font-size: 14px;
  }
  .mapShard__content-claim p {
    font-size: 20px;
  }
  .mapShard.step-1a {
    top: 274px;
    left: calc(50% + 20px);
  }
  .mapShard.step-2a {
    top: 425px;
    left: calc(50% - 72px);
  }
  .mapShard.step-3a {
    top: 579px;
    left: calc(50% - 66px);
  }
  .mapShard.step-3b {
    top: 634px;
    right: calc(50% - 145px);
  }
  .mapShard.step-4a {
    top: 761px;
    left: calc(50% - 126px);
  }
  .mapShard.step-4b {
    top: 828px;
    left: calc(50% - 15px);
  }
  .mapShard.step-5a {
    top: 955px;
    left: calc(50% - 4px);
  }
  .mapShard.step-6a {
    top: 994px;
    right: calc(50% - 151px);
  }
  .mapShard.step-7a {
    top: 1173px;
    right: calc(50% - 105px);
  }
  .mapShard.step-8a {
    top: 1327px;
    right: calc(50% - 48px);
  }
  .mapShard.step-8b {
    top: 1393px;
    left: calc(50% - 84px);
  }
  .mapShard.step-9a {
    top: 1573px;
    left: calc(50% - 73px);
  }
  .mapShard.step-9b {
    top: 1647px;
    right: calc(50% - 54px);
  }
  .mapShard.step-10a {
    top: 1900px;
    right: calc(50% - 153px);
  }
  .mapShard.step-10b {
    top: 2021px;
    right: calc(50% - 56px);
  }
  .mapShard.step-10c {
    top: 2094px;
    left: calc(50% - 92px);
  }
  .mapShard:hover {
    padding: 0;
    background: none;
    border: unset;
    -webkit-box-shadow: unset;
            box-shadow: unset;
    border-radius: unset;
  }
  .mapShard:hover .mapShard__lock {
    display: none;
  }
  .mapShard:hover .mapShard__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .mapShard:hover .mapShard__image2 {
    display: none;
  }
  .mapShard:hover .mapShard__content {
    display: none;
  }
  .mapShard.claim:hover {
    padding: 0;
    background: none;
    border: unset;
    -webkit-box-shadow: unset;
            box-shadow: unset;
    border-radius: unset;
  }
  .mapShard.claimed:hover {
    padding: 0;
    background: none;
    border: unset;
    -webkit-box-shadow: unset;
            box-shadow: unset;
    border-radius: unset;
  }
  .map .vipp__register {
    left: calc(50% + 34px);
  }
  .map .vipp__roob {
    left: calc(50% + 34px);
  }

  .vipp__bg {
    background: -webkit-gradient(linear, left top, left bottom, from(#031627), color-stop(18.19%, #002641), color-stop(43%, #150152), color-stop(65%, #450041), color-stop(98.8%, #722800)), #0a0a17;
    background: linear-gradient(180deg, #031627 0%, #002641 18.19%, #150152 43%, #450041 65%, #722800 98.8%), #0a0a17;
  }
  .vipp__bg::before {
    display: none;
  }
  .vippMap__popup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 540px) {
  .Toastify__toast-container {
    width: calc(100% - 26px);
    left: 13px;
  }

  h3 {
    font-size: 15px;
    line-height: 18px;
  }

  .auto__container {
    padding: 0 13px;
  }

  .header .auto__container {
    padding: 0 24px;
  }

  .footer .auto__container {
    padding: 0 24px;
  }
  .footer__links-inner {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .footer__links-col {
    margin-right: 0;
  }

  .vipReg__inner {
    padding: 24px 0 40px 0;
  }
  .vipReg__form-btn {
    width: 140px;
  }
  .vipReg__form-input {
    width: calc(100% - 150px);
  }

  .latest__inner {
    padding: 45px 0 80px 0;
  }
  .latest__slider .slick-prev {
    width: 28px;
    height: 28px;
    right: 46px;
    border-radius: 5px;
  }
  .latest__slider .slick-next {
    width: 28px;
    height: 28px;
    border-radius: 5px;
  }
  .latest__slider .slick-slide.slick-active::before {
    opacity: 1;
  }
  .latest__slider .slick-slide.slick-current::before {
    opacity: 0;
  }

  .offr__inner {
    padding: 70px 0 45px 0;
  }
  .offr__inner h3 {
    margin-bottom: 24px;
  }
  .offr__rolex-content {
    right: 36px;
    left: unset;
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }

  .ctaBox {
    height: 398px;
  }
  .ctaBox__content {
    margin-bottom: 10px;
  }
  .ctaBox__content h1 {
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .ctaBox__content p {
    font-size: 10px;
    line-height: 20px;
  }
  .ctaBox__ellipse {
    left: -135px;
  }
  .ctaBox__ellipse2 {
    left: -160px;
  }
  .ctaBox__character {
    width: 155px;
  }
  .ctaBox__star.one {
    bottom: 38px;
    top: unset;
    left: 259px;
    width: 17px;
  }
  .ctaBox__star.two {
    width: 10px;
    bottom: 26px;
    top: unset;
    left: 104px;
  }
  .ctaBox__star.three {
    width: 21px;
    bottom: 114px;
    left: 17px;
  }
  .ctaBox__items {
    max-width: 339px;
  }
  .ctaBox__jack {
    width: calc(33.3334% - 4px);
  }
  .ctaBox__bonus {
    width: calc(33.3334% - 4px);
  }
  .ctaBox__vip {
    width: calc(33.3334% - 4px);
  }
  .cta.kick .ctaBox__character {
    width: 192px;
    bottom: -16px;
  }
  .cta.kick .ctaBox__content {
    padding-top: 20px;
    margin-right: unset;
    margin-bottom: 16px;
  }
  .cta.kick .ctaBox__content-btn {
    bottom: 60px;
  }
  .cta.kick .ctaBox__content h1 {
    font-size: 23px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .cta.kick .ctaBox__content p {
    font-size: 15px;
    line-height: 20px;
  }
  .cta.kick .ctaBox__kick {
    width: 72px;
  }
  .cta.pred .ctaBox__guess {
    width: 206px;
    height: 202px;
    top: unset;
    bottom: -20px;
    right: -20px;
  }

  .switchpr {
    background: none;
  }
  .switchpr__inner {
    padding: 45px 0 45px 0;
  }
  .switchpr__wrapper {
    padding-top: 0;
  }
  .switchpr__btns {
    display: none;
  }
  .switchpr__head {
    display: none;
  }
  .switchprPack {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .switchprPack__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 12px;
    padding-left: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .switchprPack__title svg {
    width: 24px;
    height: 24px;
    color: #9695ce;
    margin-right: 6px;
  }
  .switchprPack__title svg path.ohterpath {
    color: #68689f;
  }
  .switchprPack__title p {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
  }
  .switchprPack__board {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    padding: 11px 0 0 30px;
    overflow: hidden;
    height: 142px;
    margin-bottom: 30px;
  }
  .switchprPack__board-content {
    z-index: 4;
  }
  .switchprPack__board-content h2 {
    font-size: 17px;
    line-height: 21px;
    margin-bottom: 8px;
  }
  .switchprPack__board-content p {
    font-size: 10px;
    line-height: 12px;
    max-width: 200px;
  }
  .switchprPack__board-icon {
    width: 12px;
    height: 12px;
    left: 9px;
    top: 15px;
  }
  .switchprPack__board-podium {
    bottom: -41px;
    right: -50px;
  }
  .switchprPack__board-crown {
    right: 36px;
  }
  .switchprPack__board-winner {
    right: 5px;
  }
  .switchprPack__board-image {
    right: 5px;
    width: 122px;
  }
  .switchprPack__board .button.green {
    width: 190px;
    z-index: 5;
    left: 30px;
    bottom: 10px;
  }
  .switchprPack__board:hover .button.green {
    bottom: 10px;
  }
  .switchprPack__board.animate .button.green {
    bottom: 10px;
  }
  .switchprPack__register {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .switchprPack__register-btn {
    max-width: unset;
    width: calc(100% - 60px);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .switchprPack__free {
    padding: 35px 0 0 53px;
    height: 419px;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin-bottom: 30px;
  }
  .switchprPack__free-icon {
    top: 32px;
    width: 21px;
    left: 22px;
  }
  .switchprPack__free-content p {
    color: #e5bdee;
  }
  .switchprPack__free-image {
    display: none;
  }
  .switchprPack__free-gift {
    display: none;
  }
  .switchprPack__free-buy {
    display: none;
  }
  .switchprPack__free-image2 {
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 100%;
  }
  .switchprPack__free-image2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
  }
  .switchprPack__free .button.green {
    border-radius: 0;
    width: 100%;
    left: 0;
    bottom: 0 !important;
    border-radius: unset;
    height: 58px;
  }
  .switchprPack__free .button.green span {
    border-radius: unset;
  }
  .switchprPack__free .button.green span::before {
    border-radius: unset;
  }
  .switchprPack__free .button.green span::after {
    border-radius: unset;
  }
  .switchprPack__rolex-content {
    right: 36px;
    left: unset;
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }
  .switchprPack__iphone-content {
    right: 36px;
    left: unset;
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }
  .switchpr__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .switchprRoob {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 80px;
  }
  .switchprRoob__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 12px;
    padding-left: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .switchprRoob__title svg {
    width: 24px;
    height: 24px;
    color: #9695ce;
    margin-right: 6px;
  }
  .switchprRoob__title svg path.ohterpath {
    color: #68689f;
  }
  .switchprRoob__title p {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
  }
  .switchprRoob__row {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .switchprRoob__jack {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin-bottom: 30px;
    height: 419px;
    padding: 30px 0 0 24px;
  }
  .switchprRoob__jack-content h2 {
    padding-left: 22px;
  }
  .switchprRoob__jack-content-text {
    width: calc(100% - 24px);
  }
  .switchprRoob__jack-tickets {
    left: 12px;
    top: -34px;
  }
  .switchprRoob__jack-icon {
    width: 23px;
    height: 20px;
    top: 29px;
    left: 20px;
  }
  .switchprRoob__jack-image {
    right: -120px;
    bottom: 40px;
  }
  .switchprRoob__jack .button.green {
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: unset;
    border-radius: unset;
  }
  .switchprRoob__jack:hover .button.green {
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: unset;
    border-radius: unset;
  }
  .switchprRoob__jack:hover .switchprRoob__jack-image {
    right: -110px;
    width: 500px;
    bottom: 30px;
  }
  .switchprRoob__jack.animate .button.green {
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: unset;
    border-radius: unset;
  }
  .switchprRoob__jack.animate .switchprRoob__jack-image {
    right: -110px;
    width: 500px;
    bottom: 30px;
  }
  .switchprRoob__jack.animate2 .button.green {
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: unset;
    border-radius: unset;
  }
  .switchprRoob__jack.animate2 .switchprRoob__jack-tickets {
    -webkit-transform: translateY(150%);
            transform: translateY(150%);
  }
  .switchprRoob__jack.animate2 .switchprRoob__jack-coins {
    right: 10px;
  }
  .switchprRoob__jack.animate2 .switchprRoob__jack-image {
    right: -110px;
    width: 500px;
    bottom: 30px;
  }
  .switchprRoob__jack.animate2 .switchprRoob__jack-slot-overlay {
    background: #fff;
  }
  .switchprRoob__jack.animate2 .switchprRoob__jack-slot-name {
    background: #ee99ff;
  }
  .switchprRoob__jack.animate2 .switchprRoob__jack-slot-ticket-bg::after {
    opacity: 1;
  }
  .switchprRoob__reward {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    height: 142px;
    padding: 10px 0 0 30px;
  }
  .switchprRoob__reward-icon {
    width: 12px;
    height: 12px;
    top: 12px;
    left: 9px;
  }
  .switchprRoob__reward-content h2 {
    font-size: 17px;
    line-height: 21px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .switchprRoob__reward-content p {
    font-size: 10px;
    line-height: 12px;
    max-width: 200px;
  }
  .switchprRoob__reward-btn {
    width: 140px;
    height: 28px;
    bottom: 10px !important;
    right: 12px !important;
  }
  .switchprRoob__reward-btn p {
    font-size: 15px;
    line-height: 18px;
  }
  .switchprRoob__reward-rank.first {
    width: 55px;
    top: 24px !important;
    right: 37px !important;
  }
  .switchprRoob__reward-rank.second {
    width: 50px;
    top: 26px;
    right: 39px;
  }
  .switchprRoob__reward-rank.third {
    width: 34px;
    top: 38px;
    right: 48px;
  }
  .switchprRoob__reward-rank.fourth {
    width: 16px;
    top: 50px;
    right: 59px;
  }
  .switchprRoob__reward:hover .button.green {
    bottom: 0;
    right: -40px;
  }
  .switchprRoob__reward:hover .switchprRoob__reward-bg2 {
    opacity: 1;
  }
  .switchprRoob__reward:hover .switchprRoob__reward-btn {
    bottom: 27px;
  }
  .switchprRoob__reward:hover .switchprRoob__reward-rank.first {
    top: 15px;
    right: 76px;
    width: 50px;
  }
  .switchprRoob__reward:hover .switchprRoob__reward-rank.second {
    -webkit-transform: rotate(-5.44deg);
            transform: rotate(-5.44deg);
    width: 45px;
    top: 35px;
    right: 86px;
    opacity: 0.5;
    display: none;
  }
  .switchprRoob__reward:hover .switchprRoob__reward-rank.third {
    -webkit-transform: rotate(-20.37deg);
            transform: rotate(-20.37deg);
    width: 30px;
    top: 54px;
    right: 126px;
    opacity: 0.5;
  }
  .switchprRoob__reward:hover .switchprRoob__reward-rank.fourth {
    -webkit-transform: rotate(-38.26deg);
            transform: rotate(-38.26deg);
    width: 11px;
    top: 76px;
    right: 155px;
    opacity: 0.5;
  }
  .switchprRoob__register {
    height: 200px;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .switchprRoob__register-btn {
    max-width: unset;
    width: calc(100% - 60px);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .modalOffer__content {
    padding: 32px 12px 16px 12px;
  }
  .modalOffer__content-head-info {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .modalOffer__content-head-info h3 {
    font-size: 16px;
  }
  .modalOffer__content-head-info h4 {
    font-size: 15px;
  }
  .modalOffer__content-item p {
    font-size: 12px;
  }
  .modalOffer__info {
    padding: 16px 12px;
  }
  .modalProfile__btn {
    width: 100px;
    height: 35px;
  }
  .modalProfile__btn p {
    font-size: 14px;
    line-height: 18px;
  }
  .modalProfile__inner {
    padding: 24px 16px;
  }
  .modalProfile__vip-slider {
    max-width: 184px;
    margin-left: -120px;
  }
  .modalProfile__vip-slider-tooltip {
    left: 10px;
  }
  .modalProfile__vip-slider-outer {
    padding-left: 0;
  }
  .modalProfile__prof-content-item {
    position: relative;
  }
  .modalProfile__prof-content-item-input {
    width: 100%;
    position: absolute;
    top: 25px;
  }
  .modalProfile__prof-content-item-row {
    padding-top: 44px;
  }
  .modalProfile__prof-content-item-row .button.link {
    width: 100%;
  }
  .modalProfile__prof-content-item-row-inner {
    width: calc(50% - 8px);
  }
  .modalProfile__prof-content-item-btn {
    width: calc(50% - 8px);
  }
  .modalProfile__prof-rank {
    width: calc(33.3334% - 8px);
    margin-right: 0 !important;
  }
  .modalProfile__prof-rank-image {
    height: 80px;
  }
  .modalProfile__prof-info {
    max-width: unset;
    width: 100%;
  }
  .modalProfile__prof-raks {
    width: 100%;
  }
  .modalJackpot__head h3 {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }
  .modalBonus__footer-items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .modalBonus__footer-item {
    margin: 0 0 16px 0;
  }
  .modalSuccess {
    padding: 32px 24px;
  }
  .modalSuccess__close {
    top: 34px;
  }
  .modalSuccess__image {
    max-width: 230px;
  }
  .modalSuccess__desc {
    border-radius: 10px;
  }
  .modalSuccess__desc h6 {
    font-size: 18px;
    margin-bottom: 4px;
  }
  .modalSuccess__desc p {
    font-size: 18px;
  }
  .modalRoob {
    padding: 40px 16px;
  }

  .jackpot__mount {
    overflow: hidden;
  }
  .jackpot__mount img {
    position: relative;
    left: -275px;
  }
  .jackpot__mount.right img {
    left: unset;
    right: -315px;
  }
  .jackpot__car {
    width: 372px;
    bottom: -32px;
  }
  .jackpotContent__inner {
    margin-bottom: 16px;
  }
  .jackpotContent__top {
    width: 76px;
    height: 18px;
  }
  .jackpotContent__top h5 {
    font-size: 12px;
  }
  .jackpotContent__icon {
    width: 26px;
    height: 26px;
  }
  .jackpotContent__icon svg {
    width: 14px;
    height: 14px;
  }
  .jackpotContent__banner {
    padding: 22px 0 0 18px;
    height: 136px;
    margin-bottom: 16px;
  }
  .jackpotContent__banner-info {
    width: 90px;
  }
  .jackpotContent__banner-info-gtr {
    width: 74px;
  }
  .jackpotContent__banner-info p {
    font-size: 9px;
    margin-bottom: 6px;
  }
  .jackpotContent__banner-info-price {
    width: 90px;
    height: 22px;
  }
  .jackpotContent__banner-info-price h6 {
    font-size: 17.85px;
  }
  .jackpotContent__rolex {
    height: 90px;
    padding: 22px 0 0 19px;
    margin-bottom: 16px;
  }
  .jackpotContent__rolex-info {
    width: 90px;
  }
  .jackpotContent__rolex-info img {
    width: 62px;
  }
  .jackpotContent__rolex-info h5 {
    font-size: 12.53px;
    line-height: 15px;
  }
  .jackpotContent__rolex-info p {
    font-size: 9px;
  }
  .jackpotContent__rolex-info-price {
    width: 90px;
    height: 22px;
  }
  .jackpotContent__rolex-info-price h6 {
    font-size: 17.85px;
  }
  .jackpotContent__eth {
    height: 66px;
    padding: 22px 0 0 35px;
  }
  .jackpotContent__eth-info {
    width: 90px;
    height: 22px;
  }
  .jackpotContent__eth-info h6 {
    font-size: 17.85px;
  }
  .jackpotContent__tickets {
    padding: 26px 24px 40px 24px;
    margin-bottom: 16px;
  }
  .jackpotContent__tickets-title {
    margin-bottom: 36px;
  }
  .jackpotContent__tickets-title h3 {
    font-size: 16.36px;
  }
  .jackpotContent__tickets-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 16px;
  }
  .jackpotContent__tickets-row-item {
    width: 100%;
    margin-bottom: 16px;
  }
  .jackpotContent__tickets-row-item-head {
    height: 51px;
  }
  .jackpotContent__tickets-row-item-head h6 {
    font-size: 12.1876px;
    line-height: 15px;
  }
  .jackpotContent__tickets-row-item-head-desc p {
    font-size: 12.268px;
    line-height: 15px;
    max-width: 134px;
  }
  .jackpotContent__tickets-row-item-body {
    height: 24px;
  }
  .jackpotContent__tickets-row-item-body p {
    font-size: 12.268px;
    line-height: 15px;
  }
  .jackpotContent__tickets-row-item:last-child {
    margin-bottom: 0;
  }
  .jackpotContent__tickets-box-inner {
    height: 70px;
  }
  .jackpotContent__tickets-box h6 {
    font-size: 12.1876px;
    line-height: 15px;
  }
  .jackpotContent__tickets-box p {
    font-size: 12.268px;
    line-height: 15px;
    max-width: 200px;
  }
  .jackpotContent__tickets:hover {
    padding: 22px 16px 44px 16px;
  }
  .jackpotContent__lottery {
    height: 142px;
    padding: 18px 0 0 24px;
  }
  .jackpotContent__lottery-title h2 {
    font-size: 28.6168px;
    line-height: 35px;
  }
  .jackpotContent__lottery-title p {
    font-size: 9.09086px;
    line-height: 11px;
  }
  .jackpotContent__lottery-items {
    right: 32px;
  }
  .jackpotContent__lottery-item {
    width: 92px;
    margin-right: 18px;
  }
  .jackpotContent__lottery-item h5 {
    font-size: 19.0283px !important;
    line-height: 23px !important;
  }
  .jackpotContent__lottery-item h6 {
    font-size: 13.3198px !important;
    line-height: 16px !important;
  }
  .jackpotContent__lottery-item h6 span {
    font-size: 13.3198px !important;
    line-height: 16px !important;
  }
  .jackpotContent__lottery-item.first {
    height: 54px;
  }
  .jackpotContent__lottery-item.second {
    height: 60px;
    padding-top: 10px;
  }
  .jackpotContent__lottery-item.third {
    height: 73px;
    padding-top: 16px;
  }
  .jackpotContent__lottery:hover .jackpotContent__lottery-item.first {
    height: 64px;
  }
  .jackpotContent__lottery:hover .jackpotContent__lottery-item.second {
    height: 70px;
  }
  .jackpotContent__lottery:hover .jackpotContent__lottery-item.third {
    height: 83px;
  }

  .ticketh__inner {
    padding: 35px 0 130px 0;
  }
  .ticketh__inner h2 {
    text-align: center;
  }
  .ticketh__table-head {
    display: none;
  }
  .ticketh__table-number {
    border-radius: 5px 0 5px 0;
  }
  .ticketh__table-row {
    background: #fff;
    height: 122px;
    margin-bottom: 20px;
  }
  .ticketh__table-row:first-child {
    height: 163px;
    margin-bottom: 53px;
  }
  .ticketh__table-row:first-child .ticketh__table-item:nth-child(2) {
    margin-left: -50px;
  }
  .ticketh__table-row:first-child .ticketh__table-item:nth-child(2) p {
    height: 33.91px;
  }
  .ticketh__table-items {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 0 18px 0 24px;
  }
  .ticketh__table-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .ticketh__table-item-title {
    display: block;
    margin-bottom: 12px;
  }
  .ticketh__table-item-title h5 {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 11.5592px;
    line-height: 1;
    letter-spacing: 0.06em;
    color: #606060;
  }
  .ticketh__table-item-tickets h6 {
    font-weight: 900 !important;
  }
  .ticketh__table-item-prize-car {
    width: 84px;
  }
  .ticketh__table-item:first-child {
    width: 100%;
    height: 52px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .ticketh__table-item:first-child p {
    font-weight: 700;
  }
  .ticketh__table-item:nth-child(2) {
    width: 40%;
  }
  .ticketh__table-item:nth-child(2) p {
    height: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .ticketh__table-item:last-child {
    width: 60%;
  }
  .ticketh.pack .ticketh__table-row:first-child .ticketh__table-item:nth-child(2) {
    margin-left: 0;
  }
  .ticketh.pack .ticketh__table-row:first-child .ticketh__table-item:nth-child(2) p {
    height: 15.41px;
  }
  .ticketh.pack .ticketh__table-item:nth-child(2) p {
    height: 15.41px;
  }

  .ticketj__inner {
    padding: 150px 0 130px 0;
  }
  .ticketj__inner h2 {
    text-align: center;
  }
  .ticketj__table-head {
    display: none;
  }
  .ticketj__table-number {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px 0 5px 0;
  }
  .ticketj__table-row {
    position: relative;
    display: block;
    background: #9faac1;
    height: 123px;
    margin-bottom: 22px;
  }
  .ticketj__table-row:first-child {
    height: 200px;
  }
  .ticketj__table-row:first-child .ticketj__table-number {
    height: 52px;
  }
  .ticketj__table-row:first-child .ticketj__table-item-title h5 {
    color: #fff;
  }
  .ticketj__table-row:nth-child(2) {
    height: 200px;
  }
  .ticketj__table-row:nth-child(2) .ticketj__table-item-title h5 {
    color: #fff;
  }
  .ticketj__table-row:nth-child(3) {
    height: 200px;
  }
  .ticketj__table-row:nth-child(3) .ticketj__table-item-title h5 {
    color: #fff;
  }
  .ticketj__table-row:nth-child(4) {
    height: 200px;
  }
  .ticketj__table-row:nth-child(5) {
    height: 200px;
  }
  .ticketj__table-row:last-child {
    margin-bottom: 0;
  }
  .ticketj__table-items {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0 20px;
    width: 100%;
  }
  .ticketj__table-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .ticketj__table-item-title {
    display: block;
    margin-bottom: 4px;
  }
  .ticketj__table-item-title h5 {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 11.5592px;
    line-height: 1;
    letter-spacing: 0.06em;
    color: #0c0722;
  }
  .ticketj__table-item-tickets h6 {
    font-weight: 900 !important;
  }
  .ticketj__table-item-prize {
    position: absolute;
    bottom: 0;
    left: 50%;
    height: unset;
    width: unset;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .ticketj__table-item-prize-car {
    position: static;
    width: 298px;
  }
  .ticketj__table-item-rolex {
    position: absolute;
    bottom: 0;
    left: 50%;
    height: unset;
    width: unset;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .ticketj__table-item-rolex-image {
    position: static;
    width: 131px;
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }
  .ticketj__table-item-eth {
    position: absolute;
    bottom: 15px;
    left: 50%;
    height: unset;
    width: unset;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    padding-right: 0;
  }
  .ticketj__table-item-eth-box {
    width: 128px;
    height: 35px;
    background: #6e9cd4;
    padding-left: 47px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .ticketj__table-item-eth-box img {
    width: 40px;
    right: 8px;
  }
  .ticketj__table-item-eth-box h6 {
    font-size: 23.6691px;
  }
  .ticketj__table-item-shard {
    position: absolute;
    bottom: 15px;
    left: 50%;
    height: unset;
    width: unset;
    transform: translateX(-50%);
    -moz-ransform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    padding-right: 0;
  }
  .ticketj__table-item-shard-box {
    width: 128px;
    height: 35px;
    background: #eceeff;
    padding-left: 39px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .ticketj__table-item-shard-box img {
    width: 38px;
    right: 4px;
    top: calc(50% - 2px);
  }
  .ticketj__table-item-shard-box h6 {
    font-size: 23.6691px;
  }
  .ticketj__table-item:first-child {
    width: 100%;
    height: 52px;
    padding-left: 57px;
    margin-bottom: 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .ticketj__table-item:first-child p {
    font-weight: 700;
  }
  .ticketj__table-item:nth-child(2) {
    width: unset;
  }
  .ticketj__table-item:nth-child(2) p {
    height: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 900;
  }
  .ticketj__table-item:last-child {
    width: unset;
  }
  .ticketj.pack .ticketj__table-row:first-child .ticketj__table-item:nth-child(2) {
    margin-left: 0;
  }
  .ticketj.pack .ticketj__table-row:first-child .ticketj__table-item:nth-child(2) p {
    height: 15.41px;
  }
  .ticketj.pack .ticketj__table-item:nth-child(2) p {
    height: 15.41px;
  }

  .store__earn {
    width: calc(100% + 26px);
    margin: 0 -13px;
  }
  .store__prize {
    width: calc(100% + 26px);
    margin: 0 -13px;
  }
  .store__prize-item {
    width: calc(50% - 16px);
  }

  .notify__item {
    border-radius: 20px;
  }
  .notify__item-close {
    top: 12px;
    right: 17px;
    transform: translate(0, 0);
    -moz-ransform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }

  .profile__vip-slider-outer {
    padding: 32px 0 20px 118px;
  }
  .profile__vip-rewards-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .profile__vip-rewards-item-info {
    width: 100%;
    border-radius: 20px 20px 0 0;
    padding: 5px 16px;
  }
  .profile__vip-rewards-item-info p {
    text-align: center;
  }
  .profile__vip-rewards-item-icon {
    display: none;
  }
  .profile__vip-rewards-item-price {
    border-radius: 0 0 20px 20px;
    width: 100%;
    padding: 5px 16px;
  }
  .profile__vip-rewards-item-price p {
    text-align: center;
  }
  .profileAccount__vip {
    height: unset;
  }
  .profileAccount__vip .vippRewards__head {
    height: 90px;
  }
  .profileAccount__vip .vippRewards__head::before {
    width: 148px;
    height: 135px;
    left: -24px;
  }
  .profileAccount__vip .vippRewards__head-rank {
    width: 93px;
    bottom: -16px;
  }
  .profileAccount__vip .vippRewards__head-prize {
    width: 155px;
    padding: 0 0 0 24px;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .profileAccount__vip .vippRewards__head-prize h6 {
    font-size: 15px;
    line-height: 19px;
  }
  .profileAccount__vip .vippRewards__head-prize h5 {
    font-size: 15px;
    line-height: 19px;
  }
  .profileAccount__vip .vippRewards__head-content-image {
    width: 80px;
  }
  .profileAccount__vip .vippRewards__head-content h6 {
    font-size: 10px;
  }
  .profileAccount__vip .vippProgress {
    max-width: 204px;
  }
  .profileAccount__vip .vippProgress__rank {
    width: 28px;
  }
  .profileAccount__vip .vippProgress__line {
    width: 128px;
    margin: 0 10px;
  }
  .profileAccount__vip .vippProgress__line-inner-elems {
    width: 128px;
  }
  .profileAccount__vip .vippProgress__info {
    width: 22px;
    height: 22px;
    right: -36px;
  }
  .profileAccount__vip .vippProgress__info svg {
    width: 12px;
    height: 12px;
  }
  .profileAccount__vip .vippProgress__other {
    width: 16px;
  }
  .profileAccount__vip .vipp__roob {
    top: 112px;
    width: calc(100% - 30px);
  }
  .profileAccount__vip-map {
    height: 184px;
  }
  .profileAccount__vip-progress {
    height: 68px;
  }
  .profileAccount__vip-btn {
    height: 36px;
  }
  .profileAccount__vip-btn p {
    font-size: 16px;
  }

  .vipp__register {
    padding: 21px 30px 19px 30px;
    border-radius: 14px;
  }
  .vipp__register h6 {
    font-size: 14.8889px;
    line-height: 18px;
    margin-bottom: 5px;
  }
  .vipp__register-lock {
    width: 12px;
    height: 16px;
    top: -9px;
  }
  .vipp__register-btn {
    width: 100%;
    max-width: 222px;
    height: 38px;
    border-radius: 7px;
    margin: 0 auto;
  }
  .vipp__register-btn p {
    font-size: 20.691px;
    line-height: 25px;
  }
  .vipp__roob {
    padding: 22px 30px 25px 30px;
    border-radius: 14px;
  }
  .vipp__roob h6 {
    font-size: 14.8889px;
    line-height: 18px;
    margin-bottom: 20px;
  }
  .vipp__roob-lock {
    width: 12px;
    height: 16px;
    top: -9px;
  }
  .vipp__roob-input {
    max-width: 222px;
    margin: 0 auto 14px auto;
  }
  .vipp__roob-input input {
    font-size: 16px;
    line-height: 20px;
    padding: 8px 16px;
    border-radius: 7px;
  }
  .vipp__roob-btn {
    width: 100%;
    max-width: 222px;
    height: 38px;
    border-radius: 7px;
    margin: 0 auto;
  }
  .vipp__roob-btn p {
    font-weight: 600;
    font-size: 20.691px;
    line-height: 25px;
  }
  .vippRew .vipp__register {
    width: calc(100% - 16px);
    margin-left: 8px;
    margin-bottom: 10px;
  }
  .vippRew .vipp__roob {
    width: calc(100% - 16px);
    margin-left: 8px;
    margin-bottom: 20px;
  }
  .vippRew .vipp__roob-input {
    max-width: 222px;
  }
  .vippRew .vipp__rew {
    padding: 0 8px 40px 8px;
  }
  .vippRew .vipp__rew-title {
    margin-bottom: 8px;
  }
}
@media (max-width: 440px) {
  .footer__note-inner p {
    text-align: left;
  }

  .vipReg__form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .vipReg__form-btn {
    width: 100%;
    max-width: unset;
  }
  .vipReg__form-input {
    width: 100%;
    margin-bottom: 16px;
  }
  .vipReg__content h3 {
    font-size: 18px;
  }

  .store__prize-title {
    margin-bottom: 60px;
  }
  .store__prize-title h3 {
    text-align: center;
  }
  .store__prize-items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .store__prize-item {
    width: 100%;
    max-width: 200px;
    margin: 0 0 30px 0;
  }

  .header__more {
    width: calc(100% - 162px);
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .header__more-balance {
    width: calc(100% - 58px);
    margin-right: 0;
  }

  .profile__vip-rewards-item {
    border-radius: 10px;
  }
  .profile__vip-rewards-item-rank {
    width: 60px;
  }
  .profile__vip-rewards-item-info {
    border-radius: 10px 10px 0 0;
  }
  .profile__vip-rewards-item-price {
    border-radius: 0 0 10px 10px;
  }
}
@media (max-width: 390px) {
  .offersItem__content-image {
    width: calc(100% - 93px);
  }
  .offersItem__icon {
    left: unset;
    right: 12px;
  }

  .vipp__register {
    max-width: 352px;
  }
  .vipp__roob {
    max-width: 352px;
  }
}
@font-face {
  font-family: "Inter";
  src: url("Inter18pt-Black.woff2") format("woff2"),
    url("Inter18pt-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("Inter18pt-Bold.woff2") format("woff2"),
    url("Inter18pt-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("Inter18pt-ExtraBold.woff2") format("woff2"),
    url("Inter18pt-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("Inter18pt-Light.woff2") format("woff2"),
    url("Inter18pt-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("Inter18pt-Medium.woff2") format("woff2"),
    url("Inter18pt-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("Inter18pt-Regular.woff2") format("woff2"),
    url("Inter18pt-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("Inter18pt-SemiBold.woff2") format("woff2"),
    url("Inter18pt-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("Kanit-Black.woff2") format("woff2"),
    url("Kanit-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("Kanit-Bold.woff2") format("woff2"),
    url("Kanit-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("Kanit-ExtraBold.woff2") format("woff2"),
    url("Kanit-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("Kanit-Light.woff2") format("woff2"),
    url("Kanit-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("Kanit-Medium.woff2") format("woff2"),
    url("Kanit-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("Kanit-Regular.woff2") format("woff2"),
    url("Kanit-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("Kanit-SemiBold.woff2") format("woff2"),
    url("Kanit-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
